import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import Loader from "../../components/UI/Loading/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import { format } from "date-fns";

// Page Component
import PagerEvent from "../../components/PagerEvents/PagerEvent";
import ActiveEventTurnoutView from "./ActiveEventTurnoutView";
import ActiveEventTimer from "./ActiveEventTimer";

// UIs & styles
import Button from "../../components/UI/Button/Button";
import { Dropdown } from "semantic-ui-react";

import styles from "./TurnoutView.module.scss";
import Icon from "../../components/UI/Icon/Icon";
import HideNavigationIcon from "../../assets/img/icons/hide_nav_icon_green.svg";
import copy from "../../assets/copy/copy.json";
import ReactToPrint from 'react-to-print';


//SignalR
import {
  HubConnectionState,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
// alert tones
import sounds from "./Settings/Sounds";

export default class Turnout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      isMobile: window.innerWidth <= 1024,
      updateEventCounter: 0, // This will be used to update the details of the selected event
      updateTimerCounter: 0, // This will be used to update the details of the selected event timer
      loadTimer: false, // This will be used to update the details of the selected event timer
      isCheckingForEvents: false, // This is used to display the loader while the API call to fetch events data is going through
      getActivePagerMessagesIsFinished: false,
      isLoadingAllEvents: true,
      isLoadingEvent: true,
      loadAllEventsWithoutRefreshing: false,
      addingLatestEventsToStartOfArray: false,
      allActiveEvents: null,
      dateTimeAfter: null,
      currentActiveEventIsLocked: false,
      currentActiveEvent: null,
      currentAlertType: null, // This is used to determine which alert tone to play for the current messageType
      hidePageNavigation: false,
      viewAvailabilityPermission: null,
      hasMoreMessagesToLoad: true,
      // Turnout Settings
      expandedView: false, //this is use to determined whether layout should be 'fit-to-screen' or 'expanded view'
      ttsOn: false,
      ttsSpeechRate: 0.6,
      ttsVolume: 0.5,
      ttsMuteBetween: false,
      ttsMuteFrom: "",
      ttsMuteTo: "",
      ttsMuteSpecific: null,
      ttsRepeat: 1,
      ttsGap: 1000,
      priority: false,
      alertTones: false,
      timerReadout: false,
      emergencyAlertSound: 0,
      nonEmergencyAlertSound: 0,
      adminAlertSound: 0,
      colourTimer: false,
      sdsTimeframeTarget: null,
      sdsTimeframeLimit: null,
      sdsTimeframeExceeded: null,
      emergencyPriorityMode: false,
      // EVENT FILTER
      showEventFilter: false,
      allTypes: true,
      Emergency: false,
      NonEmergency: false,
      Admin: false,
      messageType: null,
      agency: [], // event filter input
      pagingNumber: [], // event filter input
      userAgenciesOption: null, // Agency data that is in a suitable format for the filter
      userPagingGroupsOption: null, // Paging groups data that is in a suitable format for the filter
      defaultPagingGroup: null,
      errorMessage: null, // Error message for the banner
      printMode: false, // true when printing Turn out
      signalRDisconnected: false, // show the alert banner is it's disconnected
    };
    this.Requests = new Requests();
    this.speechUtterance = new SpeechSynthesisUtterance();
    this.synth = window.speechSynthesis;
    this.messageTimeout = null;
    this.signalRconnection = null;
    this.asyncIntervals = [];
    this.refreshIntervalId = null;
    this.pagingNumberDictionary = {};
    //alert tones
    this.audioRef = React.createRef();
    this.componentRef = React.createRef();
    this.printContentRef = React.createRef();
    this.triggerRef = React.createRef();

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.sessionStorage.setItem("redirectHomeFromEvent", "");

    // create dictionary of paging numbers user is subscribed to but chosen to hide
    this.pagingNumberMapping();

    // Initial load of pager event
    this.getActivePagerMessages();
    this.prepareFilterData();
    this.startActivePagerRefresh();

    this.getPagingGroupsWithAvailability();
    // Initial Load settings
    this.getSettings();
    // Initial load voice
    setTimeout(() => this.getVoice(), 100);

    // connect to SignalR
    this.signalRconnection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_TURNOUT_SIGNALR_DEV, {
        accessTokenFactory: () => this.getSignalRAccessToken(),
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.retryReason) {
            console.error(`Retry attempt: ${retryContext.previousRetryCount}, Reason: ${retryContext.retryReason}`);
        } else {
            console.log(`Retry attempt: ${retryContext.previousRetryCount}`);
        }
          return 5000;
        }
    })
      .configureLogging(LogLevel.Information)
      .build();

    this.signalRconnection
      .start()
      .then((result) => {
        this.sendUserProfileToSignalR();
        // handle new message received
        this.signalRconnection.on(
          "SendMessage",
          (
            eventExist,
            isUserAttendanceUpdate,
            message,
            lstUserAttandance,
            isEventAddressUpdate,
            newAddress
          ) => {
            let pagingNumber = message[0]?.paging?.number;

            // only display message with paging number
            if (pagingNumber) {
              // add signalR message to the list if user has subscription to the paging number
              if (
                typeof this.pagingNumberDictionary[pagingNumber] === "undefined"
              ) {
                let matchedFilter = this.messageMatchFilter(message[0]);
                // get signalR message only when filter matches
                if (matchedFilter) {
                  let userID = this.props.userInfo.userId;
                  let result = lstUserAttandance.find((x) => {
                    return x.toLowerCase() === userID;
                  });

                  if (result) {
                    message[0].attendanceStatus = "Attending";
                  } else {
                    message[0].attendanceStatus = null;
                  }

                  this.getSignalRMessage(
                    message[0],
                    isUserAttendanceUpdate,
                    isEventAddressUpdate,
                    newAddress
                  );
                }
              }
            }
          }
        );

        this.signalRconnection.onclose((error) => {
          console.assert(
            this.signalRconnection.state === HubConnectionState.Disconnected
          );
          this.setState(
            {
              signalRDisconnected: true,
            });
          let errorMessage =
            copy.turnout.turnoutSignalRDisconnected +
            ` (Error #${copy.errorCodes.turnoutSignalRDisconnectedErrorMessage})`;
          this.setState(
            {
              errorMessage: errorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        });

        this.signalRconnection.onreconnecting((error) => {
          console.assert(
            this.signalRconnection.state === HubConnectionState.Reconnecting
          );
          this.setState(
          {
            signalRDisconnected: true,
          });
          let errorMessage =
            copy.turnout.turnoutSignalRReconnecting +
            ` (Error #${copy.errorCodes.turnoutSignalRReconnectingErrorMessage})`;
          console.log(errorMessage, error);
          this.setState(
            {
              errorMessage: errorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        });

        this.signalRconnection.onreconnected(() => {
          this.setState(
            {
              signalRDisconnected: false,
            });
          console.assert(
            this.signalRconnection.state === HubConnectionState.Connected
          );
          window.location.reload();
          console.log("SignalR Connection reestablished.");
        });
      })
      .catch((error) => {
        let errorMessage =
          copy.turnout.turnoutSignalRFailed +
          ` (Error #${copy.errorCodes.turnoutSignalRFailedErrorMessage})`;
        this.setState(
          {
            errorMessage: errorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    clearInterval(this.messageTimeout);
    clearInterval(this.refreshIntervalId);
    // stop text to speech when moving to other pages.
    this.synth.cancel();
    this.asyncIntervals.forEach((obj, index) => {
      this.clearAsyncInterval(index);
    });
    // stop signalR connection
    this.signalRconnection.stop().then(() => {
      console.assert(
        this.signalRconnection.state === HubConnectionState.Disconnected
      );
    }).catch((error) => {
      console.error('Error stopping SignalR connection:', error);
    });
  }

  /**
   * start interval to refresh data every hour
   */
  startActivePagerRefresh() {
    this.refreshIntervalId = setInterval(
      this.getActivePagerMessages,
      60000 * 60
    );
  }

  /**
   * Stores state of active timer
   * @param {*} timer
   */
  readableTimerText = (timer) => {
    this.timerText = timer;
  };

  //toggle for timer readout, by default off
  toggleTimerReadout = () => {
    this.setState((prevState) => ({
      timerReadout: !prevState.timerReadout,
    }));
  };

  /**
   * map all the user agencies and paging group the user is a part of
   * for each agency a user is associated to map paging group that should be hidden from the display
   */
  pagingNumberMapping = () => {
    this.props.userAgencies.forEach((agency) => {
      const body = { agency: [agency] };
      this.Requests.callAPI(
        this.Requests.postUserAgencyPagingGroups,
        body
      ).then((data) => {
        if (data.data) {
          let group = data.data.filter((group) => group.isMuted === true);
          group.forEach((pagingGroup) => {
            this.pagingNumberDictionary[pagingGroup.number] = {
              pagingNumber: pagingGroup.number,
              pagingGroupName: pagingGroup.name,
            };
          });
        }
      });
    });
  };

  /**
   * Get special token to access SignalR
   */
  async getSignalRAccessToken() {
    try {
      const response = await this.Requests.callAPI(
        this.Requests.getSignalRToken,
        this.props?.userInfo?.userId
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * send userprofile to signalR to assign group
   */
  sendUserProfileToSignalR() {
    try {
      this.Requests.callAPI(
        this.Requests.sendUserProfileSignalR,
        this.props?.userInfo?.userId
      );
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * set the state of the  speech voice to be ready to use
   */
  async getVoice() {
    let voice = await this.getFromSpeechSynthesis().catch((error) =>
      console.log(error)
    );
    if (!voice || voice.length === 0) {
      voice = await this.getFromSpeechSynthesis().catch((error) =>
        console.log(error)
      );
    }
    this.setState({ speechVoice: voice });
  }

  /**
   * Called to get the speech synthesis specific AU Voice
   */
  getFromSpeechSynthesis = () =>
    new Promise((resolve, reject) => {
      const voices = window.speechSynthesis.getVoices();
      if (voices) {
        let auVoice = voices.filter((x) => x.lang === "en-AU")[0];
        resolve(
          auVoice ? auVoice : voices.filter((x) => x.lang === "en-US")[0]
        );
      } else {
        reject(Error("Error: voices failed to load"));
      }
    });

  selectedTonePerAlertType = (currentAlertType) => {
    switch (currentAlertType) {
      case "Emergency":
        return sounds[this.state.emergencyAlertSound].audio;
      case "NonEmergency":
        return sounds[this.state.nonEmergencyAlertSound].audio;
      case "Admin":
        return sounds[this.state.adminAlertSound].audio;
      default:
        return sounds[0].audio;
    }
  };

  //play Alert tones, timer readout, pager event read out respectively
  playAlertTonesReadMessage = async (word, repeats, audioFile) => {
    // Play the selected tone for the specified duration
    if (this.state.alertTones) {
      this.audioRef.current.src = audioFile;
      this.audioRef.current.volume = this.state.ttsVolume;
      this.audioRef.current.play();

      // Pause the audio after the specified duration
      await new Promise((resolve) => {
        setTimeout(() => {
          this.audioRef.current.pause();
          resolve();
        }, 3000);
      });
    }

    if (this.state.speechVoice) {
      // Cancel text to speech currently in play
      if (this.synth.speaking && !repeats) {
        this.synth.cancel();
      }
      // Apply TTS settings
      this.speechUtterance.text = word;
      this.speechUtterance.rate = parseFloat(this.state.ttsSpeechRate);
      this.speechUtterance.volume = parseFloat(this.state.ttsVolume);
      this.speechUtterance.voice = this.state.speechVoice;
    }

    // Pause for the specified duration before starting the speech
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    // Continue with the speech once the tone has played
    return new Promise((resolve) => {
      this.speechUtterance.onend = resolve;
      this.synth.speak(this.speechUtterance);
    });
  };

  /**
   * To check if text to speech is on mute triggered before the text to speech function
   */
  isTtsOnMute() {
    const muteUntil = this.state.ttsMuteSpecific
      ? new Date(this.state.ttsMuteSpecific)
      : null;
    const muteBetween = this.state.ttsMuteBetween;
    const currentTime = new Date();

    if (muteUntil !== null || muteBetween === true) {
      if (muteUntil !== null && currentTime < muteUntil) {
        return true;
      }

      if (muteBetween) {
        const muteFromTime = this.state.ttsMuteFrom.split(":");
        let muteFrom = new Date(currentTime);
        muteFrom.setHours(muteFromTime[0], muteFromTime[1], muteFromTime[2]);
        const muteToTime = this.state.ttsMuteTo.split(":");
        let muteTo = new Date(currentTime);
        muteTo.setHours(muteToTime[0], muteToTime[1], muteToTime[2]);

        // if mute to time is earlier than mute from then add an extra day
        if (muteTo < muteFrom) {
          muteTo.setDate(muteTo.getDate() + 1);
        }

        return currentTime > muteFrom && currentTime < muteTo;
      }
    }

    return false;
  }

  /**
   * Called when a page refresh is triggered by the ActiveEventTurnoutView
   */
  handleRefresh = () => {
    // Remake API calls to get paging groups for the filter if required
    if (!this.state.userPagingGroupsOption) {
      this.prepareFilterData();
    }

    // Remake API calls to availability if required
    if (!this.state.day || !this.state.week) {
      this.selectPagingGroup(
        this.state.availabilityPagingGroupsOption[0].value
      );
    }
    // Remake API call to get all active pager messages if required (i.e. if API call fails on initial page load)
    if (!this.state.allActiveEvents) {
      this.getActivePagerMessages();
    }
  };

  /**
   * Get initial settings state of default view and text to speech from the API
   */
  getSettings = () => {
    this.Requests.callAPI(this.Requests.getUserTurnoutSettings).then((data) => {
      if (!data || data.status !== 200) {
        let ErrorMessage =
          copy.turnout.turnoutSettingsAPIErrorMessage +
          ` (Error #${copy.errorCodes.getTurnoutSettingsErrorMessage})`;
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  errorMessage: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      } else {
        if (data && data.status && data.status === 200 && data.data) {
          this.setState(
            {
              expandedView:
                data.data.defaultLayout === "Expanded" ? true : false,
              ttsOn: data.data.textToSpeech.toggleOn,
              ttsSpeechRate: data.data.textToSpeech.speechRate,
              ttsVolume: data.data.textToSpeech.volume,
              ttsMuteBetween: data.data.textToSpeech.toggleMuteBetween,
              ttsMuteFrom: data.data.textToSpeech.muteFrom,
              ttsMuteTo: data.data.textToSpeech.muteTo,
              ttsMuteSpecific: data.data.textToSpeech.muteSpecific,
              ttsGap: data.data.textToSpeech.gap,
              ttsRepeat: data.data.textToSpeech.repeat,
              timerReadout: data.data.textToSpeech.timerReadout,
              // priority: data.data.textToSpeech.togglePriority,
              alertTones: data.data.textToSpeech.toggleAlertTone,
              adminAlertSound: data.data.textToSpeech.adminAlertSound,
              emergencyAlertSound: data.data.textToSpeech.emergencyAlertSound,
              nonEmergencyAlertSound:
              data.data.textToSpeech.nonEmergencyAlertSound,
              sdsTimeframeTarget: data.data.responseTargetSettings?.responseTargetOne || 0,
              sdsTimeframeLimit: data.data.responseTargetSettings?.responseTargetTwo || 0,
              sdsTimeframeExceeded: data.data.responseTargetSettings?.responseTargetThree || 0,
              colourTimer: data.data.responseTargetSettings?.enabled || false,
              //emergencyPriorityMode: data.data.prioritiseEmergencies,
            }
            // () => {
            //   if (this.state.priority) {
            //     this.getActivePagerMessages();
            //   }
            // }
          );
        }
      }
    });
  };

  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth,
      isMobile: window.innerWidth <= 1024,
    });
    if (document.body.classList.contains("fullscreen-map-mode")) {
      document.body.classList.remove("fullscreen-map-mode");
    }
  };

  /**
   * This function is used to prepare the data for the paging groups filter
   */
  getPagingGroupsWithAvailability = () => {
    // Fetch all the paging groups the user is a part of and has availability enabled
    let body = {
      availabilityEnabled: true,
    };
    // Request to get an array of the agencies the user belongs to
    this.Requests.callAPI(this.Requests.postUserPagingGroups, body).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          let pagingGroupsPartOfWithAvailability = {};
          pagingGroupsPartOfWithAvailability.all = [];
          this.props.userAgencies.forEach((item) => {
            pagingGroupsPartOfWithAvailability[item] = [];
          });
          // This sorts all paging groups by their respective agencies into the pagingGroupsPartOf object.
          data.data.forEach((item) => {
            if (
              item.agency &&
              pagingGroupsPartOfWithAvailability[item.agency]
            ) {
              pagingGroupsPartOfWithAvailability[item.agency].push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
              // Paging groups with no agency are placed in the 'all' child.
            } else if (!item.agency) {
              pagingGroupsPartOfWithAvailability.all.push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
            }
          });
          this.setState(
            {
              pagingGroupsPartOfWithAvailability:
                pagingGroupsPartOfWithAvailability,
            },
            () => {
              // Now prepare the paging group data
              this.preparePagingGroupData();
            }
          );
        } else {
          let ErrorMessage =
            copy.availability.gettingAvailabilityGroupsErrorMessage;
          this.setState(
            {
              pagingGroupsPartOfWithAvailability: false,
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  /**
   * This function is used to prepare the data for the paging groups filter
   */
  preparePagingGroupData = () => {
    // Getting all agencies the user has admin rights to
    let adminAgencies = [];
    // If they are superuser, they will have admin rights to all agencies
    if (
      this.props.userRoleAgency !== undefined &&
      this.props.userRoleAgency.indexOf("SuperUser") !== -1
    ) {
      adminAgencies = ["CFA", "AV", "SES"];
    } else {
      let agencies = ["CFA", "SES", "AV"];
      agencies.forEach((agency) => {
        // Check if they are an Admin of an Agency, if so add that agency.
        if (
          this.props.userRoleAgency.indexOf("Administrator_" + agency) !== -1
        ) {
          adminAgencies.push(agency);
        }
        //Check if they are a manager of an Agency, if so add that agency.
        if (this.props.userRoleAgency.indexOf("Manager_" + agency) !== -1) {
          adminAgencies.push(agency);
        }
      });
    }

    // Getting all paging groups the user has access to
    let pagingGroups = [];
    adminAgencies.forEach((agency) => {
      // If the user has permission to view paging groups of that agency, add the paging groups to the filter
      if (
        this.state.pagingGroupsPartOfWithAvailability[agency] !== undefined &&
        this.state.pagingGroupsPartOfWithAvailability[agency].length > 0
      ) {
        pagingGroups = pagingGroups.concat(
          this.state.pagingGroupsPartOfWithAvailability[agency]
        );
      }
    });
    Object.entries(this.props.pagingGroupsOwned).forEach((entry) => {
      // If user is not admin of the agency but owns paging groups of that agency, add the paging groups they own to the filter
      // Check to see if the paging group the user is an owner has availabilityenabled
      if (adminAgencies.indexOf(entry[0]) === -1 && entry[1].length > 0) {
        Object.entries(entry[1]).forEach((group) => {
          if (group[1] && group[1].availabilityenabled === "true") {
            // Adding paging group to filter
            pagingGroups = pagingGroups.concat(group[1]);
          }
        });
      }
    });

    // Also add personal paging groups to the filter
    if (
      this.state.pagingGroupsPartOfWithAvailability &&
      this.state.pagingGroupsPartOfWithAvailability.all
    ) {
      pagingGroups = pagingGroups.concat(
        this.state.pagingGroupsPartOfWithAvailability.all
      );
    }

    this.setState(
      {
        availabilityPagingGroupsOption: pagingGroups,
      },
      () => {
        if (
          this.state.availabilityPagingGroupsOption &&
          this.state.availabilityPagingGroupsOption[0]
        ) {
          let defaultPagingGroup =
            this.state.availabilityPagingGroupsOption[0].value;
          this.setState({ defaultPagingGroup: defaultPagingGroup });
          this.selectPagingGroup(
            this.state.availabilityPagingGroupsOption[0].value
          );
        } else {
          // No paging groups available
          this.setState({ defaultPagingGroup: false });
        }
      }
    );
  };

  updateEventCounter = () => {
    this.setState({
      isCheckingForEvents: true,
      updateEventCounter: this.state.updateEventCounter + 1,
    });
  };

  triggerPagination = () => {
    if (
      this.state.allActiveEvents.length >= 10 &&
      this.state.hasMoreMessagesToLoad
    ) {
      let messageId;
      if (this.state.allActiveEvents && this.state.allActiveEvents.length > 1) {
        messageId =
          this.state.allActiveEvents[this.state.allActiveEvents.length - 1].Id;
      }

      const body = {
        messageType: this.state.messageType ? this.state.messageType : null,
        agency: this.state.agency.length === 0 ? null : this.state.agency,
        pagingNumber:
          this.state.pagingNumber.length === 0 ? null : this.state.pagingNumber,
        messageIdBefore: messageId,
        includeAttendanceCount: true,
        isHomePage: true,
        skip: 0,
        take: 10,
      };

      this.setState(
        {
          addingLatestEventsToStartOfArray: false,
        },
        () => {
          // Get more pagination events
          this.getActivePagerMessages(body);
        }
      );
    } else {
      if (this.state.hasMoreMessagesToLoad) {
        this.setState({
          hasMoreMessagesToLoad: false,
        });
      }
    }
  };

  /**
   * This function gets called by signalR when a new message/update arrives.
   */
  getSignalRMessage = (
    message,
    isUserAttendance,
    isEventAddressUpdate,
    newAddress
  ) => {
    let newMessageArray = [].concat(this.state.allActiveEvents);
    const linkedEvents = this.state.allActiveEvents.filter((pastMessage) => pastMessage.eventId === message.eventId)
    const linkedToEmergency = linkedEvents.some((message) => message.messageType === 'Emergency')

    // check for existing message (e.g attendance update, inactive, address update)
    let existingMessage = this.state.allActiveEvents.filter(
      (existMessage) => existMessage.Id === message.Id
    );

    if (existingMessage.length > 0) {
      // get messages with the same event Ids

      if (isUserAttendance) {
        // Update attendance count for each of the messages

        linkedEvents.forEach((existMessage) => {
          const existingMessageIndex =
            this.state.allActiveEvents.indexOf(existMessage);
          newMessageArray[existingMessageIndex] = {
            ...existMessage,
            attendingCount: message.attendingCount,
            otherCount: message.otherCount,
            unAvailableCount: message.unAvailableCount,
            attendanceStatus: message.attendanceStatus,
          };
        });
      } else if (isEventAddressUpdate && newAddress !== null) {
        //if there is an address update and the address is not null then refresh the active event
        this.setState({ turnoutRefresh: true });
      } else {
        // Check if the event has closed
        linkedEvents.forEach((existMessage) => {
          const existingMessageIndex =
            this.state.allActiveEvents.indexOf(existMessage);
          newMessageArray[existingMessageIndex] = {
            ...existMessage,

            eventStatus: message.eventStatus,
          };
        });
      }

      this.setState({
        allActiveEvents: newMessageArray,
        updateEventCounter: this.state.updateEventCounter + 1,
      });
    } else {
      // An attendance update to an old event may not be rexognised as an existing message,
      // The condition below prevent adding an old message to the top of event list
      if (!isUserAttendance) {
        // add new message to the beginning of array
        newMessageArray.unshift(message);

        if (message.messageType === 'Emergency' || linkedToEmergency || !this.state.emergencyPriorityMode) {
          this.setState(
            {
              allActiveEvents: newMessageArray,
              currentActiveEvent: message.eventId,
              currentAlertType: message.messageType,
              updateEventCounter: this.state.updateEventCounter + 1,
              currentActiveEventIsLocked: true,
              latestEvent: newMessageArray[0].eventId,
            },
            () => {
              //clear previous intervals before starting another text to speech event
              this.synth.cancel();
              this.asyncIntervals.forEach((obj, index) => {
                this.clearAsyncInterval(index);
              });
            // if auto printing is enabled, print the latest event - place holder
            setTimeout(() => {
                this.textToSpeech(
                  this.state.currentActiveEvent,
                  () => this.timerText
                );
              }, 2000);
            }
          );
        } else {
          this.setState(
            {
              allActiveEvents: newMessageArray,
              updateEventCounter: this.state.updateEventCounter + 1,
              latestEvent: newMessageArray[0].eventId,
            }
          )
        }
      }
    }
  };

  messageMatchFilter = (message) => {
    const msgFilter = {
      messageType: this.state.messageType,
      agency: this.state.agency.length === 0 ? null : this.state.agency,
      pagingNumber:
        this.state.pagingNumber.length === 0 ? null : this.state.pagingNumber,
    };

    let type = false;
    let agency = false;
    let pagingNumber = false;

    if (msgFilter.messageType) {
      msgFilter.messageType.forEach((t) => {
        if (t === message.messageType && type !== true) {
          type = true;
        }
      });
    } else {
      type = true;
    }

    if (msgFilter.agency) {
      msgFilter.agency.forEach((a) => {
        if (a === message.agency && agency !== true) {
          agency = true;
        }
      });
    } else {
      agency = true;
    }

    if (msgFilter.pagingNumber) {
      msgFilter.pagingNumber.forEach((p) => {
        if (p === message.paging.number && agency !== true) {
          pagingNumber = true;
        }
      });
    } else {
      pagingNumber = true;
    }

    return agency && type && pagingNumber;
  };

  filter = () => {
    // stop text to speech
    this.synth.cancel();
    this.asyncIntervals.forEach((obj, index) => {
      this.clearAsyncInterval(index);
    });
    // trigger active timer to load
    this.setState({ loadTimer: true });
    // get new messages
    this.getActivePagerMessages();
    // close window
    this.toggleEventFilter();
  };

  /**
   * This function will return the active pager messages
   */
  getActivePagerMessages = (Body) => {
    let msg = {};

    if (Body) {
      msg = Body;
    } else {
      msg = {
        messageType: this.state.messageType,
        includeAttendanceCount: true,
        agency: this.state.agency.length === 0 ? null : this.state.agency,
        pagingNumber:
          this.state.pagingNumber.length === 0 ? null : this.state.pagingNumber,
        isHomePage: true,
        skip: 0,
        take: 30,
      };
    }

    this.setState(
      {
        getActivePagerMessagesIsFinished: false,
        isLoadingAllEvents:
          Body || this.state.loadAllEventsWithoutRefreshing ? false : true,
        isLoadingEvent:
          Body || this.state.loadAllEventsWithoutRefreshing ? false : true,
      },
      () => {
        this.Requests.callAPI(this.Requests.postPagerMessages, msg).then(
          (data) => {
            if (!data) {
              let ErrorMessage = copy.error_handling.activeEventsError;
              if (data && data.data && data.data.SASMessageClient) {
                ErrorMessage = data.data.SASMessageClient;
              }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  getActivePagerMessagesIsFinished: true,
                  isCheckingForEvents: false,
                  loadAllEventsWithoutRefreshing: false,
                },
                () => {
                  this.messageTimeout = setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            } else {
              if (data && data.status && data.status === 200) {
                let allActiveEvents;

                if (data.data) {
                  // If there is no body, then that means this function was called as a result of a filter change or page initialisation, which means that
                  // no adding to the start/end of this.state.allActiveEvents is required
                  if (!Body) {
                    allActiveEvents = data.data;
                    // if (this.state.priority) {
                    //   //if the priority settings is on, then filter for Emergency events
                    //   initialDataEmerg = allActiveEvents.filter(
                    //     (x) => x.messageType === "Emergency"
                    //   );
                    //   initialNotEmerg = allActiveEvents.filter(
                    //     (x) => x.messageType !== "Emergency"
                    //   );

                    //   allActiveEvents =
                    //     initialDataEmerg.concat(initialNotEmerg); // add non-emerg & admin after emergency msgs
                    //   console.log("Lenght", allActiveEvents[0].messageType);
                    // }
                  }
                  //Adds latest events to the beginning of array
                  else if (this.state.addingLatestEventsToStartOfArray) {
                    if (data.data.length > 0) {
                      const allActiveEventsArray = this.state.allActiveEvents;
                      const Data = data.data;

                      allActiveEvents = Data.concat(allActiveEventsArray);
                    } else if (data.data.length === 0) {
                      allActiveEvents = this.state.allActiveEvents;
                      this.setState({
                        hasMoreMessagesToLoad: true,
                        loadAllEventsWithoutRefreshing: false,
                      });
                    }
                  }
                  //Loads all events or adds more events to end of array through pagination
                  else {
                    allActiveEvents = this.state.allActiveEvents.concat(
                      data.data
                    );
                    if (data.data.length === 0) {
                      this.setState({
                        hasMoreMessagesToLoad: false,
                        loadAllEventsWithoutRefreshing: false,
                      });
                    }
                  }

                  if (!Body) {
                    this.setState(
                      {
                        getActivePagerMessagesIsFinished: true,
                        isLoadingAllEvents: false,
                        isCheckingForEvents: false,
                        isLoadingEvent: false,
                        allActiveEvents: data.data[0] ? allActiveEvents : [],
                        currentActiveEvent: allActiveEvents[0]
                          ? allActiveEvents[0].eventId
                          : null,
                        currentAlertType: allActiveEvents[0]
                          ? allActiveEvents[0].messageType
                          : null,
                        currentActiveEventIsLocked: true,
                        latestEvent: allActiveEvents[0]
                          ? allActiveEvents[0].eventId
                          : false,
                        hasMoreMessagesToLoad:
                          data.data.length < 10 ? false : true,
                        loadAllEventsWithoutRefreshing: false,
                        updateTimerCounter: this.state.updateTimerCounter + 1,
                        loadTimer: false,
                      },
                      () => {
                        this.removeUnreadMessageIndicator();
                      }
                    );
                  } else {
                    if (data.data.length > 0) {
                      if (data.data.length < 10) {
                        this.setState({
                          hasMoreMessagesToLoad: false,
                          loadAllEventsWithoutRefreshing: false,
                        });
                      }
                      this.setState(
                        {
                          getActivePagerMessagesIsFinished: true,
                          allActiveEvents: allActiveEvents,
                          latestEvent: allActiveEvents[0]
                            ? allActiveEvents[0].eventId
                            : [],
                          isLoadingAllEvents: false,
                          isLoadingEvent: false,
                          loadAllEventsWithoutRefreshing: false,
                        },
                        () => {
                          this.removeUnreadMessageIndicator();
                        }
                      );
                    } else if (data.data.length === 0) {
                      this.setState(
                        {
                          getActivePagerMessagesIsFinished: true,
                          allActiveEvents: allActiveEvents,
                          isLoadingAllEvents: false,
                          isLoadingEvent: false,
                          loadAllEventsWithoutRefreshing: false,
                        },
                        () => {
                          this.removeUnreadMessageIndicator();
                        }
                      );
                    }
                  }
                }
              } else {
                let ErrorMessage =
                  copy.dashboard.messageAPIErrorMessage +
                  ` (Error #${copy.errorCodes.messageAPIErrorMessage})`;
                // if (data && data.data && data.data.SASMessageClient) {
                //   ErrorMessage = data.data.SASMessageClient;
                // }
                this.setState(
                  (prevState) => ({
                    getActivePagerMessagesIsFinished: true,
                    allActiveEvents: !prevState.allActiveEvents
                      ? false // If failure on initial load of the page
                      : prevState.allActiveEvents, // Failure when refreshing
                    isCheckingForEvents: false,
                    isLoadingAllEvents: false,
                    isLoadingEvent: false,
                    errorMessage: ErrorMessage,
                    loadAllEventsWithoutRefreshing: false,
                  }),
                  () => {
                    this.messageTimeout = setTimeout(
                      function () {
                        this.setState({
                          errorMessage: null,
                        });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            }
          }
        );
      }
    );
  };
  removeUnreadMessageIndicator = () => {
    const { allActiveEvents } = this.state;
    const isEventUnreadArray = allActiveEvents.map((event) => !event.dateRead);
    this.setState({ isEventUnreadArray });
  };

  callGetAllMessages = () => {
    this.setState(
      {
        loadAllEventsWithoutRefreshing: true,
      },
      () => {
        this.getActivePagerMessages();
      }
    );
  };

  /**
   * This function will be called whenever a new event card is clicked to update on the right
   */
  updateActiveEvent = (currentEvent) => {
    if (currentEvent.eventId) {
      this.setState(
        {
          expandedView: false,
          currentActiveEventIsLocked: true,
          currentActiveEvent: currentEvent.eventId,
          currentAlertType: currentEvent.messageType,
        },
        () => {
          //clear previous intervals before starting another text to speech event
          this.asyncIntervals.forEach((obj, index) => {
            this.clearAsyncInterval(index);
          });
          this.textToSpeech(
            this.state.currentActiveEvent,
            () => this.timerText
          );
        }
      );
    }
  };

  /**
   * This function is required to run asynchronous setInterval
   * for repeats and gaps in Text-to-Speech
   */
  runAsyncInterval = async (cb, interval, intervalIndex) => {
    await cb();
    if (this.asyncIntervals[intervalIndex].run) {
      this.asyncIntervals[intervalIndex].id = setTimeout(
        () => this.runAsyncInterval(cb, interval, intervalIndex),
        interval
      );
    }
  };

  /**
   * This function is required to run asynchronous setInterval
   * for repeats and gaps in Text-to-Speech
   */
  setAsyncInterval = (cb, interval) => {
    if (cb && typeof cb === "function") {
      const intervalIndex = this.asyncIntervals.length;
      this.asyncIntervals.push({ run: true, id: 0 });
      this.runAsyncInterval(cb, interval, intervalIndex);
      return intervalIndex;
    } else {
      throw new Error("Callback must be a function");
    }
  };

  /**
   * This function is required to run asynchronous setInterval
   * for repeats and gaps in Text-to-Speech
   */
  clearAsyncInterval = (intervalIndex) => {
    if (this.asyncIntervals[intervalIndex].run) {
      clearTimeout(this.asyncIntervals[intervalIndex].id);
      this.asyncIntervals[intervalIndex].run = false;
    }
  };

  sdsTimeframeTimerReadout = () => {
    this.asyncIntervals.forEach((obj, index) => {
      this.clearAsyncInterval(index);
    });
    this.textToSpeech(this.state.currentActiveEvent,
      () => this.timerText
    )
  }

  /**
   * This function will be called to get the readable text and trigger the text to speech
   * @param {String} id event id
   */
  textToSpeech(id, timer) {
    this.synth.cancel();
    setTimeout(() => {
      let isOnMute = this.isTtsOnMute();
      if (!isOnMute) {
        this.Requests.callAPI(this.Requests.getTextToSpeechMessage, id).then(
          async (data) => {
            if (!data || data.status !== 200) {
              let ErrorMessage =
                copy.turnout.turnoutTextToSpeechAPIErrorMessage +
                ` (Error #${copy.errorCodes.getTurnoutTextToSpeechErrorMessage})`;
              this.setState(
                {
                  errorMessage: ErrorMessage,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({
                        errorMessage: null,
                      });
                    }.bind(this),
                    5000
                  );
                }
              );
            } else {
              if (data && data.status && data.status === 200 && data.data) {
                const message = data.data;
                if (message) {
                  const {
                    ttsOn,
                    ttsRepeat,
                    timerReadout,
                    currentAlertType,
                    ttsGap,
                  } = this.state;

                  //play a message with appropriate tone and repetition
                  const playMessage = async (text, repeat, timerReadout) => {
                    const tone =
                      this.selectedTonePerAlertType(currentAlertType);
                    let messageText = "";
                    if (ttsOn) {
                      messageText = timerReadout ? [timer(), text] : text;
                    } else if (timerReadout) {
                      messageText = timer();
                    }

                    const audioFile = this.playAlertTonesReadMessage(
                      messageText,
                      repeat,
                      tone
                    );
                    await audioFile;
                  };

                  if (ttsRepeat !== 0) {
                    let count = 0;
                    // Set an interval for repeated playback
                    this.setAsyncInterval(async () => {
                      const repeat = count > 0;
                      await playMessage(message, repeat, timerReadout);
                      count++;

                      // Clear the interval once repetitions are fulfilled
                      if (count > ttsRepeat) {
                        this.asyncIntervals.forEach((obj, index) => {
                          this.clearAsyncInterval(index);
                        });
                      }
                    }, ttsGap);
                  } else {
                    // Play the message without repetition
                    playMessage(message, false, timerReadout);
                  }
                }
              }
            }
          }
        );
      }
    }, 1000);
  }

  removeLockedEvent = () => {
    this.setState({
      currentActiveEventIsLocked: false,
      currentActiveEvent: null,
      currentAlertType: null,
      expandedView: false,
    });
  };

  expandMap = () => {
    this.setState(
      (prevState) => ({
        expandedView: !prevState.expandedView,
      }),
      () => {
        if (this.state.expandedView) {
          this.setState({
            currentActiveEventIsLocked: true,
            showEventFilter: false,
          });
        }
      }
    );
  };

  /**
   * This function prepares the data that is used for the event filter
   */
  prepareFilterData = () => {
    // Preparing agency data for the filter
    let userAgenciesOption = [];
    this.props.userAgencies.forEach((agency, index) => {
      userAgenciesOption.push({
        key: index,
        text: agency,
        value: agency,
      });
    });
    this.setState({
      userAgenciesOption: userAgenciesOption,
    });

    if (this.props.pagingGroupsPartOf) {
      // Set state
      this.setState({
        userPagingGroupsOption: this.props.pagingGroupsPartOf,
      });
    } else {
      let ErrorMessage =
        copy.dashboard.pagingGroupsFilterAPIErrorMessage +
        ` (Error #${copy.errorCodes.pagingGroupsFilterAPIErrorMessage})`;
      this.setState(
        { errorMessage: ErrorMessage, userPagingGroupsOption: false },
        () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        }
      );
    }
  };

  /**
   * This function will toggle the event filter
   */
  toggleEventFilter = () => {
    this.setState((prevState) => ({
      showEventFilter: !prevState.showEventFilter,
    }));
  };

  /**
   * This function handles what happens when the user selects 'show all alerts'
   */
  AllTypes = () => {
    // Uncheck all checkboxes
    // Add all alert types to messageType array
    this.setState({
      allTypes: true,
      Emergency: false,
      NonEmergency: false,
      Admin: false,
      messageType: null,
    });
  };

  autoOrderPagerEvents = () => { };

  /**
   * This function handles what happens when one of the alter types is selected
   * type - the checkbox which was selected (Emergency, NonEmergency, Admin)
   */
  handleAlertTypeSelect = (type) => {
    // Clear pager alert types first
    if (this.state.allTypes === true) {
      this.setState({ messageType: [], allTypes: false });
    }
    this.setState(
      (prevState) => ({
        // Toggle checkbox that was selected
        [type]: !prevState[type],
      }),
      () => {
        // If the checkbox was checked
        if (this.state[type] === true) {
          // Add alert type admin to array
          let joined = this.state.messageType.concat(type);
          // If checkboxes have all been selected, then all alert types should be shown
          if (joined.length === 3) {
            this.setState({
              allTypes: true,
              Emergency: false,
              NonEmergency: false,
              Admin: false,
              messageType: null,
            });
          } else {
            // Update data
            this.setState({ messageType: joined });
          }
          // If the checkbox was unchecked
        } else {
          if (this.state.messageType !== null) {
            // Remove alert type from array
            var array = [...this.state.messageType]; // make a separate copy of the array
            var index = array.indexOf(type);
            if (index !== -1) {
              array.splice(index, 1);
              // If no boxes are checked, then all messages should be displayed
              if (array.length === 0) {
                this.setState({
                  allTypes: true,
                  Emergency: false,
                  NonEmergency: false,
                  Admin: false,
                  messageType: null,
                });
              } else {
                // Update data
                this.setState({ messageType: array });
              }
            }
          }
        }
      }
    );
  };

  /** This function renders the alert type filter
   */
  alertTypeFilters = () => {
    return (
      // all type radio btn
      <Wrapper>
        <label aria-label={copy.global.radioAll} className={styles.radio}>
          <input
            type="radio"
            name={copy.global.radioAll}
            checked={this.state.allTypes === true ? "checked" : ""}
            onChange={this.AllTypes}
          />
          <span className={styles.checkmark}></span>
          Show all alerts
        </label>
        {/* alert type checkboxes */}
        <label aria-label={copy.global.emerg} className={styles.checkbox}>
          <input
            type="checkbox"
            name={copy.global.emerg}
            checked={this.state.Emergency === true ? "checked" : ""}
            onChange={() => {
              this.handleAlertTypeSelect("Emergency");
            }}
          />
          {copy.global.emerg}
          <span className={styles.checkmark}></span>
        </label>

        <label aria-label={copy.global.nonEmerg} className={styles.checkbox}>
          <input
            type="checkbox"
            name={copy.global.nonEmerg}
            checked={this.state.NonEmergency === true ? "checked" : ""}
            onChange={() => {
              this.handleAlertTypeSelect("NonEmergency");
            }}
          />
          {copy.global.nonEmerg}
          <span className={styles.checkmark}></span>
        </label>

        <label aria-label={copy.global.admin} className={styles.checkbox}>
          <input
            type="checkbox"
            name={copy.global.admin}
            checked={this.state.Admin === true ? "checked" : ""}
            onChange={() => {
              this.handleAlertTypeSelect("Admin");
            }}
          />
          {copy.global.admin}
          <span className={styles.checkmark}></span>
        </label>
      </Wrapper>
    );
  };

  /**
   * This function handles what happens when the agency filter changes
   */
  handleAgencyFilterChange = (value) => {
    let tempPagingNumbers = this.state.pagingNumber;

    // If paging groups are currently being filtered
    if (tempPagingNumbers !== null && tempPagingNumbers.length !== 0) {
      // If an agency was removed
      if (
        value.length !== 0 &&
        this.state.agency !== null &&
        this.state.agency.length === value.length + 1
      ) {
        // // Get the removed agency
        let removedAgency = null;

        this.state.agency.forEach((agency) => {
          // Get the agency that was removed from the filter
          if (value.indexOf(agency) === -1) {
            removedAgency = agency;
          }
        });
        // Remove all paging groups from the filter that are part of that agency
        this.state.userPagingGroupsOption[removedAgency].forEach(
          (pagingGroup) => {
            let index = tempPagingNumbers.indexOf(pagingGroup.value);
            if (index !== -1) {
              tempPagingNumbers.splice(index, 1);
            }
          }
        );
        // If an agency was added when previously none were being filtered
      } else if (this.state.agency.length === 0 && value.length === 1) {
        let addedAgency = value[0];
        tempPagingNumbers = [];
        // Remove all paging groups from the filter that are not part of that agency
        this.state.userPagingGroupsOption[addedAgency].forEach(
          (pagingGroup) => {
            let index = this.state.pagingNumber.indexOf(pagingGroup.value);
            if (index !== -1) {
              tempPagingNumbers.push(pagingGroup.value);
            }
          }
        );
      }
    }

    // Update the events with the new agency filter applied
    this.setState({
      agency: value,
      pagingNumber: tempPagingNumbers,
    });
  };

  agencyAndPagingGroupFilter = () => {
    let pagingGroups = null;

    // If API call was successful and paging groups have been loaded, then render the filter
    if (this.state.userPagingGroupsOption) {
      // // Preparing paging group data for the filter
      let pagingGroupOption = [];
      // If user has not filtered by agency yet, they should be able to search all paging groups they are a part of
      if (this.state.agency === null || this.state.agency.length === 0) {
        // Go through all agencies the user is part of and add the paging groups to the search data
        this.props.userAgencies.forEach((agency) => {
          pagingGroupOption = pagingGroupOption.concat(
            this.state.userPagingGroupsOption[agency]
          );
        });
        pagingGroupOption = pagingGroupOption.concat(
          this.state.userPagingGroupsOption.all
        );
        // If the user has filtered by agency already, they should only be able to search the paging groups belonging to the
        // filtered agencies
      } else {
        // Go through all agencies the user is part of and add the paging groups to the search data
        this.state.agency.forEach((agency) => {
          pagingGroupOption = pagingGroupOption.concat(
            this.state.userPagingGroupsOption[agency]
          );
        });
      }

      // sort the paging groups in ascending order by name
      pagingGroupOption.sort((a, b) => {
        return a["text"].localeCompare(b["text"]);
      });

      pagingGroups = pagingGroupOption.length > 1 && (
        // this user must have more than 1 paging group for this filter to appear
        <div
          className={
            this.state.multiPagingGroup === true
              ? "hide pgroupDropdown"
              : "show pgroupDropdown " + styles.dropDownItem
          }
        >
          <span className={styles.dropdownLabel}>Paging Group</span>
          {this.state.multiPagingGroup === true && (
            <span
              aria-label="Multiple groups selected"
              className={styles.multi}
              onClick={this.changeBack}
            >
              <span>
                Multiple
                <span className={styles.desktopTxt}> groups </span> selected
              </span>
              <i aria-hidden="true" className="dropdown icon"></i>
            </span>
          )}
          <Dropdown
            fluid
            multiple
            search
            selection
            className={styles.dropdown}
            value={this.state.pagingNumber}
            options={pagingGroupOption}
            onChange={(e, { value }) => {
              this.setState({
                pagingNumber: value,
              });
            }}
          />
        </div>
      );
    }

    return (
      <Wrapper>
        {this.state.userAgenciesOption.length > 1 && (
          // this user must have more than 1 agency for this filter to appear
          <div
            className={
              this.state.multiAgency === true
                ? "hide agencyDropdown"
                : "show agencyDropdown " + styles.dropDownItem
            }
          >
            <span className={styles.dropdownLabel}>Agency</span>
            {this.state.multiAgency === true && (
              <span
                aria-label="Multiple agencies selected"
                className={styles.multi}
                onClick={this.changeBack}
              >
                <span>
                  Multiple
                  <span className={styles.desktopTxt}>agencies</span> selected
                </span>
                <i aria-hidden="true" className="dropdown icon"></i>
              </span>
            )}
            <Dropdown
              // placeholder="Agency"
              fluid
              multiple
              search
              selection
              className={styles.dropdown}
              value={this.state.agency}
              options={this.state.userAgenciesOption}
              on={"true"}
              onChange={(e, { value }) => this.handleAgencyFilterChange(value)}
            />
          </div>
        )}
        {/* Render paging group filter (if there are paging groups to show) */}
        {pagingGroups}
      </Wrapper>
    );
  };

  /**
   *
   * This function will render everything for the Turnout View.
   */
  turnoutRender() {
    return (
      <div className={[styles.content_wrapper].join(" ")}>
        {/* Audio element */}
        <audio ref={this.audioRef}>
          Your browser does not support the audio element.
        </audio>
        <div
          className={
            this.state.expandedView === false
              ? styles.col_one_fourth +
              " " +
              styles.turnoutContent +
              " " +
              styles.pagerEventsColumn
              : "hidden"
          }
        >
          <ActiveEventTimer
            updateEvent={this.state.updateTimerCounter}
            loadTimer={this.state.loadTimer}
            readableTimerText={this.readableTimerText}
            eventID={
              this.state.currentActiveEvent
                ? this.state.currentActiveEvent
                : null
            }
            isExpandedView={false}
            colourTimer={this.state.colourTimer}
            sdsTimeframeTarget={this.state.sdsTimeframeTarget}
            sdsTimeframeLimit={this.state.sdsTimeframeLimit}
            sdsTimeframeExceeded={this.state.sdsTimeframeExceeded}
            sdsTimeframeTimerReadout={this.sdsTimeframeTimerReadout}
          />
          <div
            className={
              this.state.showEventFilter === true
                ? styles.filterControlsWrapper
                : "hidden"
            }
          >
            <div className={styles.filterControls + " cardBg2"}>
              <p>LIMIT RESULTS TO:</p>
              <div className={styles.holder}>{this.alertTypeFilters()}</div>
              <div className={styles.holder}>
                {this.agencyAndPagingGroupFilter()}
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  label={copy.global.btnReset}
                  content={copy.global.btnReset}
                  variant="btn_outline"
                  styles={"btn_secondary " + styles.largeBtn}
                  buttonClick={() => {
                    this.setState(
                      {
                        agency: [],
                        pagingNumber: [],
                      },
                      () => {
                        this.AllTypes();
                      }
                    );
                  }}
                />
                <Button
                  label={copy.global.btnFilter}
                  content={copy.global.btnFilter}
                  variant="btn_outline green"
                  styles={"btn_secondary " + styles.largeBtn}
                  buttonClick={this.filter}
                />
              </div>
            </div>
          </div>
          <div id="pagerMessageContainer" className={styles.pagerEvents}>
            {/* The event state is null until the API to check for messages is complete. 
            So if its null, it will show the Spinner until the API call has returned. */}
            {/* {this.state.allActiveEvents ? (
              <PagerEvent
                event={this.state.allActiveEvents}
                buttonClick={this.updateActiveEvent.bind(this)}
              />
            ) : (
              <Loader />
            )} */}

            {this.state.allActiveEvents === null ||
              this.state.isLoadingAllEvents ? (
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            ) : this.state.allActiveEvents === false ? null : (
              <Wrapper>
                {this.state.isCheckingForEvents && (
                  <div className={styles.loadingEvents}>
                    <Loader />
                  </div>
                )}
                <InfiniteScroll
                  dataLength={this.state.allActiveEvents.length}
                  next={this.triggerPagination}
                  hasMore={this.state.hasMoreMessagesToLoad}
                  loader={
                    <div className={styles.pagerEventLoader}>
                      <Loader />
                    </div>
                  }
                  scrollableTarget="pagerMessageContainer"
                  // endMessage={
                  //   <div className={styles.pagerEventLoader}>
                  //     <span>{copy.global.noMessages}</span>
                  //   </div>
                  // }
                  scrollThreshold="100%"
                >
                  {this.state.allActiveEvents.length === 0 ? (
                    <h4>
                      <span className="noMessages">{copy.home.noMessages}</span>
                    </h4>
                  ) : (
                    <PagerEvent
                      event={this.state.allActiveEvents}
                      isEventUnreadArray={this.state.isEventUnreadArray}
                      buttonClick={this.updateActiveEvent}
                      lockedEvent={this.state.currentActiveEvent}
                    />
                  )}
                </InfiniteScroll>
              </Wrapper>
            )}
          </div>
        </div>
        <div
        ref={this.componentRef}
          className={
            this.state.printMode
            ? styles.col_full_screen +
              " " +
              styles.turnoutContent +
              " " +
              styles.eventDetailsSection
            : this.state.expandedView === false
            ? styles.col_three_fourth +
              " " +
              styles.turnoutContent +
              " " +
              styles.eventDetailsSection
            : styles.col_full_screen +
              " " +
              styles.turnoutContent +
              " " +
              styles.eventDetailsSection
          }
        >
          {this.state.latestEvent === false ? (
            <h4>
              <span className="noMessages">{copy.home.noMessages}</span>
            </h4>
          ) : (
            <>
             {this.printPageHeader()}
            
            <ActiveEventTurnoutView
              userAgencyInfo={this.props.userInfo.userAgencyInfo}
              expandMap={this.expandMap}
              isMapExpanded={this.state.expandedView}
              removeLockedEvent={this.removeLockedEvent}
              eventIsLocked={this.state.currentActiveEventIsLocked}
              updateEvent={this.state.updateEventCounter}
              eventID={
                this.state.currentActiveEvent
                  ? this.state.currentActiveEvent
                  : this.state.latestEvent
              }
              turnoutRefresh={this.handleRefresh}
              allActiveEvents={this.state.allActiveEvents}
              colourTimer={this.state.colourTimer}
              sdsTimeframeTarget={this.state.sdsTimeframeTarget}
              sdsTimeframeLimit={this.state.sdsTimeframeLimit}
              sdsTimeframeExceeded={this.state.sdsTimeframeExceeded}
              sdsTimeframeTimerReadout={this.sdsTimeframeTimerReadout}
              printMode={this.state.printMode}
            />
            {this.printPageFooter()}
            </>
          )}
        </div>
      </div>
    );
  }

  /**
   *
   * This function will toggle the Navigation Header from the turnout View.
   */
  hideNavigation = () => {
    this.setState({
      hidePageNavigation: true,
    });
  };

  redirectToSettings = () => {
    this.props.history.push({
      pathname: "/turnout/settings",
    });
  };

  showNavBar = () => {
    this.setState({
      hidePageNavigation: false,
    });
  };

  // updateTabVal = (tab) => {
  //   this.setState({
  //     currentTabVal: tab,
  //   });
  // };

  selectPagingGroup = (value) => {
    this.setState({ day: null, week: null, pagingGroup: value });

    let hrsBody = {
      pagingNumbers: [value],
      startDate: format(new Date(), "yyyy-MM-dd"),
      startTime: format(new Date(), "HH:mm"),
    };

    let daysBody = {
      pagingNumbers: [value],
      startDate: format(new Date(), "yyyy-MM-dd"),
    };

    // Show availability for the next 24hr for the selected paging group
    this.Requests.callAPI(this.Requests.getHrs, hrsBody, 24).then((data) => {
      if (data && data.status && data.status === 200) {
        // Get availability for the month
        this.setState({ day: data.data });
      } else {
        let ErrorMessage =
          copy.availability.getPaginGroupHourThresholdAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroupHourThresholdAvailAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          (prevState) => ({
            errorMessage: ErrorMessage,
            day: !prevState.day ? false : prevState.day,
          }),
          () => {
            this.messageTimeout = setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
    // Show availability for the next week for the selected paging group
    this.Requests.callAPI(this.Requests.getDays, daysBody, 7).then((data) => {
      if (data && data.status && data.status === 200) {
        // Get availability for the month
        this.setState({ week: data.data });
      } else {
        let ErrorMessage =
          copy.availability.getPaginGroupWeekThresholdAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroupWeekThresholdAvailAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          (prevState) => ({
            errorMessage: ErrorMessage,
            week: !prevState.week ? false : prevState.week,
          }),
          () => {
            this.messageTimeout = setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  printPageHeader = () => {
    return (
      <div className={styles.headerForPrint + " " + (this.state.printMode ? styles.showComponentForPrint : styles.hideComponentForPrint)}>{copy.turnout.settings.print.turnoutPrintedHeader}</div>
      )
  }
  printPageFooter = () => {
    return (
      <div className={styles.headerForPrint + " " + (this.state.printMode ? styles.showComponentForPrint : styles.hideComponentForPrint)}>{copy.turnout.settings.print.turnoutPrintedFooter}</div>
      )
  }
  print = async () => {
    try {
      this.setState({
        printMode: true,
      });
  
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          this.triggerRef.current.handlePrint();
          resolve();
        }, 500); // wait for map to load
      });
  
    } catch (error) {
      console.error("An error occurred in printing: ", error);
    }
  }
  handleAfterPrint = () => {
    this.setState({
      printMode: false,
    });
  };

  render() {
    let navigationBtnLabel = this.state.hidePageNavigation
      ? "Show Menu"
      : "Hide Menu";

    if (
      this.state.userAgenciesOption === null ||
      this.state.userPagingGroupsOption === null
    ) {
      return (
        <div className={styles.SASChat + styles.main_content_holder}>
          <div className={styles.chat_content_holder}>
            <div className={styles.fullPageLoader}>
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <div
            className={
              styles.fullScreenWrapper +
              " " +
              (this.state.hidePageNavigation && styles.fullscreen)
            }
          >
            {this.state.signalRDisconnected && (<div className={styles.turnoutSignalRStatus + " signalrstatusBg txt6"}>
              <Icon
                label=""
                content=""
                variant="icon_turnoutAlert"
                styles=""
                icon="icon_turnoutAlert"
              />
              <span>
                Unstable connection
              </span>
            </div>)
            }
            <div
              className={
                styles.turnoutWrapper +
                " dashboard bg5 " +
                (this.state.hidePageNavigation && "no_navigation")
              }
            >
              <div className={styles.turnoutNav + " bg3 "}>
                <div className={styles.buttonContainer}>
                  <Button
                    label={copy.global.btnFilterEvents}
                    content={copy.global.btnFilterEvents}
                    variant="btn_outline"
                    styles={
                      "btn_tertiary " +
                      styles.filter_btn +
                      " " +
                      (this.state.showFilters ? styles.isActive : "") +
                      (this.state.expandedView ? styles.visibility_hidden : "")
                    }
                    icon={styles.iconFilter}
                    buttonClick={this.toggleEventFilter}
                  />
                  <div className={styles.turnoutNavBtns}>
                    <ReactToPrint
                      ref={this.triggerRef}
                      trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return  <Button
                        label={"Print"}
                        content={"Print"}
                        styles={"btn_tertiary " + styles.hideMenu}
                        icon={styles.iconPrint}
                        buttonClick={this.print}
                      />;
                      }}
                      content={() => this.componentRef.current}
                      onAfterPrint={this.handleAfterPrint}
                    />
                    <Button
                      label={navigationBtnLabel}
                      content={navigationBtnLabel}
                      styles={"btn_tertiary " + styles.hideMenu}
                      icon={styles.iconHideNav}
                      buttonClick={this.hideNavigation}
                    />
                    <Button
                      label="Open Turnout Settings"
                      content=""
                      variant=""
                      styles={"btn_tertiary " + styles.settings_btn}
                      icon="icon_settings"
                      buttonClick={this.redirectToSettings}
                    />
                  </div>
                </div>
                <div className={styles.showFullScreenContainer}>
                  <div
                    className={styles.showNavButton}
                    onClick={this.showNavBar}
                  >
                    <div className={styles.button}>
                      <span>SHOW MENU</span>
                      <img src={HideNavigationIcon} alt="hide menu" />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={[
                  styles.main_content_holder,
                  styles.content_holder_no_shadow,
                ].join(" ")}
              >
                <div className={styles.noAvailability}>
                  {this.turnoutRender()}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      );
    }
  }
}
