// import _ from 'lodash'
import React, { Component } from "react";
import debounce from "lodash.debounce";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import { format } from "date-fns";
import Loader from "../../components/UI/Loading/Loading";

// Page Component
// import AlertBanner from "../../hoc/AlertBanner/AlertBanner";
import PagerEvent from "../../components/PagerEvents/PagerEvent";
// Page UIs & styles
import ScrollButton from "../../components/UI/Button/BackToTop";
import Button from "../../components/UI/Button/Button";
import Icon from "../../components/UI/Icon/Icon";
import Popup from "../../components/UI/Popup/Popup";
import { Dropdown } from "semantic-ui-react";
import styles from "./HomeView.module.scss";

import InfiniteScroll from "react-infinite-scroll-component";

// Requests for API calls
import { Requests } from "../../api/IdentityServerRequests/Requests";
// Data
//import PagerMessagesData from "../../api/sampleData/pagerMessages.json"; // When API is not available, use this to test
import copy from "../../assets/copy/copy.json";

// font icons for semantic UI
import "../../assets/fonts/icons.svg";
import "../../assets/fonts/icons.eot";
import "../../assets/fonts/icons.ttf";
import "../../assets/fonts/icons.woff";
import "../../assets/fonts/icons.woff2";

import "../../assets/fonts/brand-icons.svg";
import "../../assets/fonts/brand-icons.eot";
import "../../assets/fonts/brand-icons.ttf";
import "../../assets/fonts/brand-icons.woff";
import "../../assets/fonts/brand-icons.woff2";

import "../../assets/fonts/outline-icons.svg";
import "../../assets/fonts/outline-icons.eot";
import "../../assets/fonts/outline-icons.ttf";
import "../../assets/fonts/outline-icons.woff";
import "../../assets/fonts/outline-icons.woff2";

import PullToRefresh from "react-simple-pull-to-refresh";

/**
 * This is the home screen of the application.
 * Contains the availability header, refresh, filter, and sort buttons, and all pager events from the previous 24 hours
 * Reused component for pager history page with changes to PagerEvent and the API calls
 */
export default class App extends Component {
  // This sets up the functionality for receiving push notifications
  firebasePlugin;

  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      // Handles whether to display Loader or not
      isLoading: true,
      // Whether there are more messages able to be returned from the server: is true if messages are lazy loading or loading when page opened
      hasMoreMessagesToLoad: true,
      // Width of window
      width: window.innerWidth,
      // Whether the user is part of more than 1 agency (for filter dropdown)
      multiAgency: false,
      // Whether the user is part of more than 1 paging group (for filter dropdown)
      multiPagingGroup: false,
      // An array of all loaded pager messages
      allPagerMessages: [],
      // The sorting direction (DESC or ASC)
      sortDirection: "DESC",
      // The icon name (dependent on sort direction)
      sortIcon: "icon_sort",
      // Shows the filter options, opened when filter button is selected
      showFilters: false,
      // Shows all message types (emergency, nonemergency, admin) if true
      allTypes: true,
      // Shows emergency messages
      Emergency: false,
      // Shows non emergency messages
      NonEmergency: false,
      // Shows admin messages
      Admin: false,
      // Contains an array of all message types that are being shown
      messageType: null,
      // Agencies which are being filtered (input into the filter)
      agency: [],
      // Paging groups which are being filtered (input into the filter)
      pagingNumber: [],
      // dateTimeAfter: null,
      // msg: {
      //   take: 10,
      // },
      // skip: 0,
      // take: 30,
      // Agency data that is in a suitable format for the filter
      userAgenciesOption: null,
      // Maintains sort order of agencies based on back-end
      userAgenciesOrder: null,
      // Paging groups data that is in a suitable format for the filter
      userPagingGroupsOption: null,
      // The user's current availability
      available: null,
      // State for whether pager events are scrollable
      scrollable: true,
      showDeletePrompt: false,
      isFiltered:
        window.sessionStorage.getItem("redirectHomeFromEvent") === "true"
          ? true
          : false,
    };
    this.sessionAgency = window.sessionStorage.getItem("agency");
    this.sessionAlert = window.sessionStorage.getItem("messageType");
    this.sessionPagingGroup = window.sessionStorage.getItem("pagingNumber");
    this.notificationPoppedUp = window.localStorage.getItem(
      "notificationPoppedup"
    );
    this.acceptedAndroidAutoTerms = window.localStorage.getItem(
      "acceptedAndroidAutoTerms"
    );
  }

  componentDidMount() {
    if (document.getElementsByClassName("dhx_cal_cover").length > 0) {
      document.getElementsByClassName("dhx_cal_cover")[0].style.display =
        "none";
    }

    window.scrollTo(0, 0);
    // Get the currently logged in user's next time available in the Home page
    if (
      !this.props.pagerHistory &&
      (this.props.userAgencies.includes("CFA") ||
        this.props.userAgencies.includes("SES"))
    ) {
      this.getNextAvail();
    }
    // get current date time for 24hr worth of pager message for the first load
    // THIS NEEDS TO BE TURNED ON WHEN WE HAVE PAGER MESSAGES COMING IN

    //this.getMessages();
    this.handleRefresh();

    //this.getCurrentPagerMessages();

    // This is to avoid prepareFilterData being called on logout in the mobile app
    if (window.cordova) {
      this.Requests.getAccessToken().then((accessToken) => {
        if (accessToken !== null) {
          this.prepareFilterData();
        }
      });

      if (
        // on an Android device, check if the user has accepted AA terms every time app launches
        window.cordova.platformId === "android" &&
        !this.acceptedAndroidAutoTerms
      ) {
        this.redirectToAndroidauto();
      }
    } else {
      this.prepareFilterData();
    }
    if (window.cordova && window.cordova.platformId === "android") {
      if (this.acceptedAndroidAutoTerms === "true") {
        window.SasFcmNotificationReceiverPlugin.setAutoStatus(true);
      } else {
        window.SasFcmNotificationReceiverPlugin.setAutoStatus(false);
      }
      if (
        this.notificationPoppedUp === null ||
        this.notificationPoppedUp === "null"
      ) {
        window.localStorage.setItem("notificationPoppedup", "true");
      }
    }

    if (this.state.isFiltered) {
      this.sessionAgency &&
        this.handleAgencyFilterChange(JSON.parse(this.sessionAgency));
      this.sessionAlert && this.handleAlertTypeSelect();
      this.sessionPagingGroup &&
        this.handlePagingGroupFilterChange(JSON.parse(this.sessionPagingGroup));

      // this.agencyAndPagingGroupFilters();
    }

    //this.getAgencyAndPagingGroup(); // placeholder - not ready yet
    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  checkPemissionsRun() {
    var permissions = window.cordova.plugins.permissions;

    permissions.hasPermission(
      permissions.POST_NOTIFICATIONS,
      function (status) {
        if (status.hasPermission) {
          // console.log("Yes :D ");
        } else {
          permissions.requestPermission(
            permissions.POST_NOTIFICATIONS,
            success,
            error
          );
          function error() {
            // console.warn("Notification permission is not turned on");
          }

          function success(status) {
            if (!status.hasPermission) error();
          }
        }
      }
    );
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener(
      "orientationchange",
      this.handleWindowSizeChange
    );
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  redirectToAndroidauto = () => {
    this.Requests.callAPI(
      this.Requests.getCurrentAcceptedTermsOfUseAndroidAuto
    ).then((data) => {
      if (data?.status === 200) {
        if (
          data.data.DateTimeAccepted === null &&
          data.data.DateTimeDeclined === null
        ) {
          //if the user has not responded to AA disclosure
          this.props.history.push({
            pathname: "/android-auto",
          });
        } else if (data.data.DateTimeAccepted) {
          window.localStorage.setItem("acceptedAndroidAutoTerms", "true");
          window.SasFcmNotificationReceiverPlugin.setAutoStatus(true);
        } else {
          window.localStorage.setItem("acceptedAndroidAutoTerms", "false");
          window.SasFcmNotificationReceiverPlugin.setAutoStatus(false);
        }
      } else {
        let ErrorMessage =
          copy.settings.profile.androidAutoPermissionError +
          ` (Error #${copy.errorCodes.androidAutoPermissionError})`;
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * Gets the next time the user is available
   */
  getNextAvail() {
    // Calls API to get next available time
    this.Requests.callAPI(this.Requests.getNewAvail).then((data) => {
      if (data && data.data && data.status && data.status === 200) {
        // Null pointer checks to see if required data is returned
        if (
          data.data.dateAvailable &&
          data.data.timeStart &&
          data.data.timeEnd
        ) {
          // Converts date into Day of week (Mon, Tue), Day of month (1st, 2nd), Month (Jan, Feb), Year (2020, 2021)
          let date = format(
            new Date(data.data.dateAvailable),
            "iii do MMM yyyy"
          );
          // Check to see if the availability for the user is all day
          if (
            data.data.timeStart === "00:00:00" &&
            data.data.timeEnd === "23:59:59"
          ) {
            // Sets state of start and end of date
            this.setState({
              available: {
                date: date,
                start: "allDay",
                end: "allDay",
              },
            });
          } else {
            // Sets state of start and end of date
            this.setState({
              available: {
                date: date,
                start: data.data.timeStart.slice(0, 5),
                end: data.data.timeEnd.slice(0, 5),
              },
            });
          }
        }
      } else {
        let ErrorMessage =
          copy.home.nextAvailabilityAPIErrorMessage +
          ` (Error #${copy.errorCodes.nextAvailabilityAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  }

  /**
   * Calls getCurrentPagerMessages if all messages have not been loaded
   */
  async getMessages() {
    // let tempDate = new Date();
    // let date =
    //   tempDate.getFullYear() +
    //   "-" +
    //   (tempDate.getMonth() + 1) +
    //   "-" +
    //   (tempDate.getDate() - 1) +
    //   " " +
    //   tempDate.getHours() +
    //   ":" +
    //   tempDate.getMinutes() +
    //   ":" +
    //   tempDate.getSeconds();

    //date format yyyy-mm-dd T hh:mm:ss:ms - 2018-08-08T05:21:05.312Z
    let initialMessages = window.cordova
      ? await this.getFromMobileStorage("initialPagerMessages")
      : null;

    this.setState(
      {
        allPagerMessages:
          initialMessages && initialMessages !== "null" ? initialMessages : [],
        isLoading: false,
        hasMoreMessagesToLoad: true,
      },
      () => {
        this.getCurrentPagerMessages();
      }
    );
    // this.setState({ hasMoreMessagesToLoad: true }, () =>
    //   this.getCurrentPagerMessages()
    // );

    // if (date !== null) {
    //   return Promise.resolve(date);
    // } else {
    //   return Promise.resolve(false);
    // }
  }

  /**
   * This function prepares the data that is used for the event filter
   */
  prepareFilterData = () => {
    // Preparing agency data for the filter
    let userAgenciesOption = [];
    // If the user has agencies
    if (this.props.userAgencies) {
      // Get information for each agency
      this.props.userAgencies.forEach((agency, index) => {
        userAgenciesOption.push({
          key: index,
          text: agency,
          value: agency,
        });
      });
    }

    // Set state
    this.setState({
      userAgenciesOption: userAgenciesOption,
    });

    if (this.props.pagingGroupsPartOf && this.props.userAgencies) {
      // Set state
      this.setState({
        userPagingGroupsOption: this.props.pagingGroupsPartOf,
        userAgenciesOrder: this.props.userAgencies,
      });
    } else {
      let ErrorMessage =
        copy.home.pagingGroupsFilterAPIErrorMessage +
        ` (Error #${copy.errorCodes.pagingGroupsFilterAPIErrorMessage})`;
      this.setState(
        { errorMessage: ErrorMessage, userPagingGroupsOption: false },
        () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        }
      );
    }
  };

  /**
   * This function calls the next 30 pager messages and adds them to the end of the list if the user scrolls to the bottom of the page
   */
  triggerPagination = () => {
    // const bottom =
    //   e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    // if (bottom) {
    if (
      this.state.allPagerMessages.length >= 15 &&
      this.state.hasMoreMessagesToLoad
    ) {
      let messageId;
      // let messageDateTimeBefore; Not being used any more
      if (
        this.state.allPagerMessages &&
        this.state.allPagerMessages.length > 1
      ) {
        messageId =
          this.state.allPagerMessages[this.state.allPagerMessages.length - 1]
            .Id;

        // messageDateTimeBefore = this.state.allPagerMessages[  Not being used any more
        //   this.state.allPagerMessages.length - 1
        // ].messageInputTimeStamp;
      }

      const body = {
        // If we are on pagerHistory page, then messages displayed should not be restricted on date and time
        // If we are sorting by ascending then dateTimeAfter should be updated
        // dateTimeAfterOrEqual: this.props.pagerHistory
        //   ? null
        //   : this.state.sortDirection === "DESC"
        //   ? this.state.dateTimeAfter
        //   : messageDateTimeBefore,
        isHomePage: !this.props.pagerHistory ? true : false,
        messageType: this.state.messageType ? this.state.messageType : null,
        agency: this.state.agency.length === 0 ? null : this.state.agency,
        pagingNumber:
          this.state.pagingNumber.length === 0 ? null : this.state.pagingNumber,
        // If we are sorting by ascending, the message at the bottom of the screen is the message after
        [this.state.sortDirection === "DESC"
          ? "messageIdBefore"
          : "messageIdAfter"]: messageId,

        // dateTimeBefore is not needed if we are sorting by ascending
        // dateTimeBeforeOrEqual:
        //   this.state.sortDirection === "DESC" ? messageDateTimeBefore : null,
        skip: 0,
        take: 15,
        sortDirection: this.state.sortDirection,
      };

      this.getCurrentPagerMessages(body);
    } else {
      if (this.state.hasMoreMessagesToLoad) {
        this.setState({
          hasMoreMessagesToLoad: false,
        });
      }
    }
    // }
  };

  getFromMobileStorage = (key) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.getObject(key, resolve, reject);
    });

  setInMobileStorage = (key, value) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.putObject(key, value, resolve, reject);
    });

  async storeInitialMessages(key, value) {
    if (window.cordova) {
      await this.setInMobileStorage(key, value);
    }
  }

  /**
   * Calls pager message API to get 15 most recent and not loaded pager messages.
   * If there are already loaded pager messages it will be added to the end of the current list
   * @param {array} Body The list of current pager messages
   */
  getCurrentPagerMessages = debounce((Body) => {
    // Send null value for agency & paging Group = all agencies and paging groups
    // msg is the options for pager message API
    let msg = {};

    if (Body) {
      msg = Body;
    } else {
      // isHomePage is true if not pager history
      if (!this.props.pagerHistory) {
        msg = {
          isHomePage: true,
          skip: 0,
          take: 15,
          sortDirection: this.state.sortDirection,
        };
        // isHomePage is false if it is pager history
      } else {
        msg = {
          isHomePage: false,
          skip: 0,
          take: 30,
          sortDirection: this.state.sortDirection,
        };
      }
    }

    //isLoading needs to be set to true when we want the whole body to load
    //isLoading needs to be false when it is only pagination
    this.setState(
      {
        isLoading: !Body ? true : false,
      },
      () => {
        // Calls pager message API
        this.Requests.callAPI(this.Requests.postPagerMessages, msg).then(
          (data) => {
            if (data === undefined) {
              this.props.history.push({
                pathname: "/login",
              });
            } else {
              if (data && data.status && data.status === 200) {
                let allPagerMessages;
                if (data.data) {
                  // Combines current pager messages and new pager messages
                  allPagerMessages = this.state.allPagerMessages.concat(
                    data.data
                  );
                  // Mobile only - remove old storage messages for refresh
                  if (window.cordova && this.state.allPagerMessages && !Body) {
                    allPagerMessages.splice(
                      0,
                      this.state.allPagerMessages.length
                    );
                  }
                }
                // Since  messages were asked for, if number of messages returned is less than 30 then all have been returned and there are no more to load
                if (data.data.length < 15) {
                  this.setState({
                    hasMoreMessagesToLoad: false,
                  });
                }
                // If there's no body and no pager messages then array should be empty
                if (!Body) {
                  this.setState(
                    {
                      allPagerMessages: data.data[0] ? allPagerMessages : [],
                      isLoading: false,
                    },
                    () => {
                      this.storeInitialMessages(
                        "initialPagerMessages",
                        allPagerMessages.slice(0, 15)
                      );
                    }
                  );
                } else {
                  this.setState({
                    allPagerMessages: allPagerMessages,
                    isLoading: false,
                  });
                }
              } else if (data && data.status && data.status === 401) {
                window.localStorage.removeItem("rolesNotValid");
                this.props.history.push({
                  pathname: "/login",
                });
              } else {
                let ErrorMessage = copy.home.messageAPIErrorMessage;
                // if (data && data.data && data.data.SASMessageClient) {
                //   ErrorMessage = data.data.SASMessageClient;
                // }

                // if Mobile - shows stored messages from the native storage
                // if Web - shows error messages only
                if (window.cordova) {
                  this.setState(
                    {
                      isLoading: false,
                      errorMessage: ErrorMessage,
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({ errorMessage: null });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                } else {
                  this.setState(
                    {
                      allPagerMessages: false,
                      isLoading: false,
                      errorMessage: ErrorMessage,
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({ errorMessage: null });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                }
              }
            }
          }
        );
      }
    );

    // if (body) {
    //   this.Requests.callAPI(this.Requests.postPagerMessages, msg).then(data => {
    //     if (data === undefined) {
    //       this.props.history.push({
    //         pathname: "/login"
    //       });
    //     } else {
    //       if (data && data.status && data.status === 200) {
    //         let allPagerMessages;
    //         if (data.data) {
    //           allPagerMessages = this.state.allPagerMessages.concat(data.data);
    //         }

    //         this.setState({
    //           allPagerMessages: allPagerMessages,
    //           isLoading: false
    //         });

    //         if (data.data.length === 0) {
    //           this.setState({
    //             hasMoreMessagesToLoad: false
    //           });
    //         }
    //       } else {
    //         this.props.history.push({
    //           pathname: "/*"
    //         });
    //       }
    //     }
    //   });
    // } else {
    //   this.setState(
    //     {
    //       isLoading: true,
    //       allPagerMessages: []
    //     },
    //     () => {
    //       this.Requests.callAPI(this.Requests.postPagerMessages, msg).then(
    //         data => {
    //           if (data === undefined) {
    //             this.props.history.push({
    //               pathname: "/login"
    //             });
    //           } else {
    //             if (data && data.status && data.status === 200) {
    //               let allPagerMessages;
    //               if (data.data) {
    //                 allPagerMessages = this.state.allPagerMessages.concat(
    //                   data.data
    //                 );
    //               }
    //               this.setState({
    //                 allPagerMessages: allPagerMessages,
    //                 isLoading: false
    //               });
    //             } else {
    //               this.props.history.push({
    //                 pathname: "/*"
    //               });
    //             }
    //           }
    //         }
    //       );
    //     }
    //   );
    // }
    // this.getDateTime() // Get current date time for 24hr worth of messages when a user clicks 'show all alerts'
  }, 100);

  /**
   * Changes API options for sorting and makes another API call
   */
  sortPagerMsg = () => {
    // API options (note sort direction is reversed)
    let msg = {
      messageType: this.state.messageType,
      agency: this.state.agency.length === 0 ? null : this.state.agency,
      pagingNumber:
        this.state.pagingNumber.length === 0 ? null : this.state.pagingNumber,
      // dateTimeAfterOrEqual: !this.props.pagerHistory
      //   ? this.state.dateTimeAfter
      //   : null,
      isHomePage: !this.props.pagerHistory ? true : false,
      sortDirection: this.state.sortDirection === "DESC" ? "ASC" : "DESC",
    };

    // Clears current pager messages and adds loader
    this.setState(
      {
        isLoading: true,
        allPagerMessages: [],
        hasMoreMessagesToLoad: true,
      },
      () => {
        this.getCurrentPagerMessages(msg);
      }
    );

    // Reflect change in sort direction in state
    if (this.state.sortDirection === "DESC") {
      this.setState({ sortDirection: "ASC", sortIcon: "icon_sort flip" });
    } else {
      this.setState({ sortDirection: "DESC", sortIcon: "icon_sort" });
    }
  };

  /**
   * Changes width in state and removes fullscreen-map-mode if it is active (should only appear on map screens)
   */
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
    if (document.body.classList.contains("fullscreen-map-mode")) {
      document.body.classList.remove("fullscreen-map-mode");
    }
  };

  /**
   * Handles click on Edit button in the current availability header and navigates user to the settings page
   */
  redirectToSettings = () => {
    this.props.history.push({
      pathname: "/settings/availability",
    });
  };

  /**
   * Handles click on pager event and navigates user to the event overview screen for the event they have selected
   * @param {Object} currentEvent The event object of the selected pager event
   */
  redirectToEvent = (currentEvent) => {
    let isMobileApp = window.cordova;
    // Query string contain information about the event to display before the event API loads
    if (isMobileApp) {
      this.props.history.push({
        pathname: "/eventdetails/" + currentEvent.eventId,
        search:
          "eventStatus=" +
          currentEvent.eventStatus +
          "&agency=" +
          currentEvent.agency +
          "&cADEventId=" +
          currentEvent.cADEventId +
          "&messageInputTimeStamp=" +
          currentEvent.messageInputTimeStamp +
          "&messageType=" +
          currentEvent.messageType +
          "&pagingName=" +
          currentEvent.paging.name +
          "&messageText=" +
          currentEvent.messageText,
      });
    } else {
      this.props.history.push({
        pathname: "/eventdetails/" + currentEvent.eventId,
        search:
          "eventStatus=" +
          currentEvent.eventStatus +
          "&messageType=" +
          currentEvent.messageType,
      });
    }
  };

  /**
   * Refreshes all pager events by making an API call with the current settings
   */
  handleRefresh = async () => {
    // Remake API call if the paging group API failed
    if (!this.state.userPagingGroupsOption) {
      this.prepareFilterData();
    }
    let initialMessages = [];
    if (this.state.isFiltered !== true && window.cordova) {
      initialMessages = await this.getFromMobileStorage("initialPagerMessages");
    }

    if (this.state.allTypes === true) {
      this.setState(
        {
          isLoading: true,
          allPagerMessages:
            initialMessages && initialMessages !== "null"
              ? initialMessages
              : [],
          hasMoreMessagesToLoad: true,
        },
        () => {
          this.getCurrentPagerMessages();
        }
      );
    } else {
      this.changeDropdown();
    }
  };

  /**
   * Unchecks all checkboxes, and adds all alert types to messageType array. Calls all pager messages.
   */
  AllTypes = () => {
    this.setState(
      {
        allTypes: true,
        Emergency: false,
        NonEmergency: false,
        Admin: false,
        messageType: null,
        allPagerMessages: [],
        hasMoreMessagesToLoad: true,
      },
      () => {
        this.getCurrentPagerMessages({
          messageType: this.state.messageType,
          agency: this.state.agency.length === 0 ? null : this.state.agency,
          pagingNumber:
            this.state.pagingNumber.length === 0
              ? null
              : this.state.pagingNumber,
          // dateTimeAfterOrEqual: !this.props.pagerHistory
          //   ? this.state.dateTimeAfter
          //   : null,
          isHomePage: !this.props.pagerHistory ? true : false,
          sortDirection: this.state.sortDirection,
        });
        window.sessionStorage.removeItem("messageType");
      }
    );
  };

  /**
   * This function handles what happens when one of the alter types is selected
   * type - the checkbox which was selected (Emergency, NonEmergency, Admin)
   */
  handleAlertTypeSelect = (type) => {
    // console.log("handleAlertTypeSelect", type);
    // Clear pager alert types first
    if (this.state.allTypes === true) {
      this.setState({ messageType: [], allTypes: false });
    }
    this.setState(
      (prevState) => ({
        // Toggle checkbox that was selected
        [type]: !prevState[type],
      }),
      () => {
        // If the checkbox was checked
        if (this.state[type] === true) {
          // Add alert type admin to array
          let joined;
          if (type !== undefined) {
            joined = this.state.messageType.concat(type);
          } else {
            // when user is going back to home from event details page
            joined = this.sessionAlert ? JSON.parse(this.sessionAlert) : [];
            this.setState({
              Emergency: joined.includes("Emergency"),
              NonEmergency: joined.includes("NonEmergency"),
              Admin: joined.includes("Admin"),
            });
          }

          // If checkboxes have all been selected, then all alert types should be shown
          if (joined.length === 3) {
            this.setState(
              {
                allTypes: true,
                Emergency: false,
                NonEmergency: false,
                Admin: false,
                messageType: null,
                isLoading: true,
                allPagerMessages: [],
                hasMoreMessagesToLoad: true,
              },
              () => {
                this.changeDropdown();
                window.sessionStorage.removeItem("messageType");
                window.sessionStorage.removeItem("redirectHomeFromEvent");
              }
            );
          } else {
            // Update data
            this.setState(
              {
                messageType: joined,
                isLoading: true,
                allPagerMessages: [],
                hasMoreMessagesToLoad: true,
              },
              () => {
                this.changeDropdown();
                window.sessionStorage.setItem(
                  "messageType",
                  JSON.stringify(joined)
                );
              }
            );
          }
          // If the checkbox was unchecked
        } else {
          if (this.state.messageType !== null) {
            // Remove alert type from array
            var array = [...this.state.messageType]; // make a separate copy of the array
            var index = array.indexOf(type);
            if (index !== -1) {
              array.splice(index, 1);
              // If no boxes are checked, then all messages should be displayed
              if (array.length === 0) {
                this.setState(
                  {
                    allTypes: true,
                    Emergency: false,
                    NonEmergency: false,
                    Admin: false,
                    messageType: null,
                    isLoading: true,
                    allPagerMessages: [],
                    hasMoreMessagesToLoad: true,
                  },
                  () => {
                    this.changeDropdown();
                    window.sessionStorage.removeItem("messageType");
                  }
                );
              } else {
                // Update data
                this.setState(
                  {
                    messageType: array,
                    isLoading: true,
                    allPagerMessages: [],
                    hasMoreMessagesToLoad: true,
                  },
                  () => {
                    this.changeDropdown();
                    window.sessionStorage.setItem(
                      "messageType",
                      JSON.stringify(array)
                    );
                  }
                );
              }
            }
          }
        }
      }
    );
  };

  /**
   * Hides/shows the filtesr after the Filter button is clicked
   */
  toggleFilter = () => {
    // Toggle filters - show/hide the filters on mobile/tablets
    this.setState((prevState) => ({ showFilters: !prevState.showFilters }));
  };

  /**
   * Hide the multiple agencies & groups and just show text 'multiple selected' in the dropdown menu in the filter
   */
  changeDropdown = () => {
    //Getting rid of this conditional as doesn't show multiple agency/groups text when set on show all alerts
    //if (this.state.allTypes === false) {
    // Multiple agencies
    if (this.state.agency !== null) {
      if (this.state.agency.length > 1) {
        this.setState({ multiAgency: true });
      }
    }
    // Multiple paging groups
    if (this.state.pagingNumber !== null) {
      if (this.state.pagingNumber.length > 1) {
        this.setState({ multiPagingGroup: true });
      }
    }
    //}

    // Send call to pager msg API with new values
    this.agencyAndPagingGroupFilters();
    // this.getDateTime();

    // API options
    let msg = {
      messageType: this.state.messageType,
      agency: this.state.agency.length === 0 ? null : this.state.agency,
      pagingNumber:
        this.state.pagingNumber.length === 0 ? null : this.state.pagingNumber,
      // dateTimeAfterOrEqual: !this.props.pagerHistory
      //   ? this.state.dateTimeAfter
      //   : null,
      isHomePage: !this.props.pagerHistory ? true : false,
      sortDirection: this.state.sortDirection,
    };

    // Calls API for new messages
    this.setState(
      {
        isLoading: true,
        allPagerMessages: [],
        hasMoreMessagesToLoad: true,
      },
      () => {
        this.getCurrentPagerMessages(msg);
      }
    );
  };

  /**
   * Show the multiple agencies & groups and hide the text 'multiple selected' in the dropdown menu in the filter
   */
  changeBack = () => {
    this.setState(
      {
        multiAgency: false,
        multiPagingGroup: false,
      },
      () => {
        this.agencyAndPagingGroupFilters();
      }
    );
  };

  /**
   * Contains Show All Alerts radio button and 3 message type checkboxes
   */
  alertTypeFilters = () => {
    return (
      <Wrapper>
        {/* Show all alerts radio button */}
        <label aria-label={copy.global.radioAll} className={styles.radio}>
          <input
            type="radio"
            name={copy.global.radioAll}
            checked={this.state.allTypes === true ? "checked" : ""}
            onChange={!this.state.isLoading && this.AllTypes}
          />
          <span className={styles.checkmark}></span>
          Show all alerts
        </label>
        {/* Emergency checkbox */}
        <label aria-label={copy.global.emerg} className={styles.checkbox}>
          <input
            type="checkbox"
            name={copy.global.emerg}
            checked={this.state.Emergency === true ? "checked" : ""}
            onChange={
              !this.state.isLoading
                ? () => this.handleAlertTypeSelect("Emergency")
                : null
            }
          />
          {copy.global.emerg}
          <span className={styles.checkmark}></span>
        </label>
        {/* Non-Emergency checkbox */}
        <label aria-label={copy.global.nonEmerg} className={styles.checkbox}>
          <input
            type="checkbox"
            name={copy.global.nonEmerg}
            checked={this.state.NonEmergency === true ? "checked" : ""}
            onChange={
              !this.state.isLoading
                ? () => this.handleAlertTypeSelect("NonEmergency")
                : null
            }
          />
          {copy.global.nonEmerg}
          <span className={styles.checkmark}></span>
        </label>
        {/* Admin checkbox */}
        <label aria-label={copy.global.admin} className={styles.checkbox}>
          <input
            type="checkbox"
            name={copy.global.admin}
            checked={this.state.Admin === true ? "checked" : ""}
            onChange={
              !this.state.isLoading
                ? () => this.handleAlertTypeSelect("Admin")
                : null
            }
          />
          {copy.global.admin}
          <span className={styles.checkmark}></span>
        </label>
      </Wrapper>
    );
  };

  /**
   * This function is used to handle when the agency filter values change
   * It is needed because changes in agency will affect the paging numbers being displayed
   * This function handles what happens when the agency filter changes
   * @param {} value This new filter values
   */
  handleAgencyFilterChange = (value) => {
    let tempPagingNumbers = this.state.pagingNumber;

    // If paging groups are currently being filtered
    if (tempPagingNumbers !== null && tempPagingNumbers.length !== 0) {
      // If an agency was removed
      if (
        value.length !== 0 &&
        this.state.agency !== null &&
        this.state.agency.length === value.length + 1
      ) {
        // Get the removed agency
        let removedAgency = null;

        this.state.agency.forEach((agency) => {
          // Get the agency that was removed from the filter
          if (value.indexOf(agency) === -1) {
            removedAgency = agency;
          }
        });

        // Remove all paging groups from the filter that are part of that agency
        this.state.userPagingGroupsOption[removedAgency].forEach(
          (pagingGroup) => {
            let index = tempPagingNumbers.indexOf(pagingGroup.value);
            if (index !== -1) {
              tempPagingNumbers.splice(index, 1);
            }
          }
        );
        // If an agency was added when previously none were being filtered
      } else if (this.state.agency.length === 0 && value.length === 1) {
        let addedAgency = value[0];
        tempPagingNumbers = [];
        // Remove all paging groups from the filter that are not part of that agency
        this.state.userPagingGroupsOption[addedAgency].forEach(
          (pagingGroup) => {
            let index = this.state.pagingNumber.indexOf(pagingGroup.value);
            if (index !== -1) {
              tempPagingNumbers.push(pagingGroup.value);
            }
          }
        );
      }
    }

    // Update the events with the new agency filter applied
    this.setState(
      {
        agency: value,
        pagingNumber: tempPagingNumbers,
      },
      () => {
        this.changeDropdown();

        window.sessionStorage.setItem(
          "agency",
          JSON.stringify(this.state.agency)
        );
      }
    );
  };

  handlePagingGroupFilterChange = (value) => {
    console.log("handlePagingGroupFilterChange", value);
    this.setState(
      {
        pagingNumber: value,
      },
      () => {
        window.sessionStorage.setItem(
          "pagingNumber",
          JSON.stringify(this.state.pagingNumber)
        );
        this.changeDropdown();
      }
    );
  };

  /**
   * Contains filters and dropdowns for agency and paging group filters
   */
  agencyAndPagingGroupFilters = () => {
    let pagingGroups = null;
    // If API call was successful and paging groups have been loaded, then render the filter
    if (this.state.userPagingGroupsOption) {
      // Preparing paging group data for the filter
      let pagingGroupOption = [];
      // If user has not filtered by agency yet, they should be able to search all paging groups they are a part of
      if (this.state.agency === null || this.state.agency.length === 0) {
        // Go through all agencies the user is part of and add the paging groups to the search data
        if (this.state.userAgenciesOrder) {
          this.state.userAgenciesOrder.forEach((agency) => {
            pagingGroupOption = pagingGroupOption.concat(
              this.state.userPagingGroupsOption[agency]
            );
          });
        }
        // If the user has filtered by agency already, they should only be able to search the paging groups belonging to the
        // filtered agencies
      } else {
        // Go through all agencies the user is part of and add the paging groups to the search data
        this.state.agency.forEach((agency) => {
          pagingGroupOption = pagingGroupOption.concat(
            this.state.userPagingGroupsOption[agency]
          );
        });
      }

      // add personal paging numbers to paging group filter options
      if (this.state.userPagingGroupsOption.all) {
        pagingGroupOption = pagingGroupOption.concat(
          this.state.userPagingGroupsOption.all
        );
      }

      // sort the paging groups in ascending order by name
      pagingGroupOption.sort((a, b) => {
        return a["text"].localeCompare(b["text"]);
      });

      pagingGroups = pagingGroupOption.length > 1 && (
        // this user must have more than 1 paging group for this filter to appear
        <div
          className={
            this.state.multiPagingGroup === true
              ? "hide pgroupDropdown wrappedDropdown"
              : "show pgroupDropdown wrappedDropdown"
          }
        >
          <span className={styles.dropdownLabel}>Paging Group</span>
          {this.state.multiPagingGroup === true && (
            <span
              aria-label="Multiple groups selected"
              className={styles.multi}
              onClick={!this.isLoading && this.changeBack}
            >
              <span>
                Multiple
                <span className={styles.desktopTxt}> groups </span> selected
              </span>
              <i aria-hidden="true" className="dropdown icon"></i>
            </span>
          )}

          <Dropdown
            placeholder="Paging Group"
            //fluid
            multiple
            search
            selection
            value={this.state.pagingNumber}
            options={pagingGroupOption}
            onChange={
              !this.state.isLoading
                ? (e, { value }) => this.handlePagingGroupFilterChange(value)
                : null
            }
          />
        </div>
      );
    }

    return (
      <Wrapper>
        {this.state.userAgenciesOption.length > 1 && (
          // this user must have more than 1 agency for this filter to appear
          <div
            className={
              this.state.multiAgency === true
                ? "hide agencyDropdown wrappedDropdown"
                : "show agencyDropdown wrappedDropdown"
            }
          >
            <span className={styles.dropdownLabel}>Agency</span>

            {this.state.multiAgency === true && (
              <span
                aria-label="Multiple agencies selected"
                className={styles.multi}
                onClick={!this.state.isLoading && this.changeBack}
              >
                <span>
                  Multiple
                  <span className={styles.desktopTxt}>agencies</span> selected
                </span>
                <i aria-hidden="true" className="dropdown icon"></i>
              </span>
            )}

            <Dropdown
              placeholder="Agency"
              //fluid
              multiple
              search
              selection
              value={this.state.agency}
              options={this.state.userAgenciesOption}
              on={"true"}
              onChange={
                !this.state.isLoading
                  ? (e, { value }) => this.handleAgencyFilterChange(value)
                  : null
              }
            />
          </div>
        )}
        {/* Render paging group filter (if there are paging groups to show) */}
        {pagingGroups}
      </Wrapper>
    );
  };

  /**
   * Returns web filter
   */
  webFilter = () => {
    return (
      <div className={styles.filterControls}>
        {/* Filter APIs failing, don't load the data into the dropdowns, error banner will come up instead*/}
        {this.alertTypeFilters()}
        <div className={styles.holder}>
          {/* Filter APIs failing, don't load the data into the dropdowns, error banner will come up instead*/}
          {this.agencyAndPagingGroupFilters()}
        </div>
        <Button
          label="reset"
          content={copy.global.btnReset}
          variant="btn_secondary"
          styles="reset"
          icon=""
          buttonClick={() => {
            this.setState(
              {
                agency: [],
                pagingNumber: [],
                multiAgency: false,
                multiPagingGroup: false,
                isFiltered: false,
              },
              () => {
                this.AllTypes();
                window.sessionStorage.clear();
              }
            );
          }}
        />
      </div>
    );
  };

  /**
   * Allows scrolling to be toggled on and off to support
   * the Back to Top button's functionality
   */
  setScrollable = (scrollable) => {
    this.setState({ scrollable: scrollable });
  };

  confirmDeleteAll = () => {
    // Adds deleted flag to all inactive + unlocked messages for the user in database
    this.Requests.callAPI(
      this.Requests.deleteAllInactiveUnlockedPagerMessage
    ).then((data) => {
      if (data?.status === 200) {
        setTimeout(
          function () {
            this.setState(
              {
                showDeletePrompt: false, // Hide the delete prompt
              },
              () => {
                this.handleRefresh();
              }
            );
          }.bind(this),
          1500
        );
      } else {
        let ErrorMessage =
          copy.event.deletePagingMessageAPIErrorMessage +
          ` (Error #${copy.errorCodes.deletePagingMessageAPIErrorMessage})`;
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };
  cancelDelete = () => {
    // Hide the delete prompt
    this.setState({ showDeletePrompt: false });
  };

  render() {
    const { width } = this.state;
    const breakpoint = width <= 1299;
    const closeButton = width <= 1025;

    // If user is on a device, long pressing no static information is disabled
    if (window.device) {
      document.body.style.userSelect = "none";
      document.body.style.webkitUserSelect = "none";
      document.body.style.webkitUserCallout = "none";
      document.body.style.webkitTouchCallout = "none";
    }

    if (
      this.state.allPagerMessages === null ||
      this.state.userAgenciesOption === null ||
      this.state.userPagingGroupsOption === null
    ) {
      // Loading state needs to be added when data is being retrived.
      return (
        <div className="loadingContainer">
          <Loader />
        </div>
      );
    }
    //Pager Messages API fail
    else if (this.state.allPagerMessages === false) {
      return (
        <h4>
          <span className="noMessages">
            {copy.home.messageAPIErrorMessage}
            <br />
            {copy.home.messageAPIErrorMessageFix +
              ` (Error #${copy.errorCodes.messageAPIErrorMessage})`}
            <div>
              <Button
                label="Refresh"
                content={copy.global.btnRefresh}
                variant="btn_solid"
                styles="greyBg"
                icon="icon_refresh"
                buttonClick={() => {
                  this.setState(
                    {
                      agency: [],
                      pagingNumber: [],
                      multiAgency: false,
                      multiPagingGroup: false,
                      isFiltered: false,
                    },
                    () => {
                      this.AllTypes();
                      window.sessionStorage.clear();
                    }
                  );
                }}
              />
            </div>
          </span>
        </h4>
      );
    } else {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <Popup
            isVisible={this.state.showDeletePrompt}
            titleText={copy.pagerHistory.deleteAllPopup}
            subtitleText={copy.pagerHistory.subtitle}
            button1Text={copy.pagerHistory.btnConfirm}
            button2Text={copy.global.btnNo}
            button1Action={this.confirmDeleteAll}
            button2Action={this.cancelDelete}
          />

          {/* Availability header */}
          {!this.props.pagerHistory &&
            (this.props.userAgencies.includes("CFA") ||
              this.props.userAgencies.includes("SES")) && (
              <div
                className={`availabilityContainer greyBorder ${
                  this.state.available !== null
                    ? "availabilityContainerWrap"
                    : ""
                }`}
              >
                <div className="content">
                  {this.state.available !== null ? (
                    <label className="availabilityStatus available">
                      {copy.global.available}
                      <br />
                      {this.state.available.start === "allDay" ? (
                        <span>All Day</span>
                      ) : (
                        <span>
                          {this.state.available.start} -{" "}
                          {this.state.available.end}
                        </span>
                      )}
                      <span>{this.state.available.date}</span>
                    </label>
                  ) : (
                    <p>Please update your availability</p>
                  )}

                  <Button
                    label="Edit your availability"
                    content={copy.global.btnEdit}
                    variant="btn_outline"
                    styles="btn_secondary"
                    icon=" "
                    buttonClick={this.redirectToSettings}
                  />
                </div>
              </div>
            )}

          {/* Different CSS for pager history and home page */}
          <div
            className={[
              !this.props.pagerHistory &&
              (this.props.userAgencies.includes("CFA") ||
                this.props.userAgencies.includes("SES"))
                ? styles.HomePageWrapper
                : styles.PagerHistoryWrapper,
              "bg2",
            ].join(" ")}
          >
            <div
              className={[
                styles.HomepageContainer,
                !this.props.pagerHistory &&
                (this.props.userAgencies.includes("CFA") ||
                  this.props.userAgencies.includes("SES"))
                  ? "HomePageView"
                  : "PagerHistoryView",
              ].join(" ")}
            >
              {/* Filter/Sort buttons */}
              {breakpoint ? ( // mobile filter version
                <Wrapper>
                  <div className={styles.contentHeader}>
                    <div>
                      <Button
                        label="Refresh"
                        content={copy.global.btnRefresh}
                        variant="btn_solid"
                        styles="greyBg"
                        icon="icon_refresh"
                        buttonClick={() => {
                          this.setState(
                            {
                              agency: [],
                              pagingNumber: [],
                              multiAgency: false,
                              multiPagingGroup: false,
                              isFiltered: false,
                            },
                            () => {
                              this.AllTypes();
                              window.sessionStorage.clear();
                            }
                          );
                        }}
                      />
                    </div>
                    <div className={styles.filterSection}>
                      <Button
                        label={copy.global.btnFilter}
                        content={copy.global.btnFilter}
                        variant="btn_solid"
                        styles={
                          this.state.showFilters === true ? styles.isActive : ""
                        }
                        icon="icon_filter"
                        buttonClick={this.toggleFilter}
                      />
                      <Button
                        label={copy.global.btnSort}
                        content={copy.global.btnSort}
                        variant="btn_solid"
                        styles="greyBg"
                        icon={this.state.sortIcon}
                        buttonClick={this.sortPagerMsg}
                      />
                      {this.props.pagerHistory && (
                        <div>
                          <Button
                            label={copy.global.btnDeletePH}
                            content=""
                            variant=""
                            styles={"btn_solid " + styles.delete_btn}
                            icon="icon_trashred"
                            buttonClick={() => {
                              this.setState({ showDeletePrompt: true });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      this.state.showFilters === true
                        ? styles.controls
                        : "hidden"
                    }
                  >
                    {/* Filter controls for mobile */}
                    <div className={styles.filterControls + " tileBgMob"}>
                      <p>LIMIT RESULTS TO:</p>
                      <div className={styles.holder}>
                        {/* Filter APIs failing, don't load the data into the dropdowns, error banner will come up instead*/}
                        {this.alertTypeFilters()}
                      </div>
                      <div className={styles.holder}>
                        {/* Filter APIs failing, don't load the data into the dropdowns, error banner will come up instead*/}
                        {this.agencyAndPagingGroupFilters()}
                      </div>
                      <div className={styles.filterButtons}>
                        <Button
                          label="reset"
                          content={copy.global.btnReset}
                          variant="btn_outline"
                          styles="btn_secondary"
                          icon=""
                          buttonClick={() => {
                            this.setState(
                              {
                                agency: [],
                                pagingNumber: [],
                                multiAgency: false,
                                multiPagingGroup: false,
                                isFiltered: false,
                              },
                              () => {
                                this.AllTypes();
                                window.sessionStorage.clear();
                              }
                            );
                          }}
                        />
                        {closeButton ? (
                          <Button
                            label="close"
                            content={copy.global.btnClose}
                            variant="green"
                            styles={"btn_secondary " + styles.closeBtn}
                            icon=""
                            buttonClick={this.toggleFilter}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Wrapper>
              ) : (
                // desktop filter version
                <div className={styles.contentHeader}>
                  <div>
                    <Button
                      label={copy.global.btnRefresh}
                      content={copy.global.btnRefresh}
                      variant="btn_solid"
                      styles="greyBg"
                      icon="icon_refresh"
                      buttonClick={() => {
                        this.setState(
                          {
                            agency: [],
                            pagingNumber: [],
                            multiAgency: false,
                            multiPagingGroup: false,
                            isFiltered: false,
                          },
                          () => {
                            this.AllTypes();
                            window.sessionStorage.clear();
                          }
                        );
                      }}
                    />
                  </div>
                  <div className={styles.controls}>
                    <div className={styles.filterSection + " lgrey"}>
                      <Icon
                        label={copy.global.btnFilter}
                        content={copy.global.btnFilter}
                        variant=""
                        styles=""
                        icon="icon_filter"
                      />
                      {/* Web only: show filter in the content header */}
                      {this.webFilter()}
                    </div>
                    <Button
                      label="sort"
                      content={copy.global.btnSort}
                      variant="btn_solid"
                      styles="greyBg"
                      icon={this.state.sortIcon}
                      buttonClick={this.sortPagerMsg}
                    />
                    {this.props.pagerHistory && (
                      <div className={styles.tooltip}>
                        <Button
                          label={copy.global.btnDeletePH}
                          content=""
                          variant=""
                          styles={"btn_solid " + styles.delete_btn}
                          icon="icon_trashred"
                          buttonClick={() => {
                            this.setState({ showDeletePrompt: true });
                          }}
                        />
                        <span className={styles.tooltiptext + " grey6"}>
                          {copy.global.btnDeletePH}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* Web only: show filter bar when screen is not large enough to fit filter in content header area */}
              {breakpoint &&
                !closeButton &&
                this.state.showFilters &&
                this.webFilter()}

              {/* Mobile only: show loading bar at the top  */}
              {this.state.isLoading &&
                window.cordova &&
                this.state.allPagerMessages.length > 0 && (
                  <div className={styles.loadingContainer}>
                    <Loader />
                  </div>
                )}
              {window.cordova ? (
                // Mobile only: enable pull down to refresh
                <PullToRefresh onRefresh={this.handleRefresh}>
                  <div
                    id="pagerMessageContainer"
                    // onScroll={this.triggerPagination}
                    className={
                      styles.content +
                      " " +
                      (this.state.scrollable
                        ? styles.scrollable
                        : styles.unscrollable)
                    }
                  >
                    <Wrapper>
                      <InfiniteScroll
                        dataLength={this.state.allPagerMessages.length}
                        next={this.triggerPagination}
                        hasMore={this.state.hasMoreMessagesToLoad}
                        loader={
                          <div className={styles.pagerEventLoader}>
                            <Loader />
                          </div>
                        }
                        scrollableTarget="pagerMessageContainer"
                        // endMessage={
                        //   <div className={styles.pagerEventLoader}>
                        //     <span>{copy.global.noMessages}</span>
                        //   </div>
                        // }
                        scrollThreshold="50px"
                      >
                        {this.state.allPagerMessages &&
                        this.state.allPagerMessages.length === 0 ? (
                          // If no pager messages are available, displays a message
                          <h4>
                            <span className={styles.noMessages}>
                              {copy.home.noMessages}
                            </span>
                          </h4>
                        ) : (
                          <PagerEvent
                            event={this.state.allPagerMessages}
                            buttonClick={this.redirectToEvent}
                          />
                        )}
                      </InfiniteScroll>
                    </Wrapper>
                  </div>
                </PullToRefresh>
              ) : (
                <div
                  id="pagerMessageContainer"
                  // onScroll={this.triggerPagination}
                  className={
                    styles.content +
                    " " +
                    (this.state.scrollable
                      ? styles.scrollable
                      : styles.unscrollable)
                  }
                >
                  {this.state.isLoading && !window.cordova ? (
                    <div className="loadingContainer">
                      <Loader />
                    </div>
                  ) : (
                    // Web: display pager messages without ability to pull down to refresh
                    <Wrapper>
                      <InfiniteScroll
                        dataLength={this.state.allPagerMessages.length}
                        next={this.triggerPagination}
                        hasMore={this.state.hasMoreMessagesToLoad}
                        loader={
                          <div className={styles.pagerEventLoader}>
                            <Loader />
                          </div>
                        }
                        scrollableTarget="pagerMessageContainer"
                        // endMessage={
                        //   <div className={styles.pagerEventLoader}>
                        //     <span>{copy.global.noMessages}</span>
                        //   </div>
                        // }
                        scrollThreshold="50px"
                      >
                        {this.state.allPagerMessages &&
                        this.state.allPagerMessages.length === 0 ? (
                          // If no pager messages are available, displays a message
                          <h4>
                            <span className={styles.noMessages}>
                              {copy.home.noMessages}
                            </span>
                          </h4>
                        ) : (
                          <PagerEvent
                            event={this.state.allPagerMessages}
                            buttonClick={this.redirectToEvent}
                          />
                        )}
                      </InfiniteScroll>
                    </Wrapper>
                  )}
                </div>
              )}
              {this.state.isLoading ? null : (
                <ScrollButton
                  scrollStepInPx="1000"
                  delayInMs="1"
                  display={true}
                  scrollableElement={document.getElementById(
                    "pagerMessageContainer"
                  )}
                  setScrollable={(scrollable) => {
                    this.setScrollable(scrollable);
                  }}
                />
              )}
            </div>
          </div>
        </Wrapper>
      );
    }
  }
}
