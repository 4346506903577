import React, { Component } from "react";

import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";

// Page UIs & styles
import Icon from "../../../components/UI/Icon/Icon";
import Loader from "../../../components/UI/Loading/Loading";

import styles from "../Attendance/TurnoutAttendanceListPrint.module.scss";
// Data
import copy from "../../../assets/copy/copy.json";

import { Requests } from "../../../api/IdentityServerRequests/Requests";
import Wrapper from "../../../hoc/Wrapper/Wrapper";

/**
 * AttendanceList - creates a list of attendees to an event and shows their attendance status, ETA, destination, and qualification. Also places markers of attending attendees on the map.
 */

class AttendanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapData: this.props.mapData,
      width: window.innerWidth,
      active: false,
      attending: {},
      other: {},
      unavailable: {},
      not: {},
      event: this.props.event,
      // FILTER
      showFilters: false,
      filterData: {
        destination: null,
        qualification: [],
      },
      filterLoading: false,
      attendanceList: this.props.attendanceList,

      userQualificationsOption: null,
      isEventDashboard: false,
      activeTab: "List View",
      currentTabNumber: 1,
    };
    this.Requests = new Requests();
  }

  componentDidMount() {
    const isEventDetails = matchPath(
      this.props.location.pathname,
      "/eventdetails"
    );

    if (isEventDetails) {
      this.attendanceInterval = setInterval(this.updateFilterAndSort, 30000);
    }

    const isEventDashboard = !!matchPath(
      this.props.location.pathname,
      "/dashboard"
    );

    if (isEventDashboard) {
      this.setState({
        isEventDashboard: true,
      });
    }
    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.addEventListener("resize", this.handleWindowSizeChange);

    let temporaryDictionary;

    temporaryDictionary = {};
    if (this.state.attendanceList) {
      if (this.state.attendanceList.attending !== null) {
        for (let i = 0; i < this.state.attendanceList.attending.length; i++) {
          temporaryDictionary[i] = false;
        }
        this.setState({
          attending: temporaryDictionary,
        });
      }

      temporaryDictionary = {};
      if (this.state.attendanceList.other !== null) {
        for (let i = 0; i < this.state.attendanceList.other.length; i++) {
          temporaryDictionary[i] = false;
        }
        this.setState({
          other: temporaryDictionary,
        });
      }

      temporaryDictionary = {};
      if (this.state.attendanceList.unavailable !== null) {
        for (let i = 0; i < this.state.attendanceList.unavailable.length; i++) {
          temporaryDictionary[i] = false;
        }
        this.setState({
          unavailable: temporaryDictionary,
        });
      }

      temporaryDictionary = {};

      if (this.state.attendanceList.no_Response !== null) {
        for (let i = 0; i < this.state.attendanceList.no_Response.length; i++) {
          temporaryDictionary[i] = false;
        }
        this.setState({
          not: temporaryDictionary,
        });
      }

      this.prepareFilterData();
    }
  }

  componentWillUnmount() {
    const isEventDetails = matchPath(
      this.props.location.pathname,
      "/eventdetails"
    );
    if (isEventDetails) {
      clearInterval(this.attendanceInterval);
    }

    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  // New props will be received if the user changes their attending status or changes their destination
  // Will also receive new props when a update occurs
  componentDidUpdate = (prevProps) => {
    // Updates to attendance list may need to occur whenever new props are received if
    // - No filter is on
    // - The filtered destination is the same as the destination the attendance list is for
    // - THe user has changed destinations
    if (
      this.state.filterData.destination === null ||
      (this.props.userAttendanceDestination &&
        this.props.userAttendanceDestination ===
          this.state.filterData.destination)
    ) {
      // Update attendance list if there are any differences
      if (this.props.attendanceList) {
        if (this.props.attendanceList !== prevProps.attendanceList) {
          this.setState({
            attendanceList: this.props.attendanceList,
          });
        }
      }
    } else if (
      this.props.userAttendanceDestination &&
      this.props.userAttendanceDestination !==
        prevProps.userAttendanceDestination
    ) {
      this.updateFilterAndSort(true);
    }

    if (this.props.mapData !== prevProps.mapData) {
      this.setState({
        mapData: this.props.mapData,
      });
    }
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  /** This function prepares the data for the attendance filter */
  prepareFilterData = () => {
    let body = {
      agency: [this.props.agency],
    };
    // Preparing qualifications data
    this.Requests.callAPI(this.Requests.getUserQualificationsAgency, body).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          let qualificationsOption = [];
          for (let i = 0; i < data.data.length; i++) {
            qualificationsOption.push({
              key: data.data[i].id,
              text: data.data[i].name,
              value: data.data[i].id,
            });
          }
          this.setState({ userQualificationsOption: qualificationsOption });
        } else {
          let ErrorMessage =
            copy.event.attendanceList.getQualificationsAPIErrorMessage +
            ` (Error #${copy.errorCodes.getQualificationsAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            { errorMessage: ErrorMessage, userQualificationsOption: {} },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  /**
   * setIcon - creates an icon to display either next to an attendee in the list or a Leaflet divIcon which displays on the map.
   *
   * @param  {type} statusIcon status of the attendee (attending, other, etc.)
   * @return {type}            an icon (or divIcon)
   */
  setIcon(statusIcon, index) {
    switch (statusIcon) {
      case "attending":
        return (
          <Icon
            label="attending"
            content="attending"
            variant="icon_roundTurnout"
            styles="greenBg"
            icon="icon_tick"
          />
        );
      case "other":
        return (
          <Icon
            label="other"
            content="other"
            variant="icon_roundTurnout"
            styles="yellowBg"
            icon="icon_dots"
          />
        );
      case "unavailable":
        return (
          <Icon
            label="Not attending this event"
            content="unavailable"
            variant="icon_roundTurnout"
            styles="redBg"
            icon="icon_cross"
          />
        );
      case "attendingMap":
        return (
          <Icon
            label="attending"
            content="attending"
            variant="icon_roundTurnout"
            styles="greenBg"
            icon="icon_tick"
          />
        );
      default:
        return (//
          <Icon
            label="Has not responded"
            content="Has not responded"
            variant="icon_roundTurnout"
            styles="greyBg"
            icon="icon_unknown"
          />
        );
    }
  }

  /**
   * setDestination - displays a destination icon beside the attendees
   *
   * @param  {type} destination the destination of the attendee (station or event)
   * @return {type}             an icon
   */
  setDestination(destination) {
    switch (destination) {
      case "Station":
        if (this.props.agency !== "SES") {
          return (
            <Icon
              label="travelling to station location"
              content={copy.map.labelCFAstation}
              variant="icon_outline"
              styles="blueTxt"
              icon=""
            />
          );
        } else {
          return (
            <Icon
              label="travelling to unit location"
              content={copy.map.labelSESunit}
              variant="icon_outline"
              styles="blueTxt"
              icon=""
            />
          );
        }
      case "Event":
        return (
          <Icon
            label="travelling directly to the event"
            content={copy.map.labelEvent}
            variant="icon_outline"
            styles="pinkTxt"
            icon=""
          />
        );
      default:
        return;
    }
  }

  /**
   * render - renders a list of attendees and markers for the map
   * @return {type}  those things above */
  render() {
    if (this.state.userQualificationsOption === null) {
      return (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      );
    }
    if (this.state.attendanceList) {
      let attending = false;
      // If eventUserAttendance isn't null
      if (this.state.attendanceList.attending !== null) {
        this.attendeeMapList = this.state.attendanceList.attending.map(
          (item, key) => (
            <li key={item.userInfo.userId}>
              {this.setIcon("attendingMap", key)}
              <span className={styles.person} itemProp="person">
                <span className={styles.nameTurnout} itemProp="name">
                  {item.userInfo.name}
                </span>
                {this.setDestination(item.navigateTo)}
              </span>
            </li>
          )
        );

        this.attendeeList = this.state.attendanceList.attending.map(
          (item, key) => (
            <li key={item.userInfo.userId}>
              <div>
                {this.state.isEventDashboard
                  ? this.setIcon("attendingMap", key)
                  : this.setIcon("attending")}
                <span className={styles.person} itemProp="person">
                  <span className={styles.nameTurnout} itemProp="name">
                    {item.userInfo.name}
                  </span>
                </span>
              </div>
             
            </li>
          )
        );

        attending = true;
      }
      if (!attending) {
        this.attendeeList = null;
        this.attendeeMapList = null;
      }
      // This creates the list of attendees that have clicked 'Other' as their response
      // It starts as false;

      // If eventUserAttendanceOther isn't null
      if (this.state.attendanceList.other !== null) {
        this.otherList = this.state.attendanceList.other.map((item, key) => (
          <li key={item.userInfo.userId}>
            <div>
              {this.setIcon("other")}
              <span className={styles.person} itemProp="person">
                <span className={styles.nameTurnout} itemProp="name">
                  {item.userInfo.name}
                </span>
              </span>
            </div>
          </li>
        ));
      }

      // This creates the list of members that have clicked 'Unavailable' as their response
      // It starts as false;
      // If eventUserAttendanceUnavailable isn't null
      if (this.state.attendanceList.unavailable !== null) {
        this.unavailableList = this.state.attendanceList.unavailable.map(
          (item, key) => (
            <li key={item.userInfo.userId}>
              
              <div>
                {this.setIcon("unavailable")}
                <span className={styles.person} itemProp="person">
                  <span className={styles.nameTurnout} itemProp="name">
                    {item.userInfo.name}
                  </span>
                </span>
              </div>
            </li>
          )
        );
      }

      // This creates the list of members have Not Responded yet
      // It starts as false;
      // If eventUserAttendanceNotResponded isn't null

      if (this.state.attendanceList.no_Response !== null) {
        this.not = this.state.attendanceList.no_Response.map((item, key) => (
          <li key={item.userInfo.userId}>
            {/* {() => {this.setState({not: {...this.state.not, [key]: false}})}} */}
            <div>
              {this.setIcon()}
              <span className={styles.person} itemProp="person">
                <span className={styles.nameTurnout} itemProp="name">
                  {item.userInfo.name}
                </span>
              </span>
            </div>
          </li>
        ));
      }
      const combined = [this.attendeeList, this.props.settings.showOther && this.otherList, this.props.settings.showNotAttending && this.unavailableList]

      .filter(list => list && list.length > 0)
      .flat();
      // AV does NOT show attendance list because all AV Paging Groups are NOT on the response needed list

      return (
        <Wrapper>
          <div className={styles.Attendance_section}>
          {combined && combined.length > 0 ? (
            <ul className={styles.attending}>{combined}</ul>
          ) : (
            <p className={styles.noAttendanceInfo}>No attendance information</p>
          )}
          </div>
        </Wrapper>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(AttendanceList);
