import React from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";
import styles from "./MapLegend.module.scss";
import copy from "../../assets/copy/copy.json";

/**
 * MapLegend - places a station, event, you, and reset icon on the map, and labels indicating their colour. You marker is only rendered if the position prop exists.
 *
 * @prop  onTouchStart - handles the onTouchStart function of the button
 * @prop  positions - contains all position elements of the markers (you, event, station)
 */
export default class MapLegend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      positions: this.props.positions ? this.props.positions : null,
    };
    this.createIcon = this.createIcon.bind(this);
  }

  /**
   * Required for the map to rerender and update when the props change for any of the locations (event, station, you)
   */
  componentDidUpdate = (prevProps) => {
    if (this.props.positions) {
      if (
        prevProps.positions.you !== this.props.positions.you ||
        prevProps.positions.event !== this.props.positions.event ||
        prevProps.positions.station !== this.props.positions.station
      ) {
        this.setState({
          positions: this.props.positions,
        });
      }
    }
  };

  /**
   * createIcon - creates a Leaflet Icon object which is placed on the map
   *
   * @param   url local path of the icon. Make sure passed in with "require()"
   * @return  a Leaflet Icon
   */
  createIcon(url) {
    return new L.Icon({
      iconUrl: url,
      iconRetinaUrl: url,
      iconAnchor: new L.Point(20, 40),
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: new L.Point(0, 0),
      shadowAnchor: null,
      iconSize: new L.Point(40, 40),
    });
  }

  /**
   * Renders the 3 labels and markers.
   *
   * @return  3 labels and markers
   */
  render() {
    let stationLocation = null; // initialisation
    let eventLocation = null; // initialisation

    // If currentAgencyIcon === null, then it is for a personal paging group
    let currentAgencyIcon = this.props.agency; // agency = event.agency

    if (this.state.positions) {
      stationLocation = this.state.positions.station; // station = event.paging.GeoLocation
      eventLocation = this.state.positions.event; // event = event.location
    }

    if (this.props.agency === "AV") {
      // AV (0) does not have a base location
    } else if (this.props.agency === "CFA") {
      currentAgencyIcon = "CFA"; // 1
    } else if (this.props.agency === "SES") {
      currentAgencyIcon = "SES"; // 2
    }

    let locationName = copy.map.labelCFAstation;
    // If angency is SES (2)
    if (currentAgencyIcon === "SES") {
      locationName = copy.map.labelSESunit;
    }
    return (
      <div className={styles.labelDivStyles}>
        {
          // Marker and label for station If agency is NOT AV (0), not a personal paging group event & station/unit location is not null
          currentAgencyIcon !== "AV" &&
            currentAgencyIcon !== null &&
            stationLocation && (
              <span>
                <button
                  className={
                    styles.stationStyles +
                    " " +
                    styles.labelStyles +
                    " " +
                    styles.label +
                    " btn-reset"
                  }
                  {...{
                    onClick: () => {
                      this.props.handleLabelClick(stationLocation);
                    },
                  }}
                >
                  <span>
                    <img
                      className={styles.icon}
                      src={require("../../assets/img/map/marker-label-" +
                        currentAgencyIcon +
                        ".svg").default}
                      alt={currentAgencyIcon + " map marker icon"}
                    />
                    {locationName}
                  </span>
                </button>
                <Marker
                  icon={this.createIcon(
                    require("../../assets/img/map/marker-" +
                      currentAgencyIcon +
                      ".svg").default
                  )}
                  position={this.state.positions.station}
                  zIndexOffset={200}
                ></Marker>
              </span>
            )
        }
        {
          // Marker and label for event
          eventLocation && (
            <span>
              <button
                className={
                  styles.eventStyles +
                  " " +
                  styles.labelStyles +
                  " " +
                  styles.label +
                  " btn-reset"
                }
                {...{
                  onClick: () => {
                    this.props.handleLabelClick(eventLocation);
                  },
                }}
              >
                <span>
                  <img
                    className={styles.icon}
                    src={require("../../assets/img/map/marker-label-event.svg").default}
                    alt="event map marker icon"
                  />
                  {copy.map.labelEvent}
                </span>
              </button>
              <Marker
                icon={this.createIcon(
                  require("../../assets/img/map/marker-event.svg").default
                )}
                position={this.state.positions.event}
                zIndexOffset={300}
                onclick={() => this.props.handleEventClick()}
              ></Marker>
            </span>
          )
        }
        {
          // Marker and label for You
          this.state.positions.you && window.cordova && (
            <span>
              <button
                className={
                  styles.youStyles +
                  " " +
                  styles.labelStyles +
                  " " +
                  styles.label +
                  " btn-reset"
                }
                {...{
                  onClick: () => {
                    this.props.handleLabelClick(this.state.positions.you);
                  },
                }}
              >
                <span>
                  <img
                    className={styles.icon}
                    src={require("../../assets/img/map/marker-label-You.svg").default}
                    alt="you map marker icon"
                  />
                  {copy.map.labelUser}
                </span>
              </button>
              <Marker
                icon={this.createIcon(
                  require("../../assets/img/map/marker-you.svg").default
                )}
                position={this.state.positions.you}
                zIndexOffset={100}
              ></Marker>
            </span>
          )
        }
      </div>
    );
  }
}
