import React, { Component } from "react";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../../hoc/ErrorBanner/ErrorBanner";
import styles from "./EventHeader.module.scss";
import "./EventHeader.module.scss";
import Timer from "../../Timer/Timer";

//Response controls
import Icon from "../../UI/Icon/Icon";
import { ReactComponent as Attending } from "../../../assets/img/icons/respond_attending.svg";
import { ReactComponent as Other } from "../../../assets/img/icons/respond_other.svg";
import { ReactComponent as Unavailable } from "../../../assets/img/icons/respond_unavailable.svg";
import BackArrow from "../../../assets/img/icons/settings.svg";
import Close from "../../../assets/img/icons/close.svg";
import RightArrow from "../../../assets/img/icons/rightarrow.svg";
import DownArrow from "../../../assets/img/icons/downarrow.svg";
import TimerIcon from "../../../assets/img/icons/timer.svg";
import Popup from "../../UI/Popup/Popup";

// Location tracking
import Toggle from "../../UI/Button/Toggle";
import Button from "../../UI/Button/Button";
import Loader from "../../UI/Loading/Loading";

import { AuthService } from "../../../api/Authentication/AuthService";
import { Requests } from "../../../api/IdentityServerRequests/Requests";
import copy from "../../../assets/copy/copy.json";
import Loading from "../../UI/Loading/Loading";

var classNames = require("classnames");

export default class EventHeader extends Component {
  constructor(props) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      eventID: this.props.eventId,
      errorMessage: null,
      queries: this.props.queryEvent,
      eventDetails: this.props.event,
      eventTracking:
        this.props.event &&
        this.props.event.userAttendanceCurrentUser &&
        this.props.event.userAttendanceCurrentUser
          .locationShareMyEnabledEvent !== null
          ? this.props.event.userAttendanceCurrentUser
              .locationShareMyEnabledEvent &&
            window.localStorage.getItem("trackedEvent") ===
              this.props.event.id.toString()
          : true, // Event tracking should be on by default
      eventTrackingErrorMessage: false,
      event: {
        isAttending: false,
        showResponse: false,
        showAttendingBlock: true,
        showOtherBlock: true,
        showUnavailableBlock: true,
        responseMessage: "",
        showChangeResponseBlock: false,
        showLocationControls: false,
        showLocationSelection: false,
        showCloseAlertControls: false,
        travelingToUnit: false,
        isUnitPfiValid: this.props.destinationLocations?.defaultStationUnit
          ?.GeoLocation?.properties?.PFI
          ? true
          : false,
        travelingToEvent: true,
        userAttendance: "",
        navigateTo: this.props.userDestinationPreference
          ? this.props.userDestinationPreference
          : null,
      },
      refreshingPage: false,
      width: window.innerWidth,
      userInformation: this.props.userInfo,
    };

    this.Requests = new Requests();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.event !== prevProps.event) {
      this.setState({
        eventDetails: this.props.event,
      });
    }
    if (this.calculateHeaderHeight && this.elementRef && this.props.getHeight) {
      setTimeout(
        function () {
          this.props.getHeight(this.elementRef.getBoundingClientRect().height);
        }.bind(this),
        1000
      );
      this.calculateHeaderHeight = false;
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    try {
      if (
        this.state.eventDetails &&
        this.state.eventDetails.paging.settings.attendingResponseNeeded &&
        this.state.eventDetails.userAttendanceCurrentUser !== null
      ) {
        this.getUserAttendance();
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Changes event status to either Open or Closed.
   * @param {String} status Whether the current event should become either "Open" or "Closed".
   */
  changeEventStatus = (status) => {
    const ID = this.state.eventDetails.id;

    this.Requests.callAPI(this.Requests.reactivateEvent, ID, status).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          this.props.getCurrentEventDetails();
        } else {
          let ErrorMessage =
            copy.event.restartEventAPIErrorMessage +
            ` (Error #${copy.errorCodes.restartEventAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  refreshEvent = () => {
    this.setState({ refreshingPage: true }, () => {
      setTimeout(
        function () {
          this.setState({ refreshingPage: false });
        }.bind(this),
        5000
      );
    });
    if (this.props.getCurrentEventDetails) {
      this.props.getCurrentEventDetails();
      if (this.props.getUsersCurrentLocation) {
        this.props.getUsersCurrentLocation();
      }
    }
    if (this.props.updateEventChat) {
      this.props.updateEventChat(true);
    }
  };

  canRestartEvent = () => {
    //NOTE: Have not built for Dynamic Paging Group for restarting the event
    let role;
    let pagingGroupOwned;
    let messageAgency;
    let messagePagingGroupNumber;
    // let eventType;
    //Check to see if any paging group and agency is attached to event
    if (
      this.state.eventDetails &&
      this.state.eventDetails.messages &&
      this.state.eventDetails.messages[0] &&
      this.state.eventDetails.messages[0].paging
      // &&
      // this.state.eventDetails.messages[
      //   this.state.eventDetails.messages.length - 1
      // ]
    ) {
      messageAgency = this.state.eventDetails.messages[0].agency;
      messagePagingGroupNumber =
        this.state.eventDetails.messages[0].paging.number;
      // eventType = this.state.eventDetails.messages[
      //   this.state.eventDetails.messages.length - 1
      // ].messageType;
      //Check to see if user role and paging group owner is not null or undefined
      if (
        this.props.pagingGroupsOwned !== null &&
        this.props.pagingGroupsOwned !== undefined
      ) {
        pagingGroupOwned = this.props.pagingGroupsOwned;
      }
      if (
        this.props.userRoleAgency !== null &&
        this.props.userRoleAgency !== undefined
      ) {
        role = this.props.userRoleAgency;
      }
    } else {
      return false;
    }

    //Handles when user only has one role
    if (typeof role === "string") {
      role = [role];
    }

    //Handles when eventType is Admin
    // if (eventType === "Admin") {
    //   return false;
    // }

    if (messageAgency === "DYNAMIC") {
      return false;
    }
    //Handles all roles that are allowed to restart for that agency for that event
    if (role) {
      for (let i = 0; i < role.length; i++) {
        if (role[i] === "SuperUser") {
          return true;
        } else if (
          (role[i] === "Administrator_CFA" && messageAgency === "CFA") ||
          (role[i] === "Administrator_AV" && messageAgency === "AV") ||
          (role[i] === "Administrator_SES" && messageAgency === "SES")
        ) {
          return true;
        }
      }
    }

    //Handles Paging Group Owner's restart, can only restart when the user is a PGO of that paging group event
    if (pagingGroupOwned) {
      if (pagingGroupOwned.SES) {
        for (let i = 0; i < pagingGroupOwned.SES.length; i++) {
          if (pagingGroupOwned.SES[i].key === messagePagingGroupNumber) {
            return true;
          }
        }
      }

      if (pagingGroupOwned.CFA) {
        for (let i = 0; i < pagingGroupOwned.CFA.length; i++) {
          if (pagingGroupOwned.CFA[i].key === messagePagingGroupNumber) {
            return true;
          }
        }
      }

      if (pagingGroupOwned.AV) {
        for (let i = 0; i < pagingGroupOwned.AV.length; i++) {
          if (pagingGroupOwned.AV[i].key === messagePagingGroupNumber) {
            return true;
          }
        }
      }
    }

    return false;
  };

  refCallback = (element) => {
    if (element && this.props.getHeight) {
      this.elementRef = element;
      setTimeout(
        function () {
          this.props.getHeight(element.getBoundingClientRect().height);
        }.bind(this),
        1000
      );
    }
  };

  // This function will first check if the user is attending and if they are using the mobile app
  // If they are it'll grab their location and then send the attendance
  // If not, it will send their attendance with out their location - so they won't see their ETA
  postAttendance = () => {
    // Initiate the loader for attendance list
    this.props.setAttendanceListLoading(true);
    const attendance = this.state.event.userAttendance;
    if (
      attendance === "Attending" &&
      window.cordova &&
      this.state.eventTracking === true &&
      this.props.userSettings &&
      this.props.userSettings.shareMyLocation === true &&
      this.state.eventDetails &&
      this.state.eventDetails.messages &&
      this.state.eventDetails.messages[
        this.state.eventDetails.messages.length - 1
      ] &&
      this.state.eventDetails.messages[
        this.state.eventDetails.messages.length - 1
      ].messageType !== "Admin" // Don't send get the user's location for Admin messages.
    ) {
      // Get the user's location if they are attending
      window.BackgroundGeolocation.getProviderState((state) => {
        if (state.status === 0 || state.status === 3 || state.status === 4) {
          window.BackgroundGeolocation.getCurrentPosition()
            .then((location) => {
              this.sendAttendance([
                location.coords.latitude,
                location.coords.longitude,
              ]);
            })
            .catch((error) => {
              console.log("BackgroundGeolocation error: ", error);
              // Stop the loader for attendance list if the API call fails
              this.props.setAttendanceListLoading(false);
            });
        } else {
          // Location permission not shared
          // Send the deviceLocation as null
          this.sendAttendance(null);
        }
      });
    } else {
      // Not attending or not on the mobile app
      // Send the deviceLocation as null
      this.sendAttendance(null);
    }
  };

  // This requires the user's location if they are using the mobile app in deviceLocation
  // If not, send the deviceLocation as null
  sendAttendance = (deviceLocation, resent = false) => {
    const ID = this.state.eventDetails.id;
    const navigateTo = this.state.event.navigateTo; // send as null if not known
    // attendance will always be "Attending" if resending attendance
    const attendance = resent ? "Attending" : this.state.event.userAttendance;
    let destinationLatLong = null; // send as null if not known

    if (this.props.destinationLocations) {
      // Send destination lat long only if attending
      if (attendance === "Attending") {
        if (
          navigateTo === "Event" &&
          this.props.destinationLocations.eventLatLong &&
          this.props.destinationLocations.eventLatLong.geometry
        ) {
          destinationLatLong =
            this.props.destinationLocations.eventLatLong.geometry.coordinates;
        } else if (
          navigateTo === "Station" &&
          this.props.destinationLocations.defaultStationUnit &&
          this.props.destinationLocations.defaultStationUnit.GeoLocation &&
          this.props.destinationLocations.defaultStationUnit.GeoLocation
            .properties
        ) {
          // Work out the lat long from the agency POI
          if (
            this.props.destinationLocations.defaultStationUnit.agency ===
              "CFA" ||
            this.props.destinationLocations.defaultStationUnit.agency === "SES"
          ) {
            let locations = JSON.parse(
              window.sessionStorage.getItem(
                this.props.destinationLocations.defaultStationUnit.agency +
                  "Locations"
              )
            );
            if (
              locations &&
              this.props.destinationLocations &&
              this.props.destinationLocations.defaultStationUnit &&
              this.props.destinationLocations.defaultStationUnit.GeoLocation &&
              this.props.destinationLocations.defaultStationUnit.GeoLocation
                .properties
            ) {
              let coordinates =
                locations[
                  this.props.destinationLocations.defaultStationUnit.GeoLocation
                    .properties.PFI
                ];

              if (coordinates && coordinates[1] && coordinates[0]) {
                destinationLatLong = [coordinates[1], coordinates[0]];
                this.setState({
                  event: {
                    ...this.state.event,
                    isUnitPfiValid: true,
                  },
                });
              } else {
                this.setState({
                  event: {
                    ...this.state.event,
                    isUnitPfiValid: false,
                  },
                });
              }
            }
          }
        }
      }
    }

    // Check if already attending an event
    let alreadyAttendingAnEvent = window.localStorage.getItem(
      "currentlyAttendingEventId"
    );

    // Stop tracking for that event
    if (alreadyAttendingAnEvent !== null) {
      this.props.stopTracking(alreadyAttendingAnEvent);
    }

    // Update the storage with new event's details
    window.localStorage.setItem(
      "currentlyAttendingEventDestinationCoords",
      destinationLatLong
    );
    window.localStorage.setItem("currentlyAttendingEventId", ID);

    const returnData = {
      // Only send the data if the user is attending
      attendanceStatus: attendance,
      navigateTo: attendance === "Attending" ? navigateTo : null,
      coordinatesDestination:
        attendance === "Attending" ? destinationLatLong : null,
      coordinatesUser: attendance === "Attending" ? deviceLocation : null,
    };
    this.Requests.callAPI(
      this.Requests.postUserAttendance,
      ID,
      returnData
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.calculateHeaderHeight = true;

        // In a successful response, the BE will send a Key that can be used by the mobile app to send auth requests for LastKnownLocation
        if (window.cordova && data.data) {
          window.localStorage.setItem("lastKLKey", data.data);
        }

        // The getCurrentEventDetails will update their attendance and the ETA/
        // This will also call the startTracking function so BG tracking can start
        // It will also stop tracking if they are not attending
        this.props.getCurrentEventDetails(attendance);

        // if 'Attending' status is updated from resending attendance after network error,
        // update the attendance toggle
        if (resent) {
          this.setState(
            {
              eventDetails: {
                ...this.state.eventDetails,
                userAttendanceCurrentUser: {
                  ...this.state.eventDetails.userAttendanceCurrentUser,
                  attendanceStatus: "Attending",
                },
              },
            },
            () => {
              this.getUserAttendance();
            }
          );
        }
      } else {
        let ErrorMessage =
          copy.event.attendanceList.updateAttendanceListAPIErrorMessage +
          ` (Error #${copy.errorCodes.updateAttendanceListAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.props.setAttendanceListLoading(false);
        // If API call fails, UI should revert back to original state
        this.getUserAttendance();
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );

        // retry posting attendance ('Attending' only) in 5 seconds in case error is due to network connection error
        // since we need to obtain the authentication key to start location tracking
        setTimeout(() => {
          let newAttendingEvent = window.localStorage.getItem(
            "currentlyAttendingEventId"
          );

          // check that the user is still attending the event
          if (newAttendingEvent === String(ID) && this.state.eventTracking) {
            this.sendAttendance(deviceLocation, true);
          }
        }, 10000);
      }
    });
  };

  postTravellellingTo = (location) => {
    this.setState(
      {
        event: {
          ...this.state.event,
          travellingTo: location,
        },
      },
      () => {
        this.postAttendance();
      }
    );
  };

  // Work out attendance status so that UI can be updated
  getUserAttendance = () => {
    if (
      this.state.eventDetails &&
      this.state.eventDetails.userAttendanceCurrentUser
    ) {
      let attendance =
        this.state.eventDetails.userAttendanceCurrentUser.attendanceStatus;
      if (attendance !== null) {
        if (attendance === "Attending") {
          this.setState(
            {
              event: {
                ...this.state.event,
                userAttendance: "Attending",
                isAttending: true,
                showResponse: true,
                showLocationControls: true,
                showAttendingBlock: true,
                showOtherBlock: false,
                showUnavailableBlock: false,
                responseMessage: copy.event.att.toUpperCase(),
                showChangeResponseBlock: false,
              },
            },
            () => {
              this.getNavigationDestination();
            }
          );
        } else if (attendance === "Other") {
          let otherText = "OTHER";
          try {
            if (
              this.state.eventDetails.paging.settings.attendanceStatusOtherTitle
            ) {
              otherText =
                this.state.eventDetails.paging.settings
                  .attendanceStatusOtherTitle;
            } else {
              otherText = "OTHER";
            }
          } catch {
            otherText = "OTHER";
          }
          this.setState(
            {
              event: {
                ...this.state.event,
                userAttendance: "Other",
                isAttending: false,
                showResponse: true,
                showLocationControls: false,
                showAttendingBlock: false,
                showOtherBlock: true,
                showUnavailableBlock: false,
                responseMessage: otherText,
                showChangeResponseBlock: false,
              },
            },
            () => {
              this.getNavigationDestination();
            }
          );
        } else if (attendance === "Unavailable") {
          this.setState(
            {
              event: {
                ...this.state.event,
                userAttendance: "Unavailable",
                isAttending: false,
                showResponse: true,
                showLocationControls: false,
                showAttendingBlock: false,
                showOtherBlock: false,
                showUnavailableBlock: true,
                responseMessage: copy.event.notAtt.toUpperCase(),
                showChangeResponseBlock: false,
              },
            },
            () => {
              this.getNavigationDestination();
            }
          );
        }
      }
    } else {
      // If user does not have a response, the UI should allow for attendance selection
      this.setState({
        event: {
          ...this.state.event,
          userAttendance: null,
          isAttending: false,
          showResponse: false,
          showLocationControls: false,
          showAttendingBlock: true,
          showOtherBlock: true,
          showUnavailableBlock: true,
          responseMessage: null,
          showChangeResponseBlock: false,
        },
      });
    }
  };
  // Work out user's navigation destination so that UI can be updated
  getNavigationDestination = () => {
    if (
      this.state.eventDetails &&
      this.state.eventDetails.userAttendanceCurrentUser
    ) {
      let navigateTo =
        this.state.eventDetails.userAttendanceCurrentUser.navigateTo;
      this.setState({
        event: {
          ...this.state.event,
          navigateTo: navigateTo,
          travelingToEvent: navigateTo === "Event",
          travelingToUnit: navigateTo === "Station",
        },
      });
    }
  };

  // START: Functions that use API requests
  handleAttendingClick = () => {
    this.setState(
      {
        event: {
          ...this.state.event,
          userAttendance: "Attending",
          isAttending: true,
          showResponse: true,
          showLocationControls: true,
          showAttendingBlock: true,
          showOtherBlock: false,
          showUnavailableBlock: false,
          responseMessage: copy.event.att.toUpperCase(),
          showChangeResponseBlock: false,
        },
        eventTracking:
          this.props.event &&
          this.props.event.userAttendanceCurrentUser &&
          this.props.event.userAttendanceCurrentUser.userInfo &&
          this.props.event.userAttendanceCurrentUser.userInfo
            .locationShareMyEnabled !== null
            ? this.props.event.userAttendanceCurrentUser.userInfo
                .locationShareMyEnabled
            : true,
      },
      () => {
        // set tracked event
        if (this.state.eventTracking) {
          window.localStorage.setItem(
            "trackedEvent",
            this.state.eventDetails.id
          );
        }
        this.postAttendance();
      }
    );
  };

  handleOtherClick = () => {
    let otherText = "OTHER";
    try {
      if (this.state.eventDetails.paging.settings.attendanceStatusOtherTitle) {
        otherText =
          this.state.eventDetails.paging.settings.attendanceStatusOtherTitle;
      } else {
        otherText = "OTHER";
      }
    } catch {
      otherText = "OTHER";
    }
    this.setState(
      {
        event: {
          ...this.state.event,
          userAttendance: "Other",
          isAttending: false,
          showResponse: true,
          showLocationControls: false,
          showAttendingBlock: false,
          showOtherBlock: true,
          showUnavailableBlock: false,
          responseMessage: otherText,
          showChangeResponseBlock: false,
        },
      },
      () => {
        // remove current event as tracked event to update UI toggle
        if (
          window.localStorage.getItem("trackedEvent") ===
          this.state.eventDetails.id.toString()
        ) {
          window.localStorage.removeItem("trackedEvent");
        }
        this.postAttendance();
      }
    );
  };

  handleUnavailableClick = () => {
    this.setState(
      {
        event: {
          ...this.state.event,
          userAttendance: "Unavailable",
          isAttending: false,
          showResponse: true,
          showLocationControls: false,
          showAttendingBlock: false,
          showOtherBlock: false,
          showUnavailableBlock: true,
          responseMessage: copy.event.notAtt.toUpperCase(),
          showChangeResponseBlock: false,
        },
      },
      () => {
        // remove current event as tracked event to update UI toggle
        if (
          window.localStorage.getItem("trackedEvent") ===
          this.state.eventDetails.id.toString()
        ) {
          window.localStorage.removeItem("trackedEvent");
        }
        this.postAttendance();
      }
    );
  };

  travellingToUnit = () => {
    this.setState(
      {
        event: {
          ...this.state.event,
          navigateTo: "Station",
          showLocationSelection: false,
          travelingToUnit: true,
          travelingToEvent: false,
        },
      },
      () => {
        this.postAttendance("Unit");
      }
    );
  };

  travellingToEvent = () => {
    this.setState(
      {
        event: {
          ...this.state.event,
          navigateTo: "Event",
          showLocationSelection: false,
          travelingToUnit: false,
          travelingToEvent: true,
        },
      },
      () => {
        this.postAttendance("Event");
      }
    );
  };
  // END: Functions that use API requests

  // START: Functions that use setStates

  handleChangeResponseClick = () => {
    this.setState({
      event: {
        ...this.state.event,
        showChangeResponseBlock: true,
      },
    });
  };

  handleHideChangeResponseClick = () => {
    this.setState({
      event: {
        ...this.state.event,
        showChangeResponseBlock: false,
      },
    });
  };

  handleLocationSelection = () => {
    let CurrentLocation = this.state.event.showLocationSelection;

    if (CurrentLocation) {
      this.setState({
        event: {
          ...this.state.event,
          showLocationSelection: false,
        },
      });
    } else {
      this.setState({
        event: {
          ...this.state.event,
          showLocationSelection: true,
        },
      });
    }
  };

  openTrackingControls = () => {
    this.setState({
      event: {
        ...this.state.event,
        showTrackingControls: true,
      },
    });
  };

  closeTrackingControls = () => {
    this.setState({
      event: {
        ...this.state.event,
        showTrackingControls: false,
      },
    });
  };

  toggleEventTracking = () => {
    let eventID = this.state.eventID;
    if (this.state.eventTracking !== null && eventID !== null) {
      this.setState(
        (prevState) => ({ eventTracking: !prevState.eventTracking }),
        () => {
          if (eventID !== null) {
            this.updateEventTrackinginDB(eventID, this.state.eventTracking);
          }
          if (
            !this.state.eventTracking &&
            window.localStorage.getItem("trackedEvent") ===
              this.state.eventDetails.id.toString()
          ) {
            window.localStorage.removeItem("trackedEvent");
          } else {
            window.localStorage.setItem(
              "trackedEvent",
              this.state.eventDetails.id
            );
          }
        }
      );
    }
    // let userID,
    //   eventID = null;
    // if (this.state.userInformation) {
    //   userID = this.state.userInformation.userId;
    // }
    // eventID = this.state.eventID;
    // if (this.state.eventTracking) {
    //   this.setState({
    //     eventTracking: false,
    //   });
    //   window.localStorage.setItem(
    //     userID + eventID + "shareMyLocation",
    //     "false"
    //   );
    //   this.props.stopTracking();
    // } else {
    //   this.setState({
    //     eventTracking: true,
    //   });
    //   window.localStorage.setItem(userID + eventID + "shareMyLocation", "true");
    //   if (window.cordova) {
    //     // Popup to ask for user permission
    //     window.BackgroundGeolocation.requestPermission().catch(() => {
    //       this.setState({
    //         eventTracking: false,
    //       });
    //       window.localStorage.setItem(
    //         userID + eventID + "shareMyLocation",
    //         "false"
    //       );
    //     });
    //   }
    // }
  };

  updateEventTrackinginDB = (eventID, flag) => {
    this.Requests.callAPI(
      this.Requests.setEventTracking,
      eventID, // The current event
      flag // The current flag
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        // postAttendance will send the user's current location if the user is attending and call getCurrentEventDetails
        // which will automatically start or stop tracking based on the permissions.
        this.postAttendance();
        if (flag === true && window.cordova) {
          // If the user is turning the toggle on, ask for location permission for SAS.
          // It will automatically be successfull if the user has already given permission.
          // Popup to ask for user permission
          window.BackgroundGeolocation.requestPermission().catch(() => {
            this.setState(
              {
                eventTracking: false,
              },
              () => {
                // Set it back to false in the dB
                this.updateEventTrackinginDB(false);
              }
            );
          });
        }
      } else {
        this.setState({
          eventTracking: !flag,
          eventTrackingErrorMessage: true,
        });
      }
    });
  };

  /**
   * Toggles visibility of close alert popup.
   */
  toggleCloseAlertPopup = () => {
    this.setState({
      showCloseAlertControls: !this.state.showCloseAlertControls,
    });
  };

  /**
   * Sends request to change current event status to closed and closes popup.
   */
  closeAlert = () => {
    this.changeEventStatus("Closed");
    this.toggleCloseAlertPopup();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 1023;
    const isMobileSmall = width <= 768;
    //const isTablet = width >= 768;
    if (this.state.eventDetails) {
      const {
        messages,
        messages: [
          {
            cADEventId,
            agency,
            paging: { name },
          },
        ],
        eventStatus,
        userAttendanceOtherUsersByAttendanceStatus,
      } = this.state.eventDetails;

      // Alert message type
      let AlertType;

      if (
        messages[messages.length - 1] &&
        messages[messages.length - 1].messageType !== null
      ) {
        if (messages[messages.length - 1].messageType === "NonEmergency") {
          AlertType = "Non-Emergency";
        } else {
          AlertType = messages[messages.length - 1].messageType;
        }
      }
      // Attending numbers
      let AttendingVal;
      let OtherVal;
      let UnavailableVal;
      if (userAttendanceOtherUsersByAttendanceStatus) {
        if (userAttendanceOtherUsersByAttendanceStatus.attending) {
          AttendingVal =
            userAttendanceOtherUsersByAttendanceStatus.attending.length;
        } else {
          AttendingVal = "0";
        }

        // Other numbers
        if (userAttendanceOtherUsersByAttendanceStatus.other) {
          OtherVal = userAttendanceOtherUsersByAttendanceStatus.other.length;
        } else {
          OtherVal = "0";
        }

        // Unavailable numbers
        if (userAttendanceOtherUsersByAttendanceStatus.unavailable) {
          UnavailableVal =
            userAttendanceOtherUsersByAttendanceStatus.unavailable.length;
        } else {
          UnavailableVal = "0";
        }
      }

      // Retrieving Other text
      let otherText = copy.event.other;
      if (
        this.state.eventDetails &&
        this.state.eventDetails.paging &&
        this.state.eventDetails.paging.settings &&
        this.state.eventDetails.paging.settings.attendanceStatusOtherTitle
      ) {
        const newText =
          this.state.eventDetails.paging.settings.attendanceStatusOtherTitle;
        otherText =
          newText.charAt(0).toUpperCase() + newText.slice(1).toLowerCase();
      }

      let HeaderStyling;
      let Title;
      if (eventStatus === "Closed") {
        HeaderStyling = styles.ENDED + " ENDED";
        Title = copy.event.ended;
      } else {
        if (AlertType !== null) {
          HeaderStyling = AlertType.toUpperCase();
          Title = AlertType.toUpperCase();
        }
      }

      // Response Controls
      const showAttendingBlock = this.state.event.showAttendingBlock;
      const showOtherBlock = this.state.event.showOtherBlock;
      const showUnavailableBlock = this.state.event.showUnavailableBlock;
      const showLocationControls = this.state.event.showLocationControls;
      const showChangeResponseBlock = this.state.event.showChangeResponseBlock;
      let attendingBlock;
      let attendingButton;
      let otherBlock;
      let otherButton;
      let unavailableBlock;
      let unavailableButton;
      let handleResponseModal;
      let locationControls;
      let ResponseControlsModule;

      // Location Controls
      let LocationSelection;
      let currentSelection;
      let attendingLabel = classNames(styles.label, this.props.className, {
        [styles.active]: !this.state.event.showResponse,
      });
      let responseModal = classNames(
        styles.responseModalWrapper,
        this.props.className,
        {
          [styles.open]: this.state.event.showResponse,
        }
      );
      let responseMessage = classNames(
        styles.responseMessage,
        this.props.className,
        {
          [styles.visible]: !this.state.event.showChangeResponseBlock,
        }
      );
      let locationControlsToggle = classNames(
        styles.locationControlsContainer,
        this.props.className,
        {
          [styles.open]: this.state.event.showLocationControls,
        }
      );
      let changeResponseToggle = classNames(
        styles.changeResponseBlock,
        this.props.className,
        {
          [styles.visible]: this.state.event.showChangeResponseBlock,
        }
      );
      let ArrowIcon = classNames(styles.icon, this.props.className, {
        [styles.rotated]: this.state.event.showLocationSelection,
      });
      let showLocationSelection = classNames(
        styles.locationSelection,
        " bg2 ",
        this.props.className,
        {
          [styles.visible]: this.state.event.showLocationSelection,
        },
        this.props.close ? styles.noTransition : null
      );
      let destination = "";
      if (this.state.event && this.state.event.navigateTo) {
        // if not, pick up their preference from Settings
        destination = this.state.event.navigateTo;
      } else {
        // Event
        destination = "Event";
      }
      if (showAttendingBlock) {
        attendingBlock = (
          <div className={styles.availabilityBlock}>
            <Attending
              className={styles.responseButton}
              onClick={this.handleAttendingClick}
            />
            <span className={attendingLabel}>{copy.event.att}</span>
          </div>
        );
      } else {
        attendingButton = (
          <Attending
            className={styles.responseButton}
            onClick={this.handleAttendingClick}
          />
        );
      }
      if (showOtherBlock) {
        otherBlock = (
          <div className={styles.availabilityBlock}>
            <Other
              className={styles.responseButton}
              onClick={this.handleOtherClick}
            />
            <span className={attendingLabel}>{otherText}</span>
          </div>
        );
      } else {
        otherButton = (
          <Other
            className={styles.responseButton}
            onClick={this.handleOtherClick}
          />
        );
      }
      if (showUnavailableBlock) {
        unavailableBlock = (
          <div className={styles.availabilityBlock}>
            <Unavailable
              className={styles.responseButton}
              onClick={this.handleUnavailableClick}
            />
            <span className={attendingLabel}>{copy.event.unavail}</span>
          </div>
        );
      } else {
        unavailableButton = (
          <Unavailable
            className={styles.responseButton}
            onClick={this.handleUnavailableClick}
          />
        );
      }
      if (showChangeResponseBlock) {
        handleResponseModal = (
          <div
            className={styles.closeResponse}
            onClick={this.handleHideChangeResponseClick}
          >
            <img src={Close} alt="close" />
          </div>
        );
      } else {
        handleResponseModal = (
          <div
            className={[styles.closeResponse, styles.showChangeResponse].join(
              " "
            )}
            onClick={this.handleChangeResponseClick}
          >
            <img src={BackArrow} alt="back arrow" />
            <span className={styles.label}>{copy.event.btnMod}</span>
          </div>
        );
      }
      let response = (
        <div className={responseModal}>
          <div className={styles.attendanceResponse + " tileBg"}>
            <div className={styles.response}>
              <span className={responseMessage}>
                {this.state.event.responseMessage}
              </span>
            </div>
          </div>
          <div className={changeResponseToggle}>
            {attendingButton}
            {otherButton}
            {unavailableButton}
          </div>
          {handleResponseModal}
        </div>
      );

      if (showLocationControls) {
        if (destination === "Station") {
          currentSelection = (
            <Icon
              // buttonClick={this.travellingToUnit}
              label="travelling to unit location"
              content={
                agency === "SES"
                  ? copy.map.labelSESunit
                  : copy.map.labelCFAstation
              }
              variant="icon_outline"
              styles={[styles.indicator, "blueTxt"].join(" ")}
              icon=""
            />
          );
          LocationSelection = (
            <div className={showLocationSelection}>
              <div className={styles.content}>
                <div onClick={this.travellingToEvent}>
                  <Icon
                    onClick={this.travellingToEvent}
                    label="travelling to event location"
                    content={copy.map.labelEvent}
                    variant="icon_outline"
                    styles={[styles.indicator, "pinkTxt"].join(" ")}
                    icon=""
                  />
                </div>
              </div>
            </div>
          );
        } else if (destination === "Event") {
          currentSelection = (
            <Icon
              // buttonClick={this.travellingToEvent}
              label="travelling to event location"
              content={copy.map.labelEvent}
              variant="icon_outline"
              styles={[styles.indicator + " pinkTxt"].join(" ")}
              icon=""
            />
          );
          LocationSelection = (
            <div className={showLocationSelection}>
              <div className={styles.content}>
                <div onClick={this.travellingToUnit}>
                  <Icon
                    onClick={this.travellingToUnit}
                    label="travelling to unit location"
                    content={
                      agency === "SES"
                        ? copy.map.labelSESunit
                        : copy.map.labelCFAstation
                    }
                    variant="icon_outline"
                    styles={[styles.indicator, "blueTxt"].join(" ")}
                    icon=""
                  />
                </div>
              </div>
            </div>
          );
        }

        locationControls = (
          <div className={styles.locationControlsWrapper}>
            <div className={styles.locationControls + " bg2"}>
              <div className={styles.heading}>
                <span>
                  {copy.event.destination}
                  {showLocationControls ? (
                    <img src={RightArrow} alt="right arrow"></img>
                  ) : null}
                </span>
              </div>
              <div className={styles.locationSelector}>
                <div
                  className={styles.locationWrapper}
                  onClick={this.handleLocationSelection}
                >
                  <div className={styles.locationIndicator}>
                    {currentSelection}
                  </div>
                  {LocationSelection ? (
                    <div className={ArrowIcon}>
                      <img src={DownArrow} alt="down arrow"></img>
                    </div>
                  ) : null}
                </div>
                {LocationSelection ? LocationSelection : null}
              </div>
              {/* {this.state.eventTracking && NEED TO ADD EVENT TRACKING CHECK */}
              {this.state.eventDetails &&
                this.state.eventDetails.userAttendanceCurrentUser &&
                this.state.eventDetails.userAttendanceCurrentUser.eTA !==
                  "ARRIVED" &&
                this.state.eventDetails.userAttendanceCurrentUser.eTA !== "" &&
                this.state.eventDetails.userAttendanceCurrentUser.eTA !==
                  null &&
                this.state.eventDetails.userAttendanceCurrentUser.eTA !== 0 &&
                this.state.eventTracking &&
                !(
                  this.state.event.navigateTo === "Event" &&
                  this.state.eventDetails.location === null
                ) &&
                !(
                  this.state.event.navigateTo !== "Event" &&
                  this.state.event.isUnitPfiValid === false
                ) && (
                  <div itemProp="ETA" className={styles.ETA}>
                    <span>
                      <img alt="timer icon" src={TimerIcon} />
                      {this.state.eventDetails.userAttendanceCurrentUser.eTA +
                        copy.event.minutes}
                    </span>
                  </div>
                )}
              {/* {this.state.eventTracking && NEED TO ADD EVENT TRACKING CHECK */}
              {this.state.eventDetails &&
                this.state.eventDetails.userAttendanceCurrentUser &&
                this.state.eventDetails.userAttendanceCurrentUser.eTA === 0 &&
                this.state.eventTracking &&
                !(
                  this.state.event.navigateTo === "Event" &&
                  this.state.eventDetails.location === null
                ) && (
                  <div itemProp="ETA" className={styles.ETA}>
                    <span>
                      <span className="eTAArrived">ARRIVED</span>
                    </span>
                  </div>
                )}
              {window.cordova &&
                this.state.event.isAttending &&
                this.state.eventTracking &&
                ((this.state.event.navigateTo === "Event" &&
                  this.state.eventDetails.location === null) ||
                  (this.state.event.navigateTo !== "Event" &&
                    this.state.event.isUnitPfiValid === false)) && (
                  <div itemProp="ETA" className={styles.ETA}>
                    <span>
                      <img alt="timer icon" src={TimerIcon} />
                      N/A
                    </span>
                  </div>
                )}
              {this.state.eventDetails &&
                this.state.eventDetails.userAttendanceCurrentUser &&
                this.state.eventDetails.userAttendanceCurrentUser.eTA ===
                  null &&
                window.cordova &&
                this.state.event.isAttending &&
                this.state.eventTracking &&
                this.state.eventDetails.location !== null &&
                !(
                  this.state.event.navigateTo !== "Event" &&
                  this.props.destinationLocations.defaultStationUnit
                    .GeoLocation === null
                ) && (
                  <div itemProp="ETA" className={styles.ETA}>
                    <span style={{ display: "flex", height: "23px" }}>
                      <img alt="timer icon" src={TimerIcon} />
                      <div className={styles.etaLoader}>
                        <Loading />
                      </div>
                    </span>
                  </div>
                )}
            </div>
          </div>
        );
      }

      // Sets if the response controls and attendance count are to be displayed if the information is passed in the API
      let ResponseControlsSettings = null;
      let attendanceCountSettings = null;

      // Will only display response controls if information is present in the event API
      if (
        this.state.eventDetails &&
        this.state.eventDetails.paging &&
        this.state.eventDetails.paging.settings &&
        this.state.eventDetails.paging.settings.attendingResponseNeeded
      ) {
        ResponseControlsSettings =
          this.state.eventDetails.paging.settings.attendingResponseNeeded;
      }

      // Will only display attendance count if information is present in the event API
      if (
        this.state.eventDetails &&
        this.state.eventDetails.paging &&
        this.state.eventDetails.paging.settings &&
        this.state.eventDetails.paging.settings.attendingResponseNeeded
      ) {
        attendanceCountSettings =
          this.state.eventDetails.paging.settings.attendingResponseNeeded;
      }

      if (ResponseControlsSettings) {
        ResponseControlsModule = (
          <Wrapper>
            <div className={styles.responseControls}>
              <div className={styles.controlsWrapper}>
                {attendingBlock}
                {otherBlock}
                {unavailableBlock}
              </div>
              {response}
            </div>
            <div className={locationControlsToggle}>{locationControls}</div>
          </Wrapper>
        );
      }

      const allowRestart = this.canRestartEvent();
      const RestartEventModule = (
        <div className={styles.restartModule}>
          <Button
            label="Restart event"
            buttonClick={() => {
              this.changeEventStatus("Open");
            }}
            content={copy.global.btnRestartEvent}
            variant="btn_restartEvent"
            styles=""
            icon="_refresh"
          ></Button>
        </div>
      );

      // Location tracking
      let LocationTrackingControls = classNames(
        "tileBg2 " + styles.locationTrackingControls,
        this.props.className,
        {
          [styles.visible]: this.state.event.showTrackingControls,
        }
      );
      let Text;
      if (this.state.event.LocationTracking) {
        Text = copy.event.disableTracking;
      } else {
        Text = copy.event.enableTracking;
      }
      const isAttending = this.state.event.isAttending;
      let TrackingButton;
      if (window.device && isAttending && agency !== "AV") {
        TrackingButton = (
          <div onClick={this.openTrackingControls}>
            <img
              src={
                this.state.eventTracking &&
                this.state.eventDetails &&
                this.state.eventDetails.userAttendanceCurrentUser &&
                this.state.eventDetails.userAttendanceCurrentUser.eTA !== 0
                  ? require("../../../assets/img/icons/pin.svg").default
                  : require("../../../assets/img/icons/pin_disabled.svg").default
              }
              alt="share my location for this event"
            />
          </div>
        );
      }
      let LocationTrackingModule = (
        <div className={LocationTrackingControls}>
          <div className={styles.content}>
            <div className={styles.header}>
              <span>{copy.event.trackingTitle}</span>
              <Button
                label="close event tracking controls"
                buttonClick={this.closeTrackingControls}
                content={copy.global.btnClose}
                variant="btn_outline"
                styles="greenTxt"
                icon=""
              ></Button>
            </div>
            <div className={styles.controls}>
              <span>{Text}</span>
              <Toggle
                label="Location Tracking"
                variant="toggle"
                checked={
                  this.state.eventTracking &&
                  this.state.eventDetails &&
                  this.state.eventDetails.userAttendanceCurrentUser &&
                  this.state.eventDetails.userAttendanceCurrentUser.eTA !== 0
                }
                fromAdmin
                buttonClick={this.toggleEventTracking}
              />
            </div>
            <span className={styles.disclaimer}>
              {copy.event.trackingDescription}
              {this.state.eventTrackingErrorMessage && (
                <p className="API_error_text">
                  {copy.settings.profile.shareMyLocationUpdateError +
                    ` (Error #${copy.errorCodes.shareMyLocationUpdateError})`}
                </p>
              )}
            </span>
          </div>
        </div>
      );

      if (isMobile) {
        return (
          <Wrapper>
            <ErrorBanner
              isVisible={this.state.errorMessage ? true : false}
              ErrorMessage={this.state.errorMessage}
            />
            {AlertType === "Admin" ? (
              <div
                className={[styles.eventHeaderMobile, HeaderStyling].join(" ")}
              >
                <div className={styles.alertType + " colouredTxt"}>
                  <h2>{Title ? Title : null}</h2>
                </div>
                {name && (
                  <div className={styles.detailTiles + " colouredBorder"}>
                    <div className={styles.pagerGroup + " " + styles.noTimer}>
                      <span className={styles.agency}>
                        {name ? name : null}{" "}
                      </span>
                      {/* <span className={styles.group}>{group}</span> */}
                    </div>
                  </div>
                )}
                {allowRestart &&
                  eventStatus !== "Closed" &&
                  (isMobileSmall ? (
                    <div className={styles.closeButtonWrapper + " closebtn"}>
                      <Button
                        label="close"
                        buttonClick={this.toggleCloseAlertPopup}
                        variant="btn_closeEvent mobile"
                      ></Button>
                    </div>
                  ) : (
                    <div className={styles.closeButtonWrapper + " closebtn"}>
                      <Button
                        label="Close"
                        buttonClick={this.toggleCloseAlertPopup}
                        content={copy.event.btnCloseAlert}
                        variant="btn_closeEvent"
                        styles=""
                        icon={"_close"}
                      ></Button>
                    </div>
                  ))}
              </div>
            ) : (
              <div
                className={[styles.eventHeaderMobile, HeaderStyling].join(" ")}
              >
                <div className={styles.eventID + " colouredTxt"}>
                  {cADEventId && (
                    <h3>
                      {copy.event.eventID}
                      {cADEventId}
                    </h3>
                  )}
                  {TrackingButton}
                  {LocationTrackingModule}
                </div>
                <div className={styles.alertType + " colouredTxt"}>
                  <h2>{Title ? Title : null}</h2>
                </div>
                {name && (
                  <div className={styles.detailTiles + " colouredBorder"}>
                    <div className={styles.timer + " colouredBorder"}>
                      {this.props.event.messages[0] && (
                        <Timer
                          initialTimestamp={this.state.eventDetails.messages}
                          eventStatus={this.state.eventDetails.eventStatus}
                        />
                      )}
                    </div>
                    <span className={styles.divider}></span>
                    <div className={styles.pagerGroup}>
                      <span className={styles.agency}>
                        {name ? name : null}
                      </span>
                      {/* <span className={styles.group}>{group}</span> */}
                    </div>
                  </div>
                )}
                {attendanceCountSettings && (
                  <div className={styles.attendanceCount}>
                    <div className={styles.attendingBlock}>
                      <span className={styles.count}>{AttendingVal}</span>
                      <span> {copy.event.att.toUpperCase()}</span>
                    </div>
                    <Wrapper>
                      <div className={styles.attendingBlock}>
                        <span className={styles.count}>{OtherVal}</span>
                        <span> {otherText.toUpperCase()}</span>
                      </div>
                      <div className={styles.attendingBlock}>
                        <span className={styles.count}>{UnavailableVal}</span>
                        <span> {copy.event.unavail.toUpperCase()}</span>
                      </div>
                    </Wrapper>
                  </div>
                )}
                {isMobileSmall ? (
                  <div className={styles.refreshButtonWrapper + " refreshbtn"}>
                    <Button
                      label="refresh"
                      buttonClick={this.refreshEvent}
                      content={this.state.refreshingPage && <Loader />}
                      variant="btn_refreshEvent mobile"
                      styles={this.state.refreshingPage && "_refresh"}
                    ></Button>
                  </div>
                ) : (
                  <div className={styles.refreshButtonWrapper + " refreshbtn"}>
                    <Button
                      label="Refresh"
                      buttonClick={this.refreshEvent}
                      content={
                        this.state.refreshingPage ? (
                          <Loader />
                        ) : (
                          copy.global.btnRefreshEvent
                        )
                      }
                      variant="btn_refreshEvent"
                      styles=""
                      icon={this.state.refreshingPage ? null : "_refresh"}
                    ></Button>
                  </div>
                )}
                {allowRestart &&
                  eventStatus !== "Closed" &&
                  (isMobileSmall ? (
                    <div className={styles.closeButtonWrapper + " closebtn"}>
                      <Button
                        label="close"
                        buttonClick={this.toggleCloseAlertPopup}
                        variant="btn_closeEvent mobile"
                      ></Button>
                    </div>
                  ) : (
                    <div className={styles.closeButtonWrapper + " closebtn"}>
                      <Button
                        label="Close"
                        buttonClick={this.toggleCloseAlertPopup}
                        content={copy.event.btnCloseAlert}
                        variant="btn_closeEvent"
                        styles=""
                        icon={"_close"}
                      ></Button>
                    </div>
                  ))}
              </div>
              // </div>
            )}
            {allowRestart
              ? eventStatus !== "Closed"
                ? ResponseControlsModule
                : RestartEventModule
              : eventStatus !== "Closed"
              ? ResponseControlsModule
              : null}
            <Popup
              isVisible={this.state.showCloseAlertControls}
              titleText={copy.event.closeAlertTitle}
              button1Text={copy.event.btnCloseAlert}
              button2Text={copy.global.btnCancel}
              button1Action={this.closeAlert}
              button2Action={this.toggleCloseAlertPopup}
            />
          </Wrapper>
        );
      } else {
        return (
          <div id="eventHeaderWrapper" ref={this.refCallback}>
            <ErrorBanner
              isVisible={this.state.errorMessage ? true : false}
              ErrorMessage={this.state.errorMessage}
            />
            {AlertType === "Admin" ? (
              <div
                className={[styles.eventHeaderDesktop, HeaderStyling].join(" ")}
              >
                <div className={styles.headerWrapper + " " + styles.admin}>
                  <div className={styles.alertWrapper}>
                    <div className={styles.alertType + " colouredTxt"}>
                      <h2>{AlertType ? AlertType : null}</h2>
                    </div>
                  </div>
                  {allowRestart && eventStatus !== "Closed" && (
                    <div className={styles.closeButtonWrapper}>
                      <Button
                        label="Close"
                        buttonClick={this.toggleCloseAlertPopup}
                        content={copy.event.btnCloseAlert}
                        variant="btn_closeEvent"
                        styles=""
                        icon={"_close"}
                      ></Button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={[styles.eventHeaderDesktop, HeaderStyling].join(" ")}
              >
                <div className={styles.eventID + " colouredTxt"}>
                  {cADEventId && (
                    <h3>
                      {copy.event.eventID}
                      {cADEventId}
                    </h3>
                  )}
                </div>
                <div className={styles.headerWrapper}>
                  <div className={styles.refreshButtonWrapper}>
                    <Button
                      label="Refresh"
                      buttonClick={this.refreshEvent}
                      content={
                        this.state.refreshingPage ? (
                          <Loader />
                        ) : (
                          copy.global.btnRefreshEvent
                        )
                      }
                      variant="btn_refreshEvent"
                      styles=""
                      icon={this.state.refreshingPage ? null : "_refresh"}
                    ></Button>
                  </div>
                  <div className={styles.timer + " colouredBorder"}>
                    {this.state.refreshingPage ? (
                      <Loader />
                    ) : (
                      this.state.eventDetails.messages[0] && (
                        <Timer
                          initialTimestamp={this.state.eventDetails.messages}
                          eventStatus={this.state.eventDetails.eventStatus}
                        />
                      )
                    )}
                  </div>
                  <div className={styles.alertWrapper}>
                    <div className={styles.alertType + " colouredTxt"}>
                      <h2>{Title ? Title : null}</h2>
                    </div>
                  </div>
                  {attendanceCountSettings && (
                    <div className={styles.attendanceCount}>
                      <div className={styles.attendingBlock}>
                        <span className={styles.count}>{AttendingVal}</span>
                        <span> {copy.event.att.toUpperCase()}</span>
                      </div>
                      <Wrapper>
                        <div className={styles.attendingBlock}>
                          <span className={styles.count}>{OtherVal}</span>
                          <span> {otherText.toUpperCase()}</span>
                        </div>
                        <div className={styles.attendingBlock}>
                          <span className={styles.count}>{UnavailableVal}</span>
                          <span> {copy.event.unavail.toUpperCase()}</span>
                        </div>
                      </Wrapper>
                    </div>
                  )}
                  {allowRestart && eventStatus !== "Closed" && (
                    <div className={styles.closeButtonWrapper}>
                      <Button
                        label="Close"
                        buttonClick={this.toggleCloseAlertPopup}
                        content={copy.event.btnCloseAlert}
                        variant="btn_closeEvent"
                        styles=""
                        icon={"_close"}
                      ></Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {allowRestart
              ? eventStatus !== "Closed"
                ? ResponseControlsModule
                : RestartEventModule
              : eventStatus !== "Closed"
              ? ResponseControlsModule
              : null}
            <Popup
              isVisible={this.state.showCloseAlertControls}
              titleText={copy.event.closeAlertTitle}
              button1Text={copy.event.btnCloseAlert}
              button2Text={copy.global.btnCancel}
              button1Action={this.closeAlert}
              button2Action={this.toggleCloseAlertPopup}
            />
          </div>
        );
      }
    } else if (this.state.queries) {
      const {
        cADEventId,
        eventStatus,
        messageInputTimeStamp,
        messageType,
        pagingName,
      } = this.state.queries;

      // Alert message type
      let AlertType;

      if (messageType !== null) {
        if (messageType === "NonEmergency") {
          AlertType = "Non-Emergency";
        } else {
          AlertType = messageType;
        }
      }

      let HeaderStyling;
      let Title;
      if (eventStatus === "Closed") {
        HeaderStyling = "ENDED";
        Title = copy.event.ended;
      } else {
        if (AlertType) {
          HeaderStyling = AlertType.toUpperCase();
          Title = AlertType.toUpperCase();
        }
      }

      let attendanceCountSettings = this.state.eventDetails
        ? this.state.eventDetails.paging.settings.attendingResponseNeeded
        : null;

      if (isMobile) {
        const allowRestart = this.canRestartEvent();
        return (
          <Wrapper>
            <ErrorBanner
              isVisible={this.state.errorMessage ? true : false}
              ErrorMessage={this.state.errorMessage}
            />
            {AlertType === "Admin" ? (
              <div
                className={[styles.eventHeaderMobile, HeaderStyling].join(" ")}
              >
                <div className={styles.alertType + " colouredTxt"}>
                  <h2>{Title ? Title : null}</h2>
                </div>
                {pagingName && (
                  <div className={styles.detailTiles + " colouredBorder"}>
                    <div className={styles.pagerGroup + " " + styles.noTimer}>
                      <span className={styles.agency}>
                        {pagingName ? pagingName : null}{" "}
                      </span>
                      {/* <span className={styles.group}>{group}</span> */}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                className={[styles.eventHeaderMobile, HeaderStyling].join(" ")}
              >
                <div className={styles.eventID + " colouredTxt"}>
                  {cADEventId && (
                    <h3>
                      {copy.event.eventID}
                      {cADEventId}
                    </h3>
                  )}
                  {/* {TrackingButton}
                  {LocationTrackingModule} */}
                </div>
                <div className={styles.alertType + " colouredTxt"}>
                  <h2>{Title ? Title : null}</h2>
                </div>
                {pagingName && (
                  <div className={styles.detailTiles + " colouredBorder"}>
                    <div className={styles.timer + " colouredBorder"}>
                      {messageInputTimeStamp && (
                        <Timer
                          initialTimestamp={messageInputTimeStamp}
                          eventStatus={eventStatus}
                        />
                      )}
                    </div>
                    <span className={styles.divider}></span>
                    <div className={styles.pagerGroup}>
                      <span className={styles.agency}>
                        {pagingName ? pagingName : null}
                      </span>
                      {/* <span className={styles.group}>{group}</span> */}
                    </div>
                  </div>
                )}
                {attendanceCountSettings && (
                  <div className={styles.attendanceCount}>
                    <div className={styles.attendingBlock}>
                      <span className={styles.count}>-</span>
                      <span> {copy.event.att.toUpperCase()}</span>
                    </div>
                    <Wrapper>
                      <div className={styles.attendingBlock}>
                        <span className={styles.count}>-</span>
                        <span> {copy.event.other.toUpperCase()}</span>
                      </div>
                      <div className={styles.attendingBlock}>
                        <span className={styles.count}>-</span>
                        <span> {copy.event.unavail.toUpperCase()}</span>
                      </div>
                    </Wrapper>
                  </div>
                )}
                {isMobileSmall ? (
                  <div className={styles.refreshButtonWrapper + " refreshbtn"}>
                    <Button
                      label="refresh"
                      buttonClick={this.refreshEvent}
                      content={this.state.refreshingPage && <Loader />}
                      variant="btn_refreshEvent mobile"
                      styles={this.state.refreshingPage && "_refresh"}
                    ></Button>
                  </div>
                ) : (
                  <div className={styles.refreshButtonWrapper + " refreshbtn"}>
                    <Button
                      label="Refresh"
                      buttonClick={this.refreshEvent}
                      content={
                        this.state.refreshingPage ? (
                          <Loader />
                        ) : (
                          copy.global.btnRefreshEvent
                        )
                      }
                      variant="btn_refreshEvent"
                      styles=""
                      icon={this.state.refreshingPage ? null : "_refresh"}
                    ></Button>
                  </div>
                )}
                {allowRestart &&
                  eventStatus !== "Closed" &&
                  (isMobileSmall ? (
                    <div className={styles.closeButtonWrapper + " closebtn"}>
                      <Button
                        label="close"
                        buttonClick={this.toggleCloseAlertPopup}
                        variant="btn_closeEvent mobile"
                      ></Button>
                    </div>
                  ) : (
                    <div className={styles.closeButtonWrapper + " closebtn"}>
                      <Button
                        label="Close"
                        buttonClick={this.toggleCloseAlertPopup}
                        content={copy.event.btnCloseAlert}
                        variant="btn_closeEvent"
                        styles=""
                        icon={"_close"}
                      ></Button>
                    </div>
                  ))}
              </div>
              // </div>
            )}
          </Wrapper>
        );
      } else {
        const allowRestart = this.canRestartEvent();
        return (
          <Wrapper>
            <ErrorBanner
              isVisible={this.state.errorMessage ? true : false}
              ErrorMessage={this.state.errorMessage}
            />
            {AlertType === "Admin" ? (
              <div
                className={[styles.eventHeaderDesktop, HeaderStyling].join(" ")}
              >
                <div className={styles.headerWrapper + " " + styles.admin}>
                  <div className={styles.alertWrapper}>
                    <div className={styles.alertType + " colouredTxt"}>
                      <h2>{AlertType ? AlertType : null}</h2>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={[styles.eventHeaderDesktop, HeaderStyling].join(" ")}
              >
                <div className={styles.eventID + " colouredTxt"}>
                  {cADEventId && (
                    <h3>
                      {copy.event.eventID}
                      {cADEventId}
                    </h3>
                  )}
                </div>
                <div className={styles.headerWrapper}>
                  <div className={styles.refreshButtonWrapper}>
                    <Button
                      label="Refresh"
                      buttonClick={this.refreshEvent}
                      content={
                        this.state.refreshingPage ? (
                          <Loader />
                        ) : (
                          copy.global.btnRefreshEvent
                        )
                      }
                      variant="btn_refreshEvent"
                      styles=""
                      icon={this.state.refreshingPage ? null : "_refresh"}
                    ></Button>
                  </div>
                  <div className={styles.timer + " colouredBorder"}>
                    {messageInputTimeStamp && (
                      <Timer
                        initialTimestamp={messageInputTimeStamp}
                        eventStatus={eventStatus}
                      />
                    )}
                  </div>
                  <div className={styles.alertWrapper}>
                    <div className={styles.alertType + " colouredTxt"}>
                      <h2>{Title ? Title : null}</h2>
                    </div>
                  </div>
                  {attendanceCountSettings && (
                    <div className={styles.attendanceCount}>
                      <div className={styles.attendingBlock}>
                        <span className={styles.count}>-</span>
                        <span> {copy.event.att.toUpperCase()}</span>
                      </div>
                      <Wrapper>
                        <div className={styles.attendingBlock}>
                          <span className={styles.count}>-</span>
                          <span> {copy.event.other.toUpperCase()}</span>
                        </div>
                        <div className={styles.attendingBlock}>
                          <span className={styles.count}>-</span>
                          <span> {copy.event.unavail.toUpperCase()}</span>
                        </div>
                      </Wrapper>
                    </div>
                  )}
                  {allowRestart && eventStatus !== "Closed" && (
                    <div className={styles.closeButtonWrapper}>
                      <Button
                        label="Close"
                        buttonClick={this.toggleCloseAlertPopup}
                        content={copy.event.btnCloseAlert}
                        variant="btn_closeEvent"
                        styles=""
                        icon={"_close"}
                      ></Button>
                    </div>
                  )}
                </div>
              </div>
              // </div>
            )}
          </Wrapper>
        );
      }
    } else {
      return (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      );
    }
  }
}
