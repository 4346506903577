import React, { Component } from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import Icon from "../UI/Icon/Icon";
import styles from "./Chats.module.scss";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";
import copy from "../../assets/copy/copy.json";
import { findPhoneNumbersInText } from "libphonenumber-js";

const reactStringReplace = require("react-string-replace");
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserId: this.props.UserId,
      chatData: this.props.ChatData,
      width: window.innerWidth,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.UserId !== prevState.UserId) {
      return {
        UserId: nextProps.UserId,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ChatData !== this.props.ChatData) {
      this.setState({
        chatData: this.props.ChatData,
      });
    }
    if (this.props.UserId !== prevProps.UserId) {
      this.setState({
        UserId: this.props.UserId,
      });
    }
  }
  handleClick = () => {
    if (this.props.buttonClick) {
      this.props.buttonClick(this.props.paramVal);
    }
  };

  openMenuOption = () => {
    return (
      <div className="openMenuOption">
        <Icon
          label="Menu link"
          content=""
          variant=""
          styles=""
          icon="icon_menulink"
          buttonClick={this.openMenuOption.bind(this)}
        />
      </div>
    );
  };

  // converts date/time so Safari reads it properly
  convertDate = (date) => {
    if (date) {
      return date
        .replace(/-/g, "/")
        .replace("T", " ")
        .replace(/\..*|\+.*/, "");
    } else {
      return null;
    }
  };
  showPhoneNumberUrls = (originalMessage) => {
    let newMessage = originalMessage;
    // Use library to find all instances of a phone number
    let phoneNumbers = findPhoneNumbersInText(originalMessage, "AU");
    // Go through each instance of the phone number found from the plugin
    phoneNumbers.forEach((phoneNumber, index) => {
      // Keep the actual text string
      let text = originalMessage.slice(
        phoneNumber.startsAt,
        phoneNumber.endsAt
      );
      // Extract the correct phone number
      let number = "tel:" + phoneNumber.number.number;
      // Create a new message with a link for the phone number
      newMessage = reactStringReplace(newMessage, text, () => {
        return (
          <a
            className="hyperlink-theme"
            href={number}
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={phoneNumber.number.number + index}
          >
            {" "}
            {text}
          </a>
        );
      });
    });

    // HANDLE LOCAL NUMBERS WITHOUT AREA CODES
    // Formats handled "XXXXXXXX", "XXXX-XXXX", "XXXX XXXX"
    let regexPhone = /(?:\D|^)(\d{4}[ -]{0,1}\d{4})(?=\D|$)/g;
    // Use replace method to find all instances of the captured group and change the text into telephone links
    originalMessage.replace(regexPhone, (m, p1) => {
      newMessage = reactStringReplace(newMessage, p1, (match) => {
        return (
          <a
            key={m}
            className="hyperlink-theme"
            href={"tel:" + match}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            {match}
          </a>
        );
      });
    });

    // Detect links (urls, emails) from the text and convert it into a hyperlink
    let regex =
      /[(http(s)?):\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gim; // eslint-disable-line
    originalMessage.replace(regex, (url) => {
      let index = 0;
      if (url.includes("@")) {
        //for emails
        newMessage = reactStringReplace(newMessage, url, () => {
          return (
            <a
              key={url + index++}
              className="hyperlink-theme"
              href={"mailto:" + url}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {url}
            </a>
          );
        });
      } else {
        //for links
        if (
          url.startsWith("https") ||
          url.startsWith("http") ||
          url.startsWith("HTTP") ||
          url.startsWith("HTTPS")
        ) {
          newMessage = reactStringReplace(newMessage, url, () => {
            if (window.cordova) {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme"
                  target="_system"
                  rel="noopener noreferrer"
                  href={url}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.cordova.InAppBrowser.open(url, "_system");
                  }}
                >
                  {url}
                </a>
              );
            } else {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {url}
                </a>
              );
            }
          });
        } else if (url.startsWith("www") || url.startsWith("WWW")) {
          newMessage = reactStringReplace(newMessage, url, () => {
            if (window.cordova) {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme"
                  target="_system"
                  rel="noopener noreferrer"
                  href={"https://" + url}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.cordova.InAppBrowser.open(
                      "https://" + url,
                      "_system"
                    );
                  }}
                >
                  {url}
                </a>
              );
            } else {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"https://" + url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {url}
                </a>
              );
            }
          });
        }
      }
    });

    //Emergency Markers links
    let emergencyMarkerRegex = /\b[A-Z]{3}[0-9]{3}\b/gm; // eslint-disable-line
    originalMessage.replace(emergencyMarkerRegex, (url) => {
      newMessage = reactStringReplace(newMessage, url, () => {
        if (window.cordova) {
          return (
            <a
              key={url}
              className="hyperlink-theme"
              target="_system"
              rel="noopener noreferrer"
              href={
                "https://www.iconyx.com/meet-me-at-the-marker/?marker=" + url
              }
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.cordova.InAppBrowser.open(
                  "https://www.iconyx.com/meet-me-at-the-marker/?marker=" + url,
                  "_system"
                );
              }}
            >
              {url}
            </a>
          );
        } else {
          return (
            <a
              key={url}
              className="hyperlink-theme"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://www.iconyx.com/meet-me-at-the-marker/?marker=" + url
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {url}
            </a>
          );
        }
      });
    });
    return newMessage;
  };
  render() {
    const { width } = this.state;
    const isMobile = width <= 1023;
    const isSASChatView = !!matchPath(this.props.location.pathname, "/chat");
    const {
      chatType,
      title,
      threadOwner,
      // threadOwner: { name },
      subject,
      messageFirstPayload,
      messageLastPayload,
      dateTimeCreated,
      isActive,
      threadReadStatus,
      messsageCount,
      recipientName,
    } = this.state.chatData;

    let threadOwnerInitials;
    if (threadOwner !== null) {
      if (threadOwner.name) {
        threadOwnerInitials = threadOwner.name.match(/\b(\w)/g);
      }
    }

    let recipientInitials;
    if (recipientName) {
      recipientInitials = recipientName[0].match(/\b(\w)/g);
    }

    let recipients;
    if (recipientName) {
      if (recipientName.length > 1) {
        recipients =
          recipientName[0] + " and " + recipientName.length + " more";
      } else {
        recipients = recipientName[0];
      }
    }

    const timeCreated = new Date(
      this.convertDate(dateTimeCreated)
    ).toLocaleTimeString("en-GB");
    const dateCreated = new Date(this.convertDate(dateTimeCreated))
      .toLocaleDateString("en-GB")
      .replace(/\//g, "-");
    if (isMobile) {
      return (
        <div
          onClick={this.handleClick}
          id="ChatMessage"
          className={
            styles.chatMessage +
            " " +
            (isSASChatView ? "chatMessageHoverState" : null) +
            " " +
            (!isActive && styles.inactive) +
            " chatMessageDefaultState"
          }
        >
          <table className={styles.chatMessageTableLayout}>
          <tr>
            <td rowspan="2" className={styles.iconWrapperCell}>
              <div className={styles.chatIconWrapper}>
              <span className={styles.closedLabel + " " +styles.statusCellSize}>{!isActive ? 'CLOSED' : ''}</span>
              {chatType === "Event" ? (
                <Icon
                  label="Chat bubble"
                  content={
                    title && title === "Emergency"
                      ? "E"
                      : title && title === "NonEmergency" && "NE"
                  }
                  variant={threadReadStatus ? "" : "unreadMessageIndicator"}
                  styles={[
                    "event ",
                    title && title === "Emergency"
                      ? "emergency"
                      : title && title === "NonEmergency" && "nonEmergency",
                  ].join(" ")}
                  icon={"icon_chat"}
                />
              ) : (
                <Icon
                  label="Chat bubble"
                  content={
                    chatType === "AdhocGroup" || chatType === "Group"
                      ? ""
                      : chatType === "OneOnOne"
                      ? recipientInitials
                      : threadOwnerInitials
                  }
                  variant={threadReadStatus ? "" : "unreadMessageIndicator"}
                  styles=""
                  icon={
                    chatType === "AdhocGroup" || chatType === "Group"
                      ? "icon_chat_group"
                      : "icon_chat"
                  }
                />
              )}
              </div>
            </td>
            <td>
              <div className={styles.chatMessageHeader}>
              <div className={styles.chatMessageTimeStamp}>
                <span className="time">{timeCreated}</span>
                <span className="date">{dateCreated}</span>
              </div>
              {(chatType !== "OneOnOne" && messsageCount !== null) ||
                (messsageCount !== 1 && (
                  <div className={styles.messageCount}>
                    <span className={styles.count}>
                      {chatType === "Event" ? messsageCount : messsageCount - 1}
                    </span>
                    <span>{messsageCount === 2 ? "Reply" : "Replies"}</span>
                  </div>
                ))}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.messageHolder}>
              {chatType === "Event" && (
                <Wrapper>
                  <span
                    className={
                      "chatMessageName " +
                      [
                        styles.name,
                        title && title === "Emergency"
                          ? styles.emergency
                          : title &&
                            title === "NonEmergency" &&
                            styles.nonEmergency,
                      ].join(" ")
                    }
                  >
                    {title && title}
                  </span>
                  <span className={styles.pagingGroup}>
                    {subject && subject.replace(/_/g, " ")}
                  </span>

                  <span className={styles.message + " smartlook-hide "}>
                    {messageFirstPayload
                      ? this.showPhoneNumberUrls(messageFirstPayload)
                      : messageFirstPayload}
                  </span>
                </Wrapper>
              )}
              {chatType === "AdhocGroup" && (
                <Wrapper>
                  <span className={"chatMessageName " + styles.name}>
                    {threadOwner && threadOwner.name}
                  </span>
                  <span className={styles.pagingGroup}>{recipients}</span>
                  <span className={styles.subjectLine}>
                    <span className={styles.RE}>{copy.sasChat.replyShort}</span>
                    {subject}
                  </span>
                  <span className={styles.message + " smartlook-hide "}>
                    {messageFirstPayload
                      ? this.showPhoneNumberUrls(messageFirstPayload)
                      : messageFirstPayload}
                  </span>
                </Wrapper>
              )}
              {chatType === "Group" && (
                <Wrapper>
                  <span className={"chatMessageName " + styles.name}>
                    {threadOwner && threadOwner.name}
                  </span>
                  <span className={styles.pagingGroup}>
                    {title && title.replace(/_/g, " ")}
                  </span>
                  <span className={styles.subjectLine}>
                    <span className={styles.RE}>{copy.sasChat.replyShort}</span>
                    {subject}
                  </span>
                  <span className={styles.message + " smartlook-hide "}>
                    {messageFirstPayload
                      ? this.showPhoneNumberUrls(messageFirstPayload)
                      : messageFirstPayload}
                  </span>
                </Wrapper>
              )}
              {chatType === "OneOnOne" && (
                <Wrapper>
                  <span className={"chatMessageName " + styles.name}>
                    {threadOwner ? (
                      <Wrapper>
                        {this.state.UserId === threadOwner.userId ? (
                          <Wrapper>{recipientName && recipientName[0]}</Wrapper>
                        ) : (
                          threadOwner.name
                        )}
                      </Wrapper>
                    ) : (
                      <Wrapper>{recipientName && recipientName[0]}</Wrapper>
                    )}
                  </span>
                  <span className={styles.subjectLine}>
                    <span className={styles.RE}>{copy.sasChat.replyShort}</span>
                    {subject}
                  </span>
                  <span className={styles.message + " smartlook-hide "}>
                    {messageLastPayload
                      ? this.showPhoneNumberUrls(messageLastPayload)
                      : messageLastPayload}
                  </span>
                </Wrapper>
              )}
              </div>
            </td>
          </tr>
        </table>
        </div>
      );
    } else {
      return (
        <div
          onClick={this.handleClick}
          id="ChatMessage"
          className={
            styles.chatMessage +
            " " +
            (isSASChatView ? "chatMessageHoverState" : null) +
            " " +
            this.props.className +
            " " +
            (!isActive && styles.inactive) +
            " chatMessageDefaultState"
          }
        >
          <table className={styles.chatMessageTableLayout}>
          <tr>
            <td rowspan="2" className={styles.iconWrapperCell}>
              <div className={styles.chatIconWrapper}>
              <span className={styles.closedLabel + " " +styles.statusCellSize}>{!isActive ? 'CLOSED' : ''}</span>
              {chatType === "Event" ? (
                <Icon
                  label="Chat bubble"
                  content={
                    title && title === "Emergency"
                      ? "E"
                      : title && title === "NonEmergency" && "NE"
                  }
                  // content={
                  //   chatType === "AdhocGroup" || chatType === "Group"
                  //     ? ""
                  //     : threadOwnerInitials
                  // }
                  variant={threadReadStatus ? "" : "unreadMessageIndicator"}
                  styles={[
                    "event ",
                    title && title === "Emergency"
                      ? "emergency"
                      : title && title === "NonEmergency" && "nonEmergency",
                  ].join(" ")}
                  icon={"icon_chat"}
                />
              ) : (
                <Icon
                  label="Chat bubble"
                  content={
                    chatType === "AdhocGroup" || chatType === "Group"
                      ? ""
                      : chatType === "OneOnOne"
                      ? recipientInitials
                      : threadOwnerInitials
                  }
                  variant={threadReadStatus ? "" : "unreadMessageIndicator"}
                  styles=""
                  icon={
                    chatType === "AdhocGroup" || chatType === "Group"
                      ? "icon_chat_group"
                      : "icon_chat"
                  }
                />
              )}
              </div>
            </td>
            <td>
              <div className={styles.chatMessageHeader}>
              <div className={styles.chatMessageTimeStamp}>
                <span className="time">{timeCreated}</span>
                <span className="date">{dateCreated}</span>
              </div>
              {chatType !== "OneOnOne" && messsageCount !== null && (
                <Wrapper>
                  {messsageCount > 1 && (
                    <div className={styles.messageCount}>
                      <span className={styles.count}>
                        {chatType === "Event" ? messsageCount : messsageCount - 1}
                      </span>
                      <span>{messsageCount === 2 ? "Reply" : "Replies"}</span>
                    </div>
                  )}
                </Wrapper>
              )}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.messageHolder}>
            {chatType === "Event" && (
              <Wrapper>
                <span
                  className={
                    "chatMessageName " +
                    [
                      styles.name,
                      title && title === "Emergency"
                        ? styles.emergency
                        : title &&
                          title === "NonEmergency" &&
                          styles.nonEmergency,
                    ].join(" ")
                  }
                >
                  {title && (
                    <Wrapper>
                      {title === "Emergency"
                        ? "EMERGNCY"
                        : title === "NonEmergency"
                        ? "NON EMERGENCY"
                        : title}
                    </Wrapper>
                  )}
                </span>
                <span className={styles.pagingGroup}>
                  {subject && subject.replace(/_/g, " ")}
                </span>
                <span className={styles.message + " smartlookMask "}>
                  {messageFirstPayload
                    ? this.showPhoneNumberUrls(messageFirstPayload)
                    : messageFirstPayload}
                </span>
              </Wrapper>
            )}
            {chatType === "AdhocGroup" && (
              <Wrapper>
                <span className={"chatMessageName " + styles.name}>
                  {threadOwner && threadOwner.name}
                </span>
                <span className={styles.pagingGroup}>{recipients}</span>
                <span className={styles.subjectLine}>
                  <span className={styles.RE}>{copy.sasChat.replyShort}</span>
                  {subject}
                </span>
                <span className={styles.message + " smartlookMask "}>
                  {messageFirstPayload
                    ? this.showPhoneNumberUrls(messageFirstPayload)
                    : messageFirstPayload}
                </span>
              </Wrapper>
            )}
            {chatType === "Group" && (
              <Wrapper>
                <span className={"chatMessageName " + styles.name}>
                  {threadOwner && threadOwner.name}
                </span>
                <span className={styles.pagingGroup}>
                  {title && title.replace(/_/g, " ")}
                </span>
                <span className={styles.subjectLine}>
                  <span className={styles.RE}>{copy.sasChat.replyShort}</span>
                  {subject}
                </span>
                <span className={styles.message + " smartlookMask "}>
                  {messageFirstPayload
                    ? this.showPhoneNumberUrls(messageFirstPayload)
                    : messageFirstPayload}
                </span>
              </Wrapper>
            )}
            {chatType === "OneOnOne" && (
              <Wrapper>
                <span className={"chatMessageName " + styles.name}>
                  {threadOwner ? (
                    <Wrapper>
                      {this.state.UserId === threadOwner.userId ? (
                        <Wrapper>{recipientName && recipientName[0]}</Wrapper>
                      ) : (
                        threadOwner.name
                      )}
                    </Wrapper>
                  ) : (
                    <Wrapper>{recipientName && recipientName[0]}</Wrapper>
                  )}
                </span>
                <span className={styles.subjectLine}>
                  <span className={styles.RE}>{copy.sasChat.replyShort}</span>
                  {subject}
                </span>
                <span className={styles.message + " smartlookMask "}>
                  {messageLastPayload
                    ? this.showPhoneNumberUrls(messageLastPayload)
                    : messageLastPayload}
                </span>
              </Wrapper>
            )}
              </div>
          </td>
          </tr>
        </table>
        </div>
      );
    }
  }
}
export default withRouter(Chat);
