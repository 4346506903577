import React, { Component } from "react";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import Timer from "../Timer/Timer";
import Icon from "../UI/Icon/Icon";
import Button from "../UI/Button/Button";
import { findPhoneNumbersInText } from "libphonenumber-js";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";

// styles
import styles from "./PagerEvent.module.scss";

// Copy
import copy from "../../assets/copy/copy.json";

// Requests for API calls
import { Requests } from "../../api/IdentityServerRequests/Requests";

// Images
import SES from "../../assets/img/logos/logo_ses.png";
import CFA from "../../assets/img/logos/logo_cfa.png";
import AV from "../../assets/img/logos/logo_av.png";
import EMV from "../../assets/img/logos/logo_emv.svg";
import activeArrow from "../../assets/img/icons/right-arrow-solid.svg";
import activeArrowWhite from "../../assets/img/icons/right-arrow-solid-white.svg";
import { ReactComponent as ForwardArrow } from "../../assets/img/icons/forwards-arrow.svg";
import AttendanceCount from "../Attendance/AttendanceCount";

const reactStringReplace = require("react-string-replace");

// The pager event components that show on the home screen, pager history screen, event overview screen, dashboard and turnout
class PagerEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Window width
      width: window.innerWidth,
      // Index of pager event with menu open in pager history
      menuIndex: null,
      // If the menuIndex pager event is on delete confirmation
      deleting: false,
      // The event that this pager event message is for
      event: null,
      errorMessage: null,
    };
    this.Requests = new Requests();
    this.isEventUnreadArray = [];
  }

  static getDerivedStateFromProps(props, state) {
    if (props.event !== state.event) {
      return {
        event: props.event,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.event !== prevProps.event) {
      this.setState({
        event: this.props.event,
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  /**
   * Handles change to window size
   */
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  /**
   * Gets the path to the correct logo based on agency
   * @param {String} agency The agency of the event
   */
  agencyLogo(agency) {
    switch (agency) {
      case "AV": // 0
        return <img src={AV} alt="Ambulance Victoria logo" />;
      case "CFA": // 1
        return <img src={CFA} alt="CFA logo" />;
      case "SES": // 2
        return <img src={SES} alt="SES logo" />;
      default:
        return <img src={EMV} alt="EMV logo" />;
    }
  }

  /**
   * Changes styles based on alert type
   * @param {String} alert The alert type of the pager event (emergency, nonemergency, urgent, admin, OTA program, update)
   */
  alertType(alert) {
    switch (alert) {
      case "Emergency": // 0
        return styles.emergency + " enableCopyPaste";
      case "NonEmergency": // 1
        return styles.nonEmergency + " enableCopyPaste";
      case "Urgent": // 2
        return styles.urgent + " enableCopyPaste";
      case "Admin": // 3
        return styles.admin + " enableCopyPaste";
      case "OTA Program": // 4
        return styles.admin + " enableCopyPaste";
      case "UPDATE":
        return styles.update + " enableCopyPaste";
      default:
        break;
    }
  }

  /**
   * Navigates user to event overview for pager event
   * @param {Object} currentEvent Object for the event details for this pager event
   */
  handleClick = (currentEvent) => {
    // Handled by redirectToEvent function in HomeView component
    if (this.props.buttonClick) {
      this.props.buttonClick(currentEvent);
    }
  };

  /**
   * Redirects user to to event overview for pager event
   * @param {String} eventID The event ID for this pager event
   */
  redirectToEvent = (eventID) => {
    window.sessionStorage.setItem("redirectHomeFromEvent", "true");
    this.props.history.push({
      pathname: "/eventdetails/" + eventID,
    });
  };

  /**
   * Shows attendance status for the event (attending)
   * @param {Object} item The event details for the user
   */
  renderSASChatAttendanceStatus = (item) => {
    const isTurnout = !!matchPath(this.props.location.pathname, "/turnout");
    if (item.eventStatus && item.eventStatus === "Open") {
      // This event is Open (0)
      if (item.attendanceStatus && item.attendanceStatus === "Attending") {
        return (
          <div
            className={
              [styles.AttendanceBanner, styles.current].join(" ") + " cardBg2"
            }
          >
            <span
              aria-label="attending"
              className={[styles.icon, "greenBg icon_round icon_tick"].join(
                " "
              )}
            ></span>
            <span
              className={isTurnout ? styles.headingTurnout : styles.heading}
            >
              {copy.home.attending}
            </span>
          </div>
        );
      } else if (
        item.attendanceStatus &&
        item.attendanceStatus === "Unavailable"
      ) {
        return (
          <div
            className={
              [styles.AttendanceBanner, styles.current].join(" ") + " cardBg2"
            }
          >
            <span
              aria-label="not attending"
              className={[styles.icon, "redBg icon_round icon_cross"].join(" ")}
            ></span>
            <span
              className={
                isTurnout ? styles.notAttendingTurnout : styles.notAttending
              }
            >
              {copy.home.notAttending}
            </span>
          </div>
        );
      } else if (item.attendanceStatus && item.attendanceStatus === "Other") {
        return (
          <div
            className={
              [styles.AttendanceBanner, styles.current].join(" ") + " cardBg2"
            }
          >
            <span
              aria-label="other"
              className={[styles.icon, "yellowBg icon_round icon_dots"].join(
                " "
              )}
            ></span>
            <span className={isTurnout ? styles.otherTurnout : styles.other}>
              {item.paging.attendanceStatusOtherTitle || copy.home.other}
            </span>
          </div>
        );
      }

      // Inactive event
    } else if (item.eventStatus && item.eventStatus === "Closed") {
      if (item.attendanceStatus && item.attendanceStatus === "Attending") {
        return (
          <div
            className={
              [styles.AttendanceBanner, styles.current, styles.endedEvent].join(
                " "
              ) + " cardBg2"
            }
          >
            <span
              aria-label="attending"
              className={[styles.icon, "greenBg icon_round icon_tick"].join(
                " "
              )}
            ></span>
            <span className={styles.heading}>{copy.home.didAttend}</span>
          </div>
        );
      }
    }
  };

  /**
   * Shows if there are new chats for that particular event
   * @param {Object} item The event details for the user
   */
  renderSASChatMessageStatus = (item) => {
    if (item.eventStatus === "Open") {
      // This event is Open (0)
      if (item.attendanceStatus === "Attending") {
        return (
          <div className={[styles.MessagesBanner, styles.current].join(" ")}>
            {item.newChatsAvailable ? (
              // New chats avialable
              <div
                className={[styles.content, styles.newMessage].join(" ")}
                onClick={() => this.redirectToEvent(item.id)}
              >
                <div className={styles.chatIcon}></div>
                <span>{copy.home.newChat}</span>
              </div>
            ) : (
              // No new chats avialable
              <div
                className={styles.content}
                onClick={() => this.redirectToEvent(item.id)}
              >
                <span>{copy.home.reviewChat}</span>
              </div>
            )}
          </div>
        );
      }
    } else {
      // Event is inactive
      return (
        <div className={[styles.MessagesBanner, styles.ended].join(" ")}>
          <div
            className={styles.content}
            onClick={() => this.redirectToEvent(item.id)}
          >
            <span>{copy.home.archivedChat}</span>
          </div>
        </div>
      );
    }
  };

  /**
   * Opens the menu overlay for an event
   * @param {Integer} index The index of the pager event being opened
   */
  openMessageMenu = (index) => {
    this.setState({ menuIndex: index, deleting: false });
  };

  /**
   * Locks an event from being deleted. Greys out delete button and updates its lock status in backend
   * @param {Integer} key Index of event to be locked
   * @param {Integer} id Event ID of the event
   * @param {Boolean} locked Whether the event is currently locked or not
   */
  lockMessage = (key, id, locked) => {
    // newEvent is the current event but updated locked state
    const newEvent = this.state.event;
    newEvent[key] = {
      ...newEvent[key],
      isLocked: !locked,
    };
    // Sets new locked state
    this.setState({
      event: newEvent,
    });
    // Sets new state in database
    this.Requests.callAPI(this.Requests.lockPagerMessage, !locked, [id]).then(
      (data) => {
        if (data && data.status !== 200) {
          // Changes status back if API call fails
          const newEvent = this.state.event;
          newEvent[key] = {
            ...newEvent[key],
            isLocked: locked,
          };
          this.setState({
            event: newEvent,
          });
          let ErrorMessage =
            copy.event.lockPagingMessageAPIErrorMessage +
            ` (Error #${copy.errorCodes.lockPagingMessageAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  /**
   * When user selects delete button, this shows the confirmation screen
   */
  confirmDeleteMessage = () => {
    this.setState({
      deleting: true,
    });
  };

  /**
   * Handles deleting a message for a user
   * @param {Integer} key The index of the current event
   * @param {Integer} id Event ID of the current event
   */
  deleteMessage = (key, id) => {
    // Adds deleted flag to message for the user in database
    this.Requests.callAPI(this.Requests.deletePagerMessage, [id]).then(
      (data) => {
        if (data && data.status === 200) {
          // If the call succeeds, this hides the event from the pager history view (don't display event with isDeleted flag)
          const newEvent = this.state.event;
          newEvent[key] = {
            ...newEvent[key],
            isDeleted: true,
          };
          this.setState({
            event: newEvent,
          });
        } else {
          let ErrorMessage =
            copy.event.deletePagingMessageAPIErrorMessage +
            ` (Error #${copy.errorCodes.deletePagingMessageAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  /**
   * Removes delete confirmation
   */
  closeDeleteMenu = () => {
    this.setState({ deleting: false });
  };

  /**
   * Removes menu from event
   */
  closeMenu = () => {
    this.setState({ menuIndex: null });
  };

  /**
   * This function goes through all pager messages and updates the messages so that
   * recognised phone numbers, links, and emails can be clicked/tapped on
   */

  showPhoneNumberUrls = (originalMessage, id) => {
    let newMessage = originalMessage;
    // Use library to find all instances of a phone number
    let phoneNumbers = findPhoneNumbersInText(originalMessage, "AU");
    // Go through each instance of the phone number found from the plugin
    phoneNumbers.forEach((phoneNumber, index) => {
      // Keep the actual text string
      let text = originalMessage.slice(
        phoneNumber.startsAt,
        phoneNumber.endsAt
      );
      // Extract the correct phone number
      let number = "tel:" + phoneNumber.number.number;
      // Create a new message with a link for the phone number
      newMessage = reactStringReplace(newMessage, text, () => {
        return (
          <a
            className="hyperlink-theme"
            href={number}
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={id + phoneNumber.number.number + index}
          >
            {" "}
            {text}
          </a>
        );
      });
    });

    // HANDLE LOCAL NUMBERS WITHOUT AREA CODES
    // Formats handled "XXXXXXXX", "XXXX-XXXX", "XXXX XXXX"
    let regexPhone = /(?:\D|^)(\d{4}[ -]{0,1}\d{4})(?=\D|$)/g;
    // Use replace method to find all instances of the captured group and change the text into telephone links
    originalMessage.replace(regexPhone, (m, p1) => {
      newMessage = reactStringReplace(newMessage, p1, (match) => {
        return (
          <a
            key={m}
            className="hyperlink-theme"
            href={"tel:" + match}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            {match}
          </a>
        );
      });
    });

    // Detect links (urls, emails) from the text and convert it into a hyperlink
    let regex =
      /[(http(s)?):\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gim; // eslint-disable-line
    originalMessage.replace(regex, (url) => {
      let index = 0;
      if (url.includes("@")) {
        //for emails
        newMessage = reactStringReplace(newMessage, url, () => {
          return (
            <a
              key={url + index++}
              className="hyperlink-theme"
              href={"mailto:" + url}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {url}
            </a>
          );
        });
      } else {
        //for links
        if (
          url.startsWith("https") ||
          url.startsWith("http") ||
          url.startsWith("HTTP") ||
          url.startsWith("HTTPS")
        ) {
          newMessage = reactStringReplace(newMessage, url, () => {
            if (window.cordova) {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme"
                  target="_system"
                  rel="noopener noreferrer"
                  href={url}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.cordova.InAppBrowser.open(url, "_system");
                  }}
                >
                  {url}
                </a>
              );
            } else {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {url}
                </a>
              );
            }
          });
        } else if (url.startsWith("www") || url.startsWith("WWW")) {
          newMessage = reactStringReplace(newMessage, url, () => {
            if (window.cordova) {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme"
                  target="_system"
                  rel="noopener noreferrer"
                  href={"https://" + url}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.cordova.InAppBrowser.open(
                      "https://" + url,
                      "_system"
                    );
                  }}
                >
                  {url}
                </a>
              );
            } else {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"https://" + url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {url}
                </a>
              );
            }
          });
        }
      }
    });
    //Emergency Markers links
    let emergencyMarkerRegex = /\b[A-Z]{3}[0-9]{3}\b/gm; // eslint-disable-line
    originalMessage.replace(emergencyMarkerRegex, (url) => {
      newMessage = reactStringReplace(newMessage, url, () => {
        if (window.cordova) {
          return (
            <a
              key={url}
              className="hyperlink-theme"
              target="_system"
              rel="noopener noreferrer"
              href={
                "https://www.iconyx.com/meet-me-at-the-marker/?marker=" + url
              }
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.cordova.InAppBrowser.open(
                  "https://www.iconyx.com/meet-me-at-the-marker/?marker=" + url,
                  "_system"
                );
              }}
            >
              {url}
            </a>
          );
        } else {
          return (
            <a
              key={url}
              className="hyperlink-theme"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://www.iconyx.com/meet-me-at-the-marker/?marker=" + url
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {url}
            </a>
          );
        }
      });
    });
    return newMessage;
  };

  /**
   * Converts date/time so Safari reads it properly
   * @param {Date} date The MITS date to be converted
   */
  convertDate = (date) => {
    if (date) {
      return date
        .replace(/-/g, "/")
        .replace("T", " ")
        .replace(/\..*|\+.*/, "");
    } else {
      return null;
    }
  };

  render() {
    const { width } = this.state;
    const { isEventUnreadArray } = this.props;
    const isMobile = width <= 1023;

    // Following are flags for which page the user is on
    const isHomepage = !!matchPath(this.props.location.pathname, "/home");
    const isDashboard = !!matchPath(this.props.location.pathname, "/dashboard");
    const isTurnout = !!matchPath(this.props.location.pathname, "/turnout");
    const isEventOverview = !!matchPath(
      this.props.location.pathname,
      "/eventdetails"
    );
    const inSASChatView = !!matchPath(this.props.location.pathname, "/chat");
    const isPagerHistory = !!matchPath(
      this.props.location.pathname,
      "/pagerhistory"
    );
    const isTurnoutOrDashboard = isTurnout || isDashboard;

    // If it is dashboard page, then the events needs to be updated with new information
    let event;
    if (isDashboard) {
      event = this.props.event;
    } else {
      event = this.state.event;
    }

    // Maps all pager alerts to screen
    const pagerAlert = event.map((item, key) => {
      let eventStatus = item.eventStatus;
      let deleted = item.isDeleted;
      let isEventUnread = !isEventOverview && !item.dateRead ? true : false;
      let isEventClicked = this.props.lockedEvent === item.eventId;
      //  If isEventClicked is false, means page has just rendered so get unread status from props. Once turnout/dashboard is mounted, the unread status is stored in isEventUnreadArray
      let isTurnoutEventUnread =
        isTurnoutOrDashboard && isEventUnreadArray?.[key]
          ? isEventUnreadArray[key]
          : false;
      if (isEventClicked && isTurnoutEventUnread) {
        isEventUnreadArray[key] = isTurnoutEventUnread = false;
      }
      const IsEmergency = item.alertType !== 3;

      let selected = key === this.state.menuIndex;
      let deleting = this.state.deleting;
      let AlertTypeStyle, AlertTypeText;

      if (item.messageType === "Emergency") {
        // 0
        AlertTypeStyle = styles.emergency + " enableCopyPaste";
        AlertTypeText = copy.global.emerg;
      } else if (item.messageType === "NonEmergency") {
        // 1
        AlertTypeStyle = styles.nonEmergency + " enableCopyPaste";
        AlertTypeText = copy.global.nonEmerg;
      } else if (item.messageType === "Admin") {
        // 3
        AlertTypeStyle = styles.admin + " enableCopyPaste";
        AlertTypeText = copy.global.admin;
      }

      // // if (inSASChatView) {
      // if (item.eventActive) {
      //   alertRibbon = (
      //     <div
      //       className={
      //         styles.ribbon + " " + this.alertType(item.alertType.toUpperCase())
      //       }
      //     ></div>
      //   );
      // } else {
      //   alertRibbon = <div className={styles.ribbonEnded}></div>;
      // }
      // }

      // Mobile event
      if (
        !isMobile &&
        (isHomepage || isPagerHistory) &&
        !deleted &&
        !isDashboard
      ) {
        return (
          <Wrapper>
            <ErrorBanner
              isVisible={this.state.errorMessage ? true : false}
              ErrorMessage={this.state.errorMessage}
            />

            <div
              key={key}
              className={[
                "tileBg " + styles.pagerMessage,
                styles.desktop,
                eventStatus === "Closed" && styles.ended,
                AlertTypeStyle,
                // Closed (1) eventStatus
              ].join(" ")}
              onClick={() => !selected && this.handleClick(item)}
            >
              <div
                className={
                  styles.pagerContent +
                  " " +
                  (isPagerHistory && selected && deleting
                    ? styles.deleteConfirmationMenu
                    : "")
                }
                style={{ visibility: selected && "hidden" }}
              >
                {/* Pager event header */}
                <div
                  className={
                    styles.pagerHeader +
                    " " +
                    AlertTypeStyle +
                    " " +
                    (eventStatus === "Closed" && styles.ended + " tileBg")
                    // Open (0) eventStatus
                  }
                >
                  {this.renderSASChatAttendanceStatus(item)}
                  <div className={styles.content}>
                    <div className={styles.agencyLogo}>
                      {this.agencyLogo(item.agency)}
                    </div>
                    <div className={styles.alertType}>
                      {/* Changes colours based on event type */}
                      <span className={AlertTypeStyle}>{AlertTypeText}</span>
                      {!isEventOverview && IsEmergency && (
                        <div className={styles.timer}>
                          {
                            <Wrapper>
                              {eventStatus === "Open" ? (
                                <Timer
                                  // This converts time so it appears correctly in WebKit
                                  initialTimestamp={item.messageInputTimeStamp
                                    .replace(/-/g, "/")
                                    .replace(/[a-z]+/gi, " ")}
                                  eventStatus={eventStatus}
                                  pagerMessage={true}
                                  homeMessage={isHomepage || isPagerHistory}
                                />
                              ) : (
                                <div className={styles.inactiveIcon}>
                                  <span className="grey7 txt4">Inactive</span>
                                </div>
                              )}
                            </Wrapper>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Body of event */}
                <div
                  className={
                    isTurnoutOrDashboard
                      ? isTurnoutEventUnread
                        ? "unreadMessageIndicator " + styles.pagerInformation
                        : styles.pagerInformation
                      : isEventUnread
                      ? "unreadMessageIndicator " + styles.pagerInformation
                      : styles.pagerInformation
                  }
                >
                  <div
                    className={
                      isTurnoutOrDashboard
                        ? isTurnoutEventUnread
                          ? styles.pagerGroupBold
                          : styles.pagerGroup
                        : isEventUnread
                        ? styles.pagerGroupBold
                        : styles.pagerGroup
                    }
                  >
                    {isPagerHistory && item.isLocked && (
                      <Icon
                        label="Locked Event"
                        content=""
                        styles=""
                        icon="icon_locked"
                      />
                    )}
                    <span className="enableCopyPaste">{item.paging.name}</span>
                  </div>
                  <div
                    className={
                      "txt4 smartlook-hide smartlookMask  " +
                      styles.pagerDetails
                    }
                  >
                    <span>
                      {this.showPhoneNumberUrls(item.messageText, item.Id)}
                    </span>
                  </div>
                </div>
                <div className={styles.pagerTime}>
                  <div className={styles.timeStamp}>
                    <span className={styles.time + " enableCopyPaste"}>
                      {new Date(
                        this.convertDate(item.messageInputTimeStamp)
                      ).toLocaleTimeString("en-GB")}{" "}
                      {new Date(this.convertDate(item.messageInputTimeStamp))
                        .toLocaleDateString("en-GB")
                        .replace(/\//g, "-")}
                    </span>
                    {/* <span className={styles.time}>{item.time}</span>
                  <span className={styles.date}>{item.date}</span> */}
                    {isPagerHistory && (
                      <div
                        className={styles.menulink_dark}
                        onClick={(e) => {
                          this.openMessageMenu(key);
                          e.stopPropagation();
                        }}
                      >
                        <Icon
                          label="Menu link"
                          content=""
                          variant=""
                          styles=""
                          icon="icon_menulink dark"
                          buttonClick={() => {
                            this.openMessageMenu(key);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {/* Different response messages for homepage and pager history */}
                  {isHomepage && (
                    <div className={styles.respond + " enableCopyPaste fill"}>
                      {item.eventStatus === "Closed"
                        ? copy.home.review
                        : item.messageType === "Admin" // Admin Pager Msg(3)
                        ? copy.home.review
                        : item.agency === "AV"
                        ? copy.home.view
                        : copy.home.respond}
                      <ForwardArrow />
                    </div>
                  )}
                  {isPagerHistory && (
                    <div className={styles.respond + " enableCopyPaste fill"}>
                      {copy.home.review}
                      <ForwardArrow />
                    </div>
                  )}
                </div>
              </div>
              {inSASChatView && this.renderSASChatMessageStatus(item)}
              {/* Menu for pager history */}
              {isPagerHistory && selected && !deleting && (
                <div className={styles.menuButtons}>
                  <Button
                    label="Lock pager message"
                    buttonClick={() => {
                      this.lockMessage(key, item.Id, item.isLocked);
                    }}
                    content={item.isLocked ? "Unlock" : "Lock"}
                    variant="btn_outline green"
                    styles=""
                    // icon="icon_lock"
                  />
                  <Button
                    label="Delete pager message"
                    buttonClick={() => {
                      this.confirmDeleteMessage(item.Id);
                    }}
                    content="Delete"
                    variant="btn_outline"
                    styles={
                      item.isLocked || eventStatus === "Open"
                        ? "disabled"
                        : "red"
                    }
                    // icon="icon_close"
                  />
                  <Button
                    label="Close"
                    buttonClick={this.closeMenu}
                    content="Close"
                    variant="btn_outline grey"
                    styles=""
                  />
                </div>
              )}
              {/* Delete confirmation menu */}
              {isPagerHistory && selected && deleting && (
                <div className={styles.menuButtons}>
                  <div className={styles.confirmDeleteButtons}>
                    <span>
                      Are you sure you want to delete this message from your
                      account?
                    </span>
                    <div>
                      <Button
                        label="Delete pager message"
                        buttonClick={() => {
                          this.deleteMessage(key, item.Id);
                        }}
                        content="Confirm"
                        variant="btn_outline"
                        styles="red"
                        // icon="icon_close"
                      />
                      <Button
                        label="Cancel"
                        buttonClick={this.closeDeleteMenu}
                        content="Cancel"
                        variant="btn_outline grey"
                        styles=""
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Wrapper>
        );
      } else if (!deleted) {
        // Desktop event
        return (
          <Wrapper>
            <ErrorBanner
              isVisible={this.state.errorMessage ? true : false}
              ErrorMessage={this.state.errorMessage}
            />
            <div
              key={key}
              // Header
              className={
                "tileBg " +
                styles.pagerMessage +
                " " +
                AlertTypeStyle +
                " " +
                (eventStatus === "Closed" && styles.ended) +
                " " +
                (this.props.lockedEvent === item.eventId && styles.activeEvent)
                // Event has ended (1) closed event status
              }
              onClick={() => {
                !selected && this.handleClick(item);
              }}
            >
              {/* THIS IS ONLY FOR SAS CHAT */}
              {this.renderSASChatAttendanceStatus(item)}
              <div
                className={styles.pagerContent}
                style={{ visibility: selected && "hidden" }}
              >
                <div
                  key={key}
                  className={
                    styles.pagerHeader +
                    " " +
                    AlertTypeStyle +
                    " " +
                    (eventStatus === "Closed" && styles.ended + " endedTop")
                    // closed (1) event status
                  }
                >
                  <div
                    className={
                      (isTurnout
                        ? this.props.lockedEvent === item.eventId
                          ? styles.top
                          : styles.topTurnout
                        : styles.top) +
                      " top " +
                      ((isDashboard || isTurnout) && styles.isDashboard)
                    }
                  >
                    <div
                      className={
                        isTurnout ? styles.agencyLogoTurnout : styles.agencyLogo
                      }
                    >
                      {this.agencyLogo(item.agency)}
                    </div>
                    <div className={styles.alertType}>
                      <span className={AlertTypeStyle}>{AlertTypeText}</span>
                    </div>
                    <div
                      className={
                        isPagerHistory
                          ? styles.timeStampPagerHistory
                          : isTurnout
                          ? styles.timeStampTurnout
                          : styles.timeStamp
                      }
                    >
                      <span
                        className={
                          styles.time +
                          " enableCopyPaste" +
                          " " +
                          (eventStatus === "Closed" && "txt3 ") +
                          (isDashboard && " dashboardTime ") +
                          (isTurnout &&
                            this.props.lockedEvent === item.eventId &&
                            " dashboardTime ") +
                          (isEventOverview && " dashboardTime ")
                        }
                      >
                        {new Date(
                          this.convertDate(item.messageInputTimeStamp)
                        ).toLocaleTimeString("en-GB")}
                        {"  "}
                        {new Date(this.convertDate(item.messageInputTimeStamp))
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")}
                      </span>
                      {/* <span className={styles.time}>{item.time}</span>
                    <span className={styles.date}>{item.date}</span> */}
                    </div>
                    {isPagerHistory && (
                      <div
                        className={styles.menulink}
                        onClick={(e) => {
                          this.openMessageMenu(key);
                          e.stopPropagation();
                        }}
                      >
                        <Icon
                          label="Menu link"
                          content=""
                          variant=""
                          styles=""
                          icon={
                            eventStatus === "Open"
                              ? "icon_menulink"
                              : "icon_menulink dark"
                          }
                          buttonClick={() => {
                            this.openMessageMenu(key);
                          }}
                        />
                      </div>
                    )}
                    {this.props.lockedEvent === item.eventId && (
                      <div className={styles.activeArrow}>
                        {eventStatus === "Closed" ? (
                          <img src={activeArrow} alt="active arrow" />
                        ) : (
                          <img src={activeArrowWhite} alt="active arrow" />
                        )}
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      isTurnoutOrDashboard
                        ? isTurnoutEventUnread
                          ? "unreadMessageIndicator " + styles.bottom
                          : styles.bottom
                        : isEventUnread
                        ? "unreadMessageIndicator " + styles.bottom
                        : styles.bottom
                    }
                  >
                    {isPagerHistory && item.isLocked && (
                      <Icon
                        label="Locked Event"
                        content=""
                        styles=""
                        icon="icon_locked"
                      />
                    )}
                    <div
                      className={
                        isTurnoutOrDashboard
                          ? isTurnoutEventUnread
                            ? styles.pagerGroupBold
                            : styles.pagerGroup
                          : isEventUnread
                          ? styles.pagerGroupBold
                          : styles.pagerGroup
                      }
                    >
                      <span className="enableCopyPaste">
                        {item.paging.name}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    isTurnout
                      ? "txt4 smartlookMask " + styles.pagerTurnoutDetails
                      : "txt4 smartlook-hide " + styles.pagerDetails
                  }
                >
                  <span>{this.showPhoneNumberUrls(item.messageText)}</span>
                </div>
                <div
                  className={
                    isTurnout
                      ? styles.footerWrapperTurnout
                      : styles.footerWrapper
                  }
                >
                  {!isEventOverview && IsEmergency && (
                    <div className={styles.timer}>
                      {
                        <Wrapper>
                          {eventStatus === "Open" ? (
                            <Wrapper>
                              <Timer
                                initialTimestamp={item.messageInputTimeStamp
                                  .replace(/-/g, "/")
                                  .replace(/[a-z]+/gi, " ")}
                                eventStatus={eventStatus}
                                pagerMessage={true}
                                showMobileView={isDashboard || isTurnout}
                                homeMessage={isHomepage || isPagerHistory}
                              />
                              {/* Attendance count is only displayed on the dashboard and not for AV */}
                              {(isDashboard || isTurnout) &&
                                item.agency !== "AV" && (
                                  <AttendanceCount
                                    attending={item.attendingCount}
                                    other={item.otherCount}
                                    notAttending={item.unAvailableCount}
                                  />
                                )}
                            </Wrapper>
                          ) : (
                            <Wrapper>
                              <div className={styles.inactiveIcon}>
                                <span className="grey7 txt4">Inactive</span>
                              </div>
                              {/* Attendance count is only displayed on the dashboard and not for AV */}
                              {isDashboard &&
                                item.agency !== "AV" &&
                                AlertTypeText !== "Admin" && (
                                  <AttendanceCount
                                    attending={item.attendingCount}
                                    other={item.otherCount}
                                    notAttending={item.unAvailableCount}
                                  />
                                )}
                            </Wrapper>
                          )}
                        </Wrapper>
                      }
                    </div>
                  )}
                  {isHomepage && (
                    <div className={styles.respond + " enableCopyPaste fill"}>
                      {item.eventStatus === "Closed"
                        ? copy.home.review
                        : item.messageType === "Admin" // Admin Pager Msg(3)
                        ? copy.home.review
                        : item.agency === "AV"
                        ? copy.home.view
                        : copy.home.respond}
                      <ForwardArrow />
                    </div>
                  )}
                  {isPagerHistory && (
                    <div className={styles.respond + " enableCopyPaste fill"}>
                      {copy.home.review}
                      <ForwardArrow />
                    </div>
                  )}
                </div>
              </div>
              {inSASChatView && this.renderSASChatMessageStatus(item)}
              {/* Menu for pager history */}
              {isPagerHistory && selected && !deleting && (
                <div className={styles.menuButtons}>
                  <Button
                    label="Lock pager message"
                    buttonClick={() => {
                      this.lockMessage(key, item.Id, item.isLocked);
                    }}
                    content={item.isLocked ? "Unlock" : "Lock"}
                    variant="btn_outline green"
                    styles=""
                    // icon="icon_lock"
                  />
                  <Button
                    label="Delete pager message"
                    buttonClick={() => {
                      this.confirmDeleteMessage(item.Id);
                    }}
                    content="Delete"
                    variant="btn_outline"
                    styles={
                      item.isLocked || eventStatus === "Open"
                        ? "disabled"
                        : "red"
                    }
                    // icon="icon_close"
                  />
                  <Button
                    label="Close"
                    buttonClick={this.closeMenu}
                    content="Close"
                    variant="btn_outline grey"
                    styles=""
                  />
                </div>
              )}
              {/* Delete confirmation menu */}
              {isPagerHistory && selected && deleting && (
                <div className={styles.menuButtons}>
                  <div className={styles.confirmDeleteButtons}>
                    <span>
                      Are you sure you want to delete this message from your
                      account?
                    </span>
                    <div>
                      <Button
                        label="Delete pager message"
                        buttonClick={() => {
                          this.deleteMessage(key, item.Id);
                        }}
                        content="Confirm"
                        variant="btn_outline"
                        styles="red"
                        // icon="icon_close"
                      />
                      <Button
                        label="Cancel"
                        buttonClick={this.closeDeleteMenu}
                        content="Cancel"
                        variant="btn_outline grey"
                        styles=""
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Wrapper>
        );
      } else {
        return null;
      }
    });

    return <Wrapper>{pagerAlert}</Wrapper>;
  }
}
export default withRouter(PagerEvent);
