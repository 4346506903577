import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { matchPath } from "react-router";
import debounce from "lodash.debounce";
import Wrapper from "./hoc/Wrapper/Wrapper";
import ErrorBanner from "./hoc/ErrorBanner/ErrorBanner";
import AlertBanner from "./hoc/AlertBanner/AlertBanner";

import Home from "./views/Home/HomeView";
import EventOverviewView from "./views/EventOverview/EventOverviewView";
import SASChat from "./views/SASChat/SASChatView";
import PagerHistoryView from "./views/PagerHistory/PagerHistoryView";
import Settings from "./views/Settings/SettingsView";
import Admin from "./views/Admin/SearchView";
import Availability from "./views/Availability/AvailabilityView";
import Dashboard from "./views/Dashboard/DashboardView";
import Download from "./views/Dashboard/Reports/Download";
import Turnout from "./views/Turnout/TurnoutView";
import TurnoutSettingsView from "./views/Turnout/Settings/TurnoutSettings";
import Report from "./views/Dashboard/Reports/ReportView";
import Loader from "./components/UI/Loading/Loading";
import Button from "./components/UI/Button/Button";
// eslint-disable-next-line
import SASUpdateInProgress from "./views/ForceUpdate/SASUpdateInProgress";

import UnknownError from "./views/ErrorPages/UnknownError";
import Login from "./views/Authentication/LoginView";
import TermsOfUse from "./views/Authentication/TermsOfuse";
import LocationDisclosure from "./views/Authentication/LocationDisclosure";
import AndroidAutoDisclosure from "./views/Authentication/AndroidAutoDisclosure";
import ASN from "./views/Authentication/ASNView";
import styles from "./views/ErrorPages/UnknownError.module.scss";

import { Requests } from "./api/IdentityServerRequests/Requests";
import Navigation from "./components/Navigation/Main/Navigation";
import ForceUpdate from "./views/ForceUpdate/ForceUpdate";

import { logger } from "./utils";

import copy from "./assets/copy/copy.json";
import smartlookClient from "smartlook-client";

class Routes extends Component {
  // firebasePlugin;

  constructor(props) {
    super(props);
    this.Requests = new Requests();
    // null means that the async data requests have not returned yet
    // false means that the requests have returned but there was no information found
    this.state = {
      errorMessage: null,
      errorCode: null,
      accessToken: null,
      userInformation: null,
      userInfo: null,
      userAgencies: null,
      initialised: false,
      pagingGroupsPartOf: null,
      pagingGroupsOwned: null,
      userPagingGroupsWithAvailability: null,
      authorisedPagingGroups: null,
      initialAPIsCalled: false, // This implies that the initial apis required for the app to work were not called
    };
  }

  // Check if the firebase token has changed and the API call needs to be made
  componentDidUpdate() {
    if (window.cordova) {
      let updateFCMToken = window.localStorage.getItem("updateFCMToken");
      if (updateFCMToken === "true") {
        let fcmToken = window.localStorage.getItem("fcmToken");
        this.sendPushNotificationsToken(fcmToken);
        window.localStorage.setItem("updateFCMToken", "false");
      }

      // Check if the user needs to be redirected to event
      let redirectUserToEvent = window.localStorage.getItem("redirectToEvent");
      if (redirectUserToEvent !== null) {
        this.props.history.push({
          pathname: "/eventdetails/" + redirectUserToEvent,
        });
        window.localStorage.removeItem("redirectToEvent");
      }

      // Check if the user needs to be redirected to chat
      let redirectUserToChat = window.localStorage.getItem("redirectToChat");
      if (redirectUserToChat !== null) {
        this.props.history.push({
          pathname: "/chat/chats/" + redirectUserToChat,
        });
        window.localStorage.removeItem("redirectToChat");
      }
    }
  }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.checkDarkMode);
  // }

  componentDidMount = () => {
    this.Requests.checkIfForceLogoutIsEnabled();
    // Checks if the user is installing the app for the first time.
    // If they are, clears the storage and creates a variables ot keep track of it.
    let firstAppInstall = window.localStorage.getItem("firstAppInstall");
    if (firstAppInstall === null) {
      const value = window.localStorage.getItem("notificationPoppedup");
      window.localStorage.clear();
      window.localStorage.setItem("notificationPoppedup", value);
      window.sessionStorage.clear();
      window.localStorage.setItem("firstAppInstall", "false");
      window.localStorage.setItem("displayPreference", "default");
    }
    this.initPushNotifications();
    // Initial dark mode check
    this.checkDisplayPreferences();

    // Dark mode onChange listener that triggers when the user's changes the devices default preferences.
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    try {
      // Listener for Chrome & Firefox & Android
      darkModeMediaQuery.addEventListener("change", (e) => {
        this.checkDisplayPreferences();
      });
    } catch (error1) {
      try {
        // Listener for Safari & iOS
        darkModeMediaQuery.addListener((e) => {
          this.checkDisplayPreferences();
        });
      } catch (error2) {
        console.error(error2);
      }
    }

    // Only on Android, listens for when device resumes from background and checks for theme change
    if (window.device && window.device.platform === "Android") {
      window.document.addEventListener("resume", () => {
        this.checkDisplayPreferences();
      });
    }

    // Check if its opening up the event page
    let isAccessingEventPage = !!matchPath(
      this.props.location.pathname,
      "/eventdetails"
    );
    if (!isAccessingEventPage) {
      /**
       * The user is NOT checking the event page first, so make all the necessary checks for:
       * As the next step, the app calls about 7 different API calls to our SAS backend to work out:
       * - Does the app require a force update?
       * - Has the user accepted terms of use?
       * - Who is this user, what's their userId, name, role etc..? Based on the access token we send.
       * - What permissions does this user have based on their login from Identity Sever? (permissions from Active Directory/SAS only user etc..)
       * - Which agencies does this user belong to?
       * - Which paging groups is this user a part of?
       * - Which paging groups is this user an owner of?
       */
      this.initialiseSASUserData();
    } else {
      // The IS checking the event page so skip all the checks
      // Get the access token
      this.Requests.getAccessToken().then((accessToken) => {
        this.setState({ accessToken: accessToken, initialAPIsCalled: true });
      });
    }

    // Ready background location tracking:
    if (window.cordova) {
      let refreshToken =
        this.Requests.getIdentityTokensFromStorage("refreshToken");
      let accessToken =
        this.Requests.getIdentityTokensFromStorage("accessToken");
      let expiresAt = this.Requests.getIdentityTokensFromStorage("expiresAt");
      if (accessToken && refreshToken && expiresAt) {
        this.readyBackgroundGeolocation(accessToken, refreshToken, expiresAt);
      }
    }

    // Ready Smartlook:
    if (
      process.env.REACT_APP_ROOT_DEV === "https://web.uat.emvsas.dxau.digital/"
    ) {
      this.setupSmartlook();
    }
  };

  /**
   * This makes Routes an error boundary component which will catch any uncaught errors in any of its children.
   * Any caught errors will reroute to the login screen with an error banner.
   */
  componentDidCatch = () => {
    this.setState({ errorMessage: copy.error_handling.criticalError }, () => {
      setTimeout(
        function () {
          this.setState({ errorMessage: null });
        }.bind(this),
        5000
      );
      this.props.history.push({
        pathname: "/login",
      });
    });
  };

  getFromMobileStorage = (key) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.getObject(key, resolve, reject);
    });

  setInMobileStorage = (key, value) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.putObject(key, value, resolve, reject);
    });

  async storeInitialData(key, value) {
    if (window.cordova) {
      await this.setInMobileStorage(key, value);
    }
  }

  initialiseSASUserData = async () => {
    if (window.cordova) {
      // Check if the user needs to update the application
      // The sasAppConfigs.json is stored on the web server in (Dev, UAT and Prod), so we can control the configs without an appstore approval process.
      let configURL = process.env.REACT_APP_ROOT_DEV + "sasAppConfigs.json";
      const request = new Request(configURL);
      fetch(request)
        .then((res) => res.json())
        .then(
          (result) => {
            let currentAppVersionOnServer;
            // Getting the latest release version from the server
            if (window.device && window.device.platform === "iOS") {
              currentAppVersionOnServer = result.currentiOSBuildVersion;
            } else if (window.device && window.device.platform === "Android") {
              currentAppVersionOnServer = result.currentAndroidBuildVersion;
            }

            // Comparing the version from the server to the downloaded one on the phone
            if (currentAppVersionOnServer !== undefined) {
              const semver = require("semver");
              if (
                semver.gt(currentAppVersionOnServer, window.AppVersion.version)
              ) {
                if (result.forceAppUpdate === "true") {
                  this.props.history.push({
                    pathname: "/force-update",
                    state: { result: result },
                  });
                } else {
                  // Need to put in a check for clearing local storage if different version of the app.
                  let firstAppInstall =
                    window.localStorage.getItem("firstAppInstall");
                  if (firstAppInstall === null) {
                    const value = window.localStorage.getItem(
                      "notificationPoppedup"
                    );
                    window.localStorage.clear();

                    window.localStorage.setItem("firstAppInstall", "false");
                    window.localStorage.setItem("notificationPoppedup", value);
                  }
                }
              }
            }
          },
          (error) => {
            console.log("Error in determining if app needs to be updated");
            console.log(error);
          }
        );

      // get initial data from the native storage and set state
      let initialUserInfo = await this.getFromMobileStorage("userInfo");
      let initialInfo = await this.getFromMobileStorage("info");
      let initialAgency = await this.getFromMobileStorage("agency");

      if (
        initialUserInfo &&
        initialUserInfo !== "null" &&
        initialInfo &&
        initialInfo !== "null" &&
        initialAgency &&
        initialAgency !== "null"
      ) {
        this.setState(
          {
            userInformation: initialUserInfo,
            userInfo: initialInfo,
            userAgencies: initialAgency,
          },
          () => {
            this.getPagingGroupsPartOfJoinedAndOwned();
            this.getUserAvailabilityPagingGroups();
            this.getAuthorisedPagingGroups();
            this.setSmartlookUser();
          }
        );
      }
    }

    // Request the user's access token
    this.Requests.getAccessToken().then((accessToken) => {
      this.setState({ accessToken: accessToken });
      // If the user as an access token (i.e. they're logged in)
      if (
        accessToken !== false &&
        accessToken !== undefined &&
        accessToken !== null &&
        accessToken !== "null"
      ) {
        // This function has error handling for GET requests.

        // API call to get user information
        if (!this.state.userInformation) this.getUserInformation();

        // API call to get whether user has accepted the latest terms of use
        this.Requests.callAPI(this.Requests.getCurrentAcceptedTermsOfUse).then(
          (data) => {
            if (data && data.status && data.status === 200) {
              if (data.data.DateTimeAccepted) {
                // Save in local storage if terms of use is accepted or not
                window.localStorage.setItem("termsOfUseAccepted", "true");
              } else {
                window.localStorage.setItem("termsOfUseAccepted", "false");
              }
            } else if (data && data.status && data.status === 401) {
              // Reset to false, since accessToken is invalid
              window.localStorage.removeItem("rolesNotValid");
              window.localStorage.setItem("termsOfUseAccepted", "false");
              window.localStorage.setItem("updateFCMToken", "true");
              this.setState(
                {
                  userInformation: false,
                  userInfo: false,
                  userAgencies: false,
                  pagingGroupsPartOf: false,
                  pagingGroupsOwned: false,
                  accessToken: false,
                },
                () => {
                  this.props.history.push({
                    pathname: "/login",
                  });
                }
              );
            } else {
              window.localStorage.setItem("termsOfUseAccepted", "false");
              let ErrorMessage =
                copy.termsOfUse.errorDesc +
                ` (Error #${copy.errorCodes.termsOfUse})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  errorCode: copy.errorCodes.termsOfUse,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          }
        );

        // API call to get user agency and agency information, including agency settings
        if (!this.state.userInfo) {
          this.getUserSASInfo();
        }

        // API call to get user agencies
        if (!this.state.userAgencies) {
          this.getUserAgencies();
        }
      } else {
        window.localStorage.setItem("termsOfUseAccepted", "false");
        this.setState({
          userInformation: false,
          userInfo: false,
          userAgencies: false,
          pagingGroupsPartOf: false,
          pagingGroupsOwned: false,
        });
      }
    });
  };

  /**
   * Makes API call to get user information
   */
  getUserInformation = () => {
    this.Requests.callAPI(this.Requests.userInformation).then((data) => {
      if (data && data.status && data.status === 200) {
        logger.setUserId(data.data.sub);
        this.setState({ userInformation: data.data });
        // store initial information in Native Storage
        this.storeInitialData("userInfo", data.data);
        this.setSmartlookUser();
        if (data.data && data.data.role) {
          window.localStorage.setItem(
            "userRoleAgency",
            JSON.stringify(data.data.role)
          );
        }
      } else if (data && data.status && data.status === 401) {
        // Reset to false, since accessToken is invalid
        window.localStorage.removeItem("rolesNotValid");
        window.localStorage.setItem("termsOfUseAccepted", "false");
        window.localStorage.setItem("updateFCMToken", "true");
        this.setState(
          {
            userInformation: false,
            userInfo: false,
            userAgencies: false,
            pagingGroupsPartOf: false,
            pagingGroupsOwned: false,
            accessToken: false,
          },
          () => {
            this.props.history.push({
              pathname: "/login",
            });
          }
        );
      } else {
        let ErrorMessage =
          copy.login.userInfoAPIErrorMessage +
          ` (Error #${copy.errorCodes.userInfoAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            userInformation: false,
            errorMessage: ErrorMessage,
            errorCode: copy.errorCodes.userInfoAPIErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * Makes API call to get user information
   */
  getUserSASInfo = () => {
    this.Requests.callAPI(this.Requests.userSASInfo).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({ userInfo: data.data });
        // store initial user info in native storage
        this.storeInitialData("info", data.data);
      } else if (data && data.status && data.status === 401) {
        // Reset to false, since accessToken is invalid
        window.localStorage.removeItem("rolesNotValid");
        window.localStorage.setItem("termsOfUseAccepted", "false");
        window.localStorage.setItem("updateFCMToken", "true");
        this.setState(
          {
            userInformation: false,
            userInfo: false,
            userAgencies: false,
            pagingGroupsPartOf: false,
            pagingGroupsOwned: false,
            accessToken: false,
          },
          () => {
            this.props.history.push({
              pathname: "/login",
            });
          }
        );
      } else {
        let ErrorMessage =
          copy.login.userSettingsAPIErrorMessage +
          ` (Error #${copy.errorCodes.userSettingsAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            userInfo: false,
            errorMessage: ErrorMessage,
            errorCode: copy.errorCodes.userSettingsAPIErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /**
   * Makes API call to get user's agencies
   * If successful, will also proceed to get user's paging groups
   */
  getUserAgencies = () => {
    // Request to get an array of the agencies the user belongs to
    this.Requests.callAPI(this.Requests.getUserAgencies).then((data) => {
      if (data && data.status && data.status === 200) {
        // store initial user agency info in native storage
        this.storeInitialData("agency", data.data);
        this.setState({ userAgencies: data.data }, () => {
          window.localStorage.setItem("userAgencies", data.data);
          // Get user's paging groups if agencies
          this.getPagingGroupsPartOfJoinedAndOwned();
          this.getUserAvailabilityPagingGroups();
          this.getAuthorisedPagingGroups();
        });
      } else if (data && data.status && data.status === 401) {
        // Reset to false, since accessToken is invalid
        window.localStorage.removeItem("rolesNotValid");
        window.localStorage.setItem("termsOfUseAccepted", "false");
        window.localStorage.setItem("updateFCMToken", "true");
        this.setState(
          {
            userInformation: false,
            userInfo: false,
            userAgencies: false,
            pagingGroupsPartOf: false,
            pagingGroupsOwned: false,
            accessToken: false,
          },
          () => {
            this.props.history.push({
              pathname: "/login",
            });
          }
        );
      } else {
        let ErrorMessage =
          copy.login.userAgencyAPIErrorMessage +
          ` (Error #${copy.errorCodes.userAgencyAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            userAgencies: false,
            pagingGroupsOwned: false,
            pagingGroupsPartOf: false,
            errorMessage: ErrorMessage,
            errorCode: copy.errorCodes.userAgencyAPIErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * Check local storage to see the user's appearance preferences
   */
  checkDisplayPreferences = () => {
    // Check display preferences
    let displayPreferences = window.localStorage.getItem("displayPreference");
    if (displayPreferences === "default") {
      // Apply the display preferences from the device (dark mode on launch)
      // if (window.device && window.device.platform === "Android") {
      if (window.device) {
        // Android uses the plugin - matchMedia not supported
        const self = this;
        if (window.cordova.plugins.ThemeDetection) {
          window.cordova.plugins.ThemeDetection.isDarkModeEnabled(function (
            success
          ) {
            // success is either true or false
            if (success.value) {
              self.updateAppTheme("dark");
            } else {
              self.updateAppTheme("light");
            }
          });
        }
      } else if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        this.updateAppTheme("dark");
      } else {
        this.updateAppTheme("light");
      }
    } else if (displayPreferences === "light") {
      // Overwrite display preferences and apply light mode
      this.updateAppTheme("light");
    } else if (displayPreferences === "dark") {
      // Overwrite display preferences and apply dark mode
      this.updateAppTheme("dark");
    }
  };

  /**
   * Apply's the theme on the app based on whats passed into the function
   */
  updateAppTheme = (theme) => {
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("class", "theme-" + theme);

    // Update the status bar in the app
    if (window.cordova && window.device.platform === "iOS") {
      if (theme === "dark") {
        // The statusbar plugin is a fork of the original plugin which fixes an issue with the text colour
        window.StatusBar.backgroundColorByName("black");
        window.StatusBar.styleLightContent();
      } else if (theme === "light") {
        window.StatusBar.backgroundColorByName("white");
        window.StatusBar.styleDefault();
      }
    }
  };

  /**
   * API call to get the paging groups the user is part of and owned (through ASN or SAS)
   */
  getPagingGroupsPartOfJoinedAndOwned = () => {
    // Request to get an array of the agencies the user belongs to
    this.Requests.callAPI(this.Requests.getUserPagingGroupsJoinedAndOwned).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          // THIS IS FOR GETTING ALL THE GROUPS THE USER IS A PART OF
          let pagingGroupsPartOf = {};
          pagingGroupsPartOf.all = [];
          this.state.userAgencies.forEach((item) => {
            pagingGroupsPartOf[item] = [];
          });
          // This sorts all paging groups by their respective agencies into the pagingGroupsPartOf object.
          data.data.Pagings.forEach((item) => {
            if (item.agency && pagingGroupsPartOf[item.agency]) {
              pagingGroupsPartOf[item.agency].push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
              // Paging groups with no agency are placed in the 'all' child.
            } else if (!item.agency) {
              pagingGroupsPartOf.all.push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
            }
          });

          // THIS FOR ALL THE GROUPS THE USER IS AN OWNER OF
          let pagingGroupsOwned = {};
          for (let i = 0; i < this.state.userAgencies.length; i++) {
            pagingGroupsOwned[this.state.userAgencies[i]] = [];
          }
          for (let i = 0; i < data.data.OwnedPagings.length; i++) {
            if (data.data.OwnedPagings[i].agency !== null) {
              if (
                pagingGroupsOwned[data.data.OwnedPagings[i].agency] !==
                undefined
              ) {
                let currentGroupAvailabilityEnabled =
                  data.data.OwnedPagings[i].settings &&
                  data.data.OwnedPagings[i].settings.availabilityEnabled
                    ? data.data.OwnedPagings[
                        i
                      ].settings.availabilityEnabled.toString()
                    : null;
                pagingGroupsOwned[data.data.OwnedPagings[i].agency].push({
                  key: data.data.OwnedPagings[i].number,
                  text: data.data.OwnedPagings[i].name,
                  value: data.data.OwnedPagings[i].number,
                  availabilityenabled: currentGroupAvailabilityEnabled,
                });
              } else {
                // The paging group returned is from the agency the user is no longer a part of
                this.setState(
                  {
                    errorMessage: copy.error_handling.errorPagingGroupOwner,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            }
          }
          this.setState({
            pagingGroupsPartOf: pagingGroupsPartOf,
            pagingGroupsOwned: pagingGroupsOwned,
          });
        } else if (data && data.status && data.status === 401) {
          // Reset to false, since accessToken is invalid
          window.localStorage.removeItem("rolesNotValid");
          window.localStorage.setItem("termsOfUseAccepted", "false");
          window.localStorage.setItem("updateFCMToken", "true");
          this.setState(
            {
              userInformation: false,
              userInfo: false,
              userAgencies: false,
              pagingGroupsPartOf: false,
              pagingGroupsOwned: false,
              accessToken: false,
            },
            () => {
              this.props.history.push({
                pathname: "/login",
              });
            }
          );
        } else {
          let ErrorMessage =
            copy.login.userPagingGroupAPIErrorMessage +
            ` (Error #${copy.errorCodes.userPagingGroupAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            {
              pagingGroupsPartOf: false,
              pagingGroupsOwned: false,
              errorMessage: ErrorMessage,
              errorCode: copy.errorCodes.userPagingGroupAPIErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  /**
   * API call to get all paging groups the user is part of and has availability enabled
   */
  getUserAvailabilityPagingGroups = () => {
    let pagingGroupsWithAvailability = {};
    this.state.userAgencies.forEach((agency) => {
      pagingGroupsWithAvailability[agency] = [];
    });

    this.Requests.callAPI(this.Requests.postUserAgencyPagingGroups, {}).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          data.data.forEach((pagingGroup) => {
            if (
              pagingGroup.settings &&
              pagingGroup.settings.availabilityEnabled
            ) {
              pagingGroupsWithAvailability[pagingGroup.agency].push({
                availabilityenabled: true,
                key: pagingGroup.number,
                text: pagingGroup.name.replace(/_/g, " "),
                value: pagingGroup.number,
                agency: pagingGroup.agency,
              });
            }
          });

          this.setState({
            userPagingGroupsWithAvailability: pagingGroupsWithAvailability,
          });
        } else {
          let ErrorMessage =
            copy.settings.profile.pagingGroupHideShowInfoAPIErrorMessage +
            ` (Error #${copy.errorCodes.pagingGroupHideShowInfoAPIErrorMessage})`;
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  /**
   * API call to get all paging groups that the user is authorised to access
   */
  getAuthorisedPagingGroups = () => {
    this.Requests.callAPI(this.Requests.postUserPagingGroups, {}).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          let authorisedPagingGroups = {};
          authorisedPagingGroups.all = [];
          this.state.userAgencies.forEach((item) => {
            authorisedPagingGroups[item] = [];
          });

          data.data.forEach((item) => {
            if (item.agency && authorisedPagingGroups[item.agency]) {
              authorisedPagingGroups[item.agency].push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
              // Paging groups with no agency are placed in the 'all' child.
            } else if (!item.agency) {
              authorisedPagingGroups.all.push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
            }
          });

          this.setState({
            authorisedPagingGroups: authorisedPagingGroups,
          });
        } else {
          let ErrorMessage =
            copy.login.userPagingGroupAPIErrorMessage +
            ` (Error #${copy.errorCodes.userPagingGroupAPIErrorMessage})`;
          this.setState(
            {
              authorisedPagingGroups: false,
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  /**
   * Sets current user location in the database
   */
  setLocation = debounce((userLocation) => {
    let currentEventID = window.localStorage.getItem(
      "currentlyAttendingEventId"
    );
    if (window.cordova && currentEventID) {
      this.Requests.callAPI(
        this.Requests.sendLastKnownLocation,
        currentEventID,
        {
          coordinates: userLocation,
        }
      ).then((response) => {
        if (response && response.status !== 200) {
          return <ErrorBanner errorType={response.status} />;
        }
      });
    }
  }, 1000);

  // Readies background geolocation plugin
  readyBackgroundGeolocation = () => {
    let currentUserId = window.localStorage.getItem("userId");
    let currentlyAttendingEventId = window.localStorage.getItem(
      "currentlyAttendingEventId"
    );
    let authKey = window.localStorage.getItem("lastKLKey");
    if (window.cordova && !this.state.initialised) {
      window.BackgroundGeolocation.ready({
        locationAuthorizationRequest: "Any",
        // debug: true, // Enable this when debugging
        // logLevel: window.BackgroundGeolocation.LOG_LEVEL_DEBUG, // Enable this when debugging
        preventSuspend: true,
        hearbeatInterval: 60,
        stopAfterElapsedMinutes: 60,
        stopOnTerminate: false,
        stopTimeout: 60,
        foregroundService: true,
        // Android 11 has changed location authorization and no longer offers the [Allow all the time] button on the location authorization dialog.
        // Instead, Android now offers a hook to present a custom dialog to the user where you will explain exactly why you require "Allow all the time" location permission.
        backgroundPermissionRationale: {
          title: "Use of your location",
          message: copy.location.disclosure1,
          positiveAction: "Change to {backgroundPermissionOptionLabel}",
          negativeAction: "Cancel",
        },
        url:
          process.env.REACT_APP_EVENT_API_DEV +
          "/UserLocationTracking/LastKnownLocation",
        headers: {
          authorization: authKey,
        },
        method: "PUT",
        params: {
          eventId: currentlyAttendingEventId,
          userId: currentUserId,
        },
        // headers: {
        //   authorization: "Bearer " + accessToken,
        // },
        // maxRecordsToPersist: 5,
        // maxDaysToPersist: 1,
        autoSync: true,
        autoSyncThreshold: 1,
        batchSync: false,
      });

      // This function will check the responses from the api being used by the BackgroundGeolocation plugin
      // It will stop tracking when error code is recieved
      window.BackgroundGeolocation.onHttp((response) => {
        // let responseText = response.responseText; //Turn on when in debug mode,
        // let status = response.status; //Turn on when in debug mode
        // console.log("[onHttp] " + status + " " + responseText); //Turn on when in debug mode

        // Stop tracking the user since either the event is closed or they have arrived the geofence
        if (response.status === 200 && response.responseText === "true") {
          window.BackgroundGeolocation.stop();
          window.localStorage.removeItem(
            "currentlyAttendingEventDestinationCoords"
          );
          window.localStorage.removeItem("currentlyAttendingEventId");
          window.localStorage.removeItem("lastKLKey");
        }
      });

      // Enable this when the plugin is in debug mode to view the logs
      // window.BackgroundGeolocation.logger.getLog(function (log) {
      //   console.log(log);
      // });

      window.BackgroundGeolocation.onLocation(
        (location) => {
          // When a new location is recorded and the app is in foregroung
          // Check if the tracking needs to be stopped
          // Set user's location
          let userLocation = [
            location.coords.latitude,
            location.coords.longitude,
          ];
          let destination = window.localStorage.getItem(
            "currentlyAttendingEventDestinationCoords"
          );
          // Check to see if both the user location and destination location exsist
          if (userLocation && destination) {
            // Check to see if they are inside the geofence
            if (this.insideGeofence(userLocation, destination)) {
              // Inside geofence, they have arrived, send the same lat long as the destination so the ETA is 0
              this.setLocation(destination);
              this.endTrackGeolocation();
            }
          }
        },
        (error) => {
          console.log("[location] ERROR: ", error);
        }
      );

      // This funciton will try to wake the app when in background
      window.BackgroundGeolocation.onHeartbeat((event) => {
        console.log("[onHeartbeat] ", event);
      });

      // This is to ensure that the ready function of the plugin is only called once.
      this.setState({ initialised: true });
    }
  };

  /**
   * Deletes location from the backend and removes geolocation watch.
   */
  endTrackGeolocation = () => {
    let currentEventID = window.localStorage.getItem(
      "currentlyAttendingEventId"
    );
    if (currentEventID) {
      // Delete their last known location and stop tracking
      // Because they either are no longer attending or they have stopped giving SAS Location permission
      try {
        this.Requests.callAPI(
          this.Requests.deleteLastKnownLocation,
          currentEventID
        ).then(() => {
          if (window.cordova) {
            window.BackgroundGeolocation.stop();
          }
        });
      } catch {
        this.setLocation(null);
        if (window.cordova) {
          window.BackgroundGeolocation.stop();
        }
      }
    }
  };

  checkLocationPermission = (success, error) => {
    if (window.cordova && this.state.userInfo) {
      // NEED TO CHECK Permission for user's profile - from settings
      // Gets device operating system location permission
      window.BackgroundGeolocation.getProviderState((state) => {
        if (state.status === 0 || state.status === 3 || state.status === 4) {
          success();
        } else {
          if (error) {
            error();
          }
        }
      });
    }
  };

  // Calculates whether a given position is within a 200m radius of either the station or the event (current destination)
  insideGeofence = (position, destination) => {
    if (position !== null && destination !== null) {
      let lat1 = position[0];
      let lon1 = position[1];
      let lat2 = destination[0];
      let lon2 = destination[1];

      let toRadian = (angle) => (Math.PI / 180) * angle;
      let distance = (a, b) => (Math.PI / 180) * (a - b);
      let RADIUS_OF_EARTH_IN_KM = 6371;

      let dLat = distance(lat2, lat1);
      let dLon = distance(lon2, lon1);

      lat1 = toRadian(lat1);
      lat2 = toRadian(lat2);

      // Haversine Formula
      let a =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
      let c = 2 * Math.asin(Math.sqrt(a));

      let finalDistance = RADIUS_OF_EARTH_IN_KM * c * 1000; // This will return in meters
      return finalDistance <= 200 ? true : false;
    } else {
      return false;
    }
  };

  /**
   * This is a mobile app only function that's run when the Routes component updates.
   * This function will recieve the FCM token as a param and check the user's device id in the mobile app.
   * It will then send it to the SAS backend and save it for the user - identified throught the access token.
   * @param fcmToken - This token is available in the local storage
   */
  sendPushNotificationsToken = (fcmToken) => {
    if (window.cordova) {
      if (window.device.uuid !== undefined && fcmToken !== null) {
        let deviceId = window.device.uuid;
        let tokenBody = {
          token: fcmToken,
        };
        this.Requests.callAPI(
          this.Requests.sendFirebaseToken,
          deviceId,
          tokenBody
        ).then((data) => {
          if (data && data.status && data.status === 200) {
            window.localStorage.setItem("updateFCMToken", "false");
          } else if (
            data &&
            data.status &&
            (data.status === 401 || data.status === 402)
          ) {
            window.localStorage.setItem("updateFCMToken", "false");
            window.localStorage.removeItem("rolesNotValid");
            // Reset to false, since accessToken is invalid
            window.localStorage.setItem("termsOfUseAccepted", "false");
            this.setState(
              {
                userInformation: false,
                userInfo: false,
                userAgencies: false,
                pagingGroupsPartOf: false,
                pagingGroupsOwned: false,
                accessToken: false,
              },
              () => {
                this.props.history.push({
                  pathname: "/login",
                });
              }
            );
          } else {
            window.localStorage.setItem("updateFCMToken", "true");
          }
        });
      }
    }
  };

  /**
   * This is a mobile app only function that's run on component did mount.
   * It will check for push notification permissions if the user hasn't provided an fcmtoken on app load
   * It will also set the notifications channel as global.
   */
  initPushNotifications() {
    if (window.cordova) {
      let fcmToken = window.localStorage.getItem("fcmToken");
      if (fcmToken === null || fcmToken === "null") {
        this.checkPermission(false);
      } else if (fcmToken !== null || fcmToken !== "null") {
        this.checkPermission(true);
      }
    }
  }

  /**
   * This is a mobile app only function that's run on the first time the app loads to check for permissions for push notifications.
   * It will check for push notification permissions if the user hasn't provided an fcmtoken on app load
   * 
   * NOTE: the following code must replace the hasPermission and grantPermission functions in FirebasePlugin.m in Xcode before an iOS build or deployment for critical alerts to function:

// -(void)_hasPermission:(void (^)(BOOL result))completeBlock {
//     @try {
//         [[UNUserNotificationCenter currentNotificationCenter] getNotificationSettingsWithCompletionHandler:^(UNNotificationSettings * _Nonnull settings) {
//             @try {
//                 BOOL enabled = NO;
//                 if (@available(iOS 12.0, *)) {
//                     if (settings.alertSetting == UNNotificationSettingEnabled && settings.criticalAlertSetting == UNNotificationSettingEnabled) {
//                         enabled = YES;
//                         [self registerForRemoteNotifications];
//                     }
//                 } else {
//                     if (settings.alertSetting == UNNotificationSettingEnabled) {
//                         enabled = YES;
//                         [self registerForRemoteNotifications];
//                     }
//                 }
//                 NSLog(@"_hasPermission: %@", enabled ? @"YES" : @"NO");
//                 completeBlock(enabled);
//             }@catch (NSException *exception) {
//                 [self handlePluginExceptionWithoutContext:exception];
//             }
//         }];
//     }@catch (NSException *exception) {
//         [self handlePluginExceptionWithoutContext:exception];
//     }
// }

// - (void)grantPermission:(CDVInvokedUrlCommand *)command {
//     NSLog(@"grantPermission");
//     @try {
//         [self _hasPermission:^(BOOL enabled) {
//             @try {
//                 if(enabled){
//                     NSString* message = @"Permission is already granted - call hasPermission() to check before calling grantPermission()";
//                     CDVPluginResult *pluginResult = [CDVPluginResult resultWithStatus:CDVCommandStatus_ERROR messageAsString:message];
//                     [self.commandDelegate sendPluginResult:pluginResult callbackId:command.callbackId];
//                 }else{
//                     [UNUserNotificationCenter currentNotificationCenter].delegate = (id<UNUserNotificationCenterDelegate> _Nullable) self;
//                     if (@available(iOS 12.0, *)) {
//                         UNAuthorizationOptions authOptions = UNAuthorizationOptionAlert|UNAuthorizationOptionSound|UNAuthorizationOptionBadge|UNAuthorizationOptionCriticalAlert;
//                         [[UNUserNotificationCenter currentNotificationCenter]
//                          requestAuthorizationWithOptions:authOptions
//                          completionHandler:^(BOOL granted, NSError * _Nullable error) {
//                             @try {
//                                 NSLog(@"requestAuthorizationWithOptions: granted=%@", granted ? @"YES" : @"NO");
//                                 if (error == nil && granted) {
//                                     [self registerForRemoteNotifications];
//                                 }
//                                 [self handleBoolResultWithPotentialError:error command:command result:granted];
                                
//                             }@catch (NSException *exception) {
//                                 [self handlePluginExceptionWithContext:exception :command];
//                             }
//                         }
//                          ];
//                     } else {
//                         // Fallback for earlier versions
//                         UNAuthorizationOptions authOptions = UNAuthorizationOptionAlert|UNAuthorizationOptionSound|UNAuthorizationOptionBadge;
//                         [[UNUserNotificationCenter currentNotificationCenter]
//                          requestAuthorizationWithOptions:authOptions
//                          completionHandler:^(BOOL granted, NSError * _Nullable error) {
//                             @try {
//                                 NSLog(@"requestAuthorizationWithOptions: granted=%@", granted ? @"YES" : @"NO");
//                                 if (error == nil && granted) {
//                                     [self registerForRemoteNotifications];
//                                 }
//                                 [self handleBoolResultWithPotentialError:error command:command result:granted];
                                
//                             }@catch (NSException *exception) {
//                                 [self handlePluginExceptionWithContext:exception :command];
//                             }
//                         }
//                          ];
//                     }
//                 }
//             }@catch (NSException *exception) {
//                 [self handlePluginExceptionWithContext:exception :command];
//             }
//         }];
//     }@catch (NSException *exception) {
//         [self handlePluginExceptionWithContext:exception :command];
//     }
// }

   */
  async checkPermission(firstTime) {
    const self = this;
    if (!firstTime) {
      window.FirebasePlugin.hasPermission(
        function (hasPermission) {
          if (!hasPermission) {
            window.FirebasePlugin.grantPermission(
              function (hasPermissionNow) {
                if (hasPermissionNow) {
                  window.FirebasePlugin.getToken(
                    function (fcmToken) {
                      window.localStorage.setItem("fcmToken", fcmToken);
                      window.localStorage.setItem("updateFCMToken", "true");
                      self.sendPushNotificationsToken(fcmToken);
                    },
                    function (error) {
                      console.error(error);
                      return "error";
                    }
                  );
                }
              },
              function (error) {
                console.error(error);
                return "fcm permission error";
              }
            );
          } else {
            window.FirebasePlugin.getToken(
              function (fcmToken) {
                window.localStorage.setItem("fcmToken", fcmToken);
                window.localStorage.setItem("updateFCMToken", "true");
              },
              function (error) {
                console.error(error);
                return "fcm getting token error";
              }
            );
            self.setupPushNotifications();
          }
        },
        function (error) {
          console.error(error);
          return "fcm grant permission error";
        }
      );
    } else {
      window.FirebasePlugin.hasPermission(
        function (hasPermission) {
          if (!hasPermission) {
            window.FirebasePlugin.grantPermission(
              function (hasPermissionNow) {
                if (hasPermissionNow) {
                  self.setupPushNotifications();
                }
              },
              function (error) {
                console.error(error);
                return "fcm permission error";
              }
            );
          } else {
            window.FirebasePlugin.getToken(
              function (fcmToken) {
                window.localStorage.setItem("fcmToken", fcmToken);
                window.localStorage.setItem("updateFCMToken", "true");
              },
              function (error) {
                console.error(error);
                return "fcm getting token error";
              }
            );
          }
        },
        function (error) {
          console.error(error);
          return "fcm grant permission error";
        }
      );
    }
  }

  setupPushNotifications = () => {
    // This should only setup the handlers for the mobile app.
    if (window.FirebasePlugin !== undefined) {
      //Register handlers
      window.FirebasePlugin.onTokenRefresh(
        function (token) {
          window.localStorage.setItem("fcmToken", token);
          window.localStorage.setItem("updateFCMToken", "true");
        },
        function (error) {
          console.log("Failed to refresh fcm token: " + error);
        }
      );

      // Define custom  channel - all keys are except 'id' are optional.
      let defaultChannel = {
        // channel ID - must be unique per app package
        id: "channel_default",

        // Channel description. Default: empty string
        description:
          "This is the default notification preference with white lights, vibration and SAS Alert Tone 1 as the sound.",

        // Channel name. Default: empty string
        name: "Default Channel",

        //The sound to play once a push comes. Default value: 'default'
        //Values allowed:
        //'default' - plays the default notification sound
        //'ringtone' - plays the currently set ringtone
        //'false' - silent; don't play any sound
        //filename - the filename of the sound file located in '/res/raw' without file extension (mysound.mp3 -> mysound)
        sound: "sas_alert_tone_1",

        //Vibrate on new notification. Default value: true
        //Possible values:
        //Boolean - vibrate or not
        //Array - vibration pattern - e.g. [0, 500, 200, 500] - delay, milliseconds vibrate, milliseconds pause, vibrate, pause, etc.
        vibration: [0, 750, 250, 750, 250, 750, 1000, 750, 250, 750, 250, 750],

        // Whether to blink the LED
        light: true,

        //LED color in ARGB format - this example BLUE color. If set to -1, light color will be default. Default value: -1.
        lightColor: "ff0000",

        //Importance - integer from 0 to 4. Default value: 4
        //0 - none - no sound, does not show in the shade
        //1 - min - no sound, only shows in the shade, below the fold
        //2 - low - no sound, shows in the shade, and potentially in the status bar
        //3 - default - shows everywhere, makes noise, but does not visually intrude
        //4 - high - shows everywhere, makes noise and peeks
        importance: 4,

        //Show badge over app icon when non handled pushes are present. Default value: true
        badge: true,

        //Show message on locked screen. Default value: 1
        //Possible values (default 1):
        //-1 - secret - Do not reveal any part of the notification on a secure lockscreen.
        //0 - private - Show the notification on all lockscreens, but conceal sensitive or private information on secure lockscreens.
        //1 - public - Show the notification in its entirety on all lockscreens.
        visibility: 1,
      };

      // Defining the custom default chat notification channel
      let defaultChatChannel = {
        // channel ID - must be unique per app package
        id: "channel_chat",

        // Channel description. Default: empty string
        description:
          "This is the default chat notification preference with white lights, vibration and SAS Chat Tone 1 as the sound.",

        // Channel name. Default: empty string
        name: "Default Chat Channel",

        //The sound to play once a push comes. Default value: 'default'
        //Values allowed:
        //'default' - plays the default notification sound
        //'ringtone' - plays the currently set ringtone
        //'false' - silent; don't play any sound
        //filename - the filename of the sound file located in '/res/raw' without file extension (mysound.mp3 -> mysound)
        sound: "sas_chat_tone_1",

        //Vibrate on new notification. Default value: true
        //Possible values:
        //Boolean - vibrate or not
        //Array - vibration pattern - e.g. [0, 500, 200, 500] - delay, milliseconds vibrate, milliseconds pause, vibrate, pause, etc.
        vibration: [0, 750, 250, 750, 250, 750, 1000, 750, 250, 750, 250, 750],

        // Whether to blink the LED
        light: true,

        //LED color in ARGB format - this example BLUE color. If set to -1, light color will be default. Default value: -1.
        lightColor: "ffffff",

        //Importance - integer from 0 to 4. Default value: 4
        //0 - none - no sound, does not show in the shade
        //1 - min - no sound, only shows in the shade, below the fold
        //2 - low - no sound, shows in the shade, and potentially in the status bar
        //3 - default - shows everywhere, makes noise, but does not visually intrude
        //4 - high - shows everywhere, makes noise and peeks
        importance: 4,

        //Show badge over app icon when non handled pushes are present. Default value: true
        badge: true,

        //Show message on locked screen. Default value: 1
        //Possible values (default 1):
        //-1 - secret - Do not reveal any part of the notification on a secure lockscreen.
        //0 - private - Show the notification on all lockscreens, but conceal sensitive or private information on secure lockscreens.
        //1 - public - Show the notification in its entirety on all lockscreens.
        visibility: 1,
      };

      // Create the channel
      window.FirebasePlugin.setDefaultChannel(
        defaultChannel,
        function () {
          // console.log("Default channel created: " + defaultChannel.id);
        },
        function (error) {
          // console.log("Default create channel error: " + error);
        }
      );

      // Create the chat default channel
      window.FirebasePlugin.createChannel(
        defaultChatChannel,
        function () {
          // console.log("Chat Channel created: " + defaultChatChannel.id);
        },
        function (error) {
          // console.log("Create chat channel error: " + error);
        }
      );
    }
  };

  setupSmartlook = () => {
    if (window.cordova) {
      /*window.Smartlook.setupAndStartRecording({
        smartlookAPIKey: "f849b01b58f59939b005ddda1b652cdc83665e71",
      });*/
      window.Smartlook.setProjectKey({ key: "f849b01b58f59939b005ddda1b652cdc83665e71" });
      window.Smartlook.start();
    } else {
      smartlookClient.init("1d0f6d2ca6840dde6476f5a746b632708224dcfe");
    }
    this.setSmartlookUser();
  };

  setSmartlookUser = () => {
    if (
      process.env.REACT_APP_ROOT_DEV ===
        "https://web.uat.emvsas.dxau.digital/" &&
      this.state.userInformation
    ) {
      if (window.cordova) {
        window.Smartlook.setUserIdentifier({
          identifier: this.state.userInformation?.sub,
          sessionProperties: {
            name: this.state.userInformation?.name,
            email: this.state.userInformation?.email,
          },
        });
      } else {
        smartlookClient.identify(this.state.userInformation?.sub, {
          name: this.state.userInformation?.name,
          email: this.state.userInformation?.email,
        });
      }
    }
  };

  /**
   * This function remakes API call(s) which have failed
   * Before remaking the API call, the state variable is reset to null to trigger the page loader
   */
  callAPIAgain = () => {
    window.location.reload(false);
  };

  /**
   * This is a higher order component that is designed restrict user access to certain routes
   * depending on whether they are logged in and what roles they have
   * @param {*} ComposedComponent - The component to return if the user has the required permission
   * @param {Boolean} PagerOwnerAllowed - If true, a user is allowed access to a page regardless of their role provided they are a paging group owner
   * @param {Array} AgenciesAllowed - Agencies which have full access to a tab regardless of the permissions (value of null means that permissions apply)
   */
  allowedAccess(
    ComposedComponent,
    allowedUsers,
    PagerOwnerAllowed,
    AgenciesAllowed
  ) {
    // If the user is not logged in, redirect them to the login page
    if (this.state.accessToken === false) {
      return class AuthenticatedRoute extends React.Component {
        render() {
          return (
            <Redirect
              to={{
                pathname: "/login",
                allowLogin: false,
                // state: { errorMessage: "Login is required before continuing" }
              }}
            />
          );
        }
      };
    } else {
      // If the user is logged in, and have not accepted terms of use
      if (window.localStorage.getItem("termsOfUseAccepted") === "false") {
        return class AuthenticatedRoute extends React.Component {
          render() {
            return (
              <Redirect
                to={{
                  pathname: "/terms-of-use",
                }}
              />
            );
          }
        };
      } else {
        // Get the user's role and agencies
        let userRoles = this.getUserRoles();
        // If the user does not have any roles, redirect them to the login page
        if (userRoles === false) {
          return class AuthenticatedRoute extends React.Component {
            render() {
              return (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: {
                      allowLogin: false,
                      errorMessage: copy.login.userNoRoles,
                    },
                  }}
                />
              );
            }
          };
        } else {
          let accessAllowed = this.checkUserPermissions(
            userRoles,
            allowedUsers,
            PagerOwnerAllowed,
            AgenciesAllowed
          );
          // If the user is allowed access to the page, direct them to the page
          if (accessAllowed) {
            if (this.state.userInfo && this.state.userInfo.userId) {
              window.localStorage.setItem("userId", this.state.userInfo.userId);
            }
            let userAgencies = this.state.userAgencies;
            let userInfo = this.state.userInfo;
            let userInformation = this.state.userInformation;
            let userRoleAgency;
            if (typeof this.state.userInformation.role !== "string") {
              userRoleAgency = this.state.userInformation.role;
            } else {
              userRoleAgency = [this.state.userInformation.role];
            }
            let pagingGroupsPartOf = this.state.pagingGroupsPartOf;
            let pagingGroupsOwned = this.state.pagingGroupsOwned;
            let userPagingGroupsWithAvailability =
              this.state.userPagingGroupsWithAvailability;
            let authorisedPagingGroups = this.state.authorisedPagingGroups;
            return class AuthenticatedRoute extends React.Component {
              render() {
                return (
                  <ComposedComponent
                    {...this.props}
                    userRoles={userRoles}
                    userAgencies={userAgencies}
                    userRoleAgency={userRoleAgency}
                    userInfo={userInfo}
                    userInformation={userInformation}
                    pagingGroupsPartOf={pagingGroupsPartOf}
                    pagingGroupsOwned={pagingGroupsOwned}
                    userPagingGroupsWithAvailability={
                      userPagingGroupsWithAvailability
                    }
                    authorisedPagingGroups={authorisedPagingGroups}
                  />
                );
              }
            };
          } else {
            // Redirect the user to the error page if they do not have permission to access a page
            return class AuthenticatedRoute extends React.Component {
              render() {
                return (
                  <Redirect
                    to={{
                      pathname: "*",
                      state: {
                        errorMessage: copy.login.userNoPermission,
                        userRoles: userRoles,
                      },
                    }}
                  />
                );
              }
            };
          }
        }
      }
    }
  }

  /**
   * This function returns the roles that the user has as a list
   * If they have no roles, false is returned
   */
  getUserRoles() {
    let validRoles = [
      "SuperUser",
      "User_CFA",
      "User_AV",
      "User_SES",
      "Manager_CFA",
      "Manager_AV",
      "Manager_SES",
      "Administrator_CFA",
      "Administrator_AV",
      "Administrator_SES",
    ];

    if (!(this.state.userInformation && this.state.userInformation.role)) {
      // User has no roles
      return false;
    } else {
      let userRoles;
      // If user only has one role, the role will be a string
      // If user has multiple roles, it will be a list
      if (typeof this.state.userInformation.role !== "string") {
        // Filter out the invalid roles from the user's list of roles
        userRoles = this.state.userInformation.role.filter((role) => {
          return validRoles.indexOf(role) !== -1;
        });
        // Check if the user has any valid roles
        if (userRoles.length > 0) {
          userRoles = userRoles.map((value) => {
            return value.split("_")[0];
          });
          window.localStorage.setItem("userRoles", userRoles);
        } else {
          userRoles = false;
        }
      } else {
        // Check if the user's role is valid
        if (validRoles.indexOf(this.state.userInformation.role) !== -1) {
          userRoles = [this.state.userInformation.role.split("_")[0]];
          window.localStorage.setItem("userRoles", userRoles);
        } else {
          userRoles = false;
        }
      }
      return userRoles;
    }
  }

  /**
   * This function checks whether a user has permission to access a page
   * If the roles they have are found in the list of allowed roles ('allowedRoles'), then they will be granted access
   * They will also be granted access if they are part of an agency that is given full permission
   */
  checkUserPermissions(
    userRoles,
    allowedRoles,
    PagerOwnerAllowed,
    AgenciesAllowed
  ) {
    if (
      // Checking if the user is part of an agency that has full access to a page
      (AgenciesAllowed &&
        this.state.userAgencies &&
        this.state.userAgencies.some(
          (agency) => AgenciesAllowed.indexOf(agency) !== -1
        )) ||
      // Checking if the user has permission to access a page
      userRoles.some((role) => allowedRoles.indexOf(role) !== -1)
    ) {
      return true;
    } else {
      if (PagerOwnerAllowed) {
        return this.isPagingGroupOwner();
      } else {
        return false;
      }
    }
  }

  /**
   * This function checks whether the user is a paging group owner
   */
  isPagingGroupOwner() {
    let isPagingGroupOwner = false;
    if (this.state.pagingGroupsOwned) {
      let pagingGroupKeys = Object.keys(this.state.pagingGroupsOwned);

      for (let i = 0; i < pagingGroupKeys.length; i++) {
        if (this.state.pagingGroupsOwned[pagingGroupKeys[i]].length > 0) {
          isPagingGroupOwner = true;
        }
      }
    }
    return isPagingGroupOwner;
  }

  render() {
    // check if agencies and groups need updating
    let updateAgencyAndGroups = window.localStorage.getItem(
      "updateAgencyAndGroups"
    );
    if (updateAgencyAndGroups !== null) {
      this.getUserAgencies();
      window.localStorage.removeItem("updateAgencyAndGroups");
    }

    // Check if its opening up the event page
    let isAccessingEventPage = !!matchPath(
      this.props.location.pathname,
      "/eventdetails"
    );

    // Need to wait for the API calls to return before rendering everything
    // If state variable === null, this means that an API call is still in progress
    if (
      (this.state.accessToken === null ||
        this.state.userInformation === null ||
        this.state.userInfo === null ||
        this.state.userAgencies === null ||
        this.state.pagingGroupsOwned === null ||
        this.state.pagingGroupsPartOf === null ||
        window.localStorage.getItem("termsOfUseAccepted") === null) &&
      !isAccessingEventPage
    ) {
      if (this.state.initialAPIsCalled === true) {
        // The user is coming from the event padge on the mobile app and the initial apis were bypassed since they came from the push notification.
        // Need to reload the page to recall all the apis.
        window.location.reload(true);
      } else {
        // Page is loading
        return (
          <div className="loading_bar_wrapper">
            <Loader />
          </div>
        );
      }
    } else if (
      this.state.accessToken && // Make sure user is actually logged in first
      this.state.accessToken !== "null" && // Android can return accessToken as null
      // Check for errors in any of the API calls
      (this.state.userInformation === false ||
        this.state.userInfo === false ||
        this.state.userAgencies === false ||
        this.state.pagingGroupsOwned === false ||
        this.state.pagingGroupsPartOf === false) &&
      !isAccessingEventPage
    ) {
      return (
        <Wrapper>
          <div className="main-content-holder">
            <div className={styles.errorPage}>
              <h1>{copy.error_page.userInformationAPIFailure}</h1>
              <h1>{copy.error_page.refreshMessage}</h1>

              <div>
                <h3>{`(Error #${this.state.errorCode})`}</h3>
              </div>
              <div>
                <Button
                  label="Refresh"
                  content={copy.global.btnRefresh}
                  variant="btn_solid"
                  styles="greyBg"
                  icon="icon_refresh"
                  buttonClick={this.callAPIAgain}
                />
              </div>
              {/* <span className={styles.tryAgain} onClick={this.callAPIAgain}>
                {copy.error_page.tryAgain}
              </span> */}
            </div>
          </div>
        </Wrapper>
      );
    } else {
      let userRoles = this.getUserRoles();
      let userRoleAgency;
      if (this.state.userInformation) {
        if (typeof this.state.userInformation.role !== "string") {
          userRoleAgency = this.state.userInformation.role;
        } else {
          userRoleAgency = [this.state.userInformation.role];
        }
      } else if (window.localStorage.getItem("userRoleAgency") !== null) {
        userRoleAgency = JSON.parse(
          window.localStorage.getItem("userRoleAgency")
        );
      }
      const isHomepage = !!matchPath(this.props.location.pathname, "/home");
      const isChat = !!matchPath(this.props.location.pathname, "/chat");
      const isSettings = !!matchPath(this.props.location.pathname, "/settings");
      const isAvailability = !!matchPath(
        this.props.location.pathname,
        "/availability"
      );
      const isDashboard = !!matchPath(
        this.props.location.pathname,
        "/dashboard"
      );
      const isTurnout = !!matchPath(this.props.location.pathname, "/turnout");
      const isAdmin = !!matchPath(this.props.location.pathname, "/admin");
      const isPagerHistory = !!matchPath(
        this.props.location.pathname,
        "/pagerhistory"
      );
      const isNotification = !!matchPath(
        this.props.location.pathname,
        "/notifications"
      );
      const isEventDetails = !!matchPath(
        this.props.location.pathname,
        "/eventdetails"
      );

      let showNavigationBar =
        isHomepage ||
        isChat ||
        isSettings ||
        isAvailability ||
        isDashboard ||
        isAdmin ||
        isPagerHistory ||
        isEventDetails ||
        isNotification ||
        isTurnout;

      return (
        <Wrapper>
          {/* An error banner that is for internet connectivity only */}
          <ErrorBanner
            internetConnectivity
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          {window.cordova && (
            <AlertBanner accessToken={this.state.accessToken} />
          )}
          {showNavigationBar && (
            <Navigation
              userRoles={userRoles}
              isPagingGroupOwner={this.isPagingGroupOwner()}
              userAgencies={this.state.userAgencies}
            />
          )}
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login" component={Login} />
            {/* The below route is for the Maintaince Page */}
            {/* Comment out the line <Route exact path="/login" component={Login} /> and uncomment the section below to show the maintainance page.  */}
            {/* The maintaince page can be bypassed by going to the route /allowAccess */}
            {/* Maintaince Page Section START */}

            {/* <Route exact path="/login" component={SASUpdateInProgress} />
            <Route exact path="/allowAccess" component={Login} /> */}

            {/* Maintaince Page Section END */}
            <Route exact path="/signin-callback" component={Login} />
            <Route exact path="/force-update" component={ForceUpdate} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route
              exact
              path="/location-disclosure"
              component={LocationDisclosure}
            />
            <Route
              exact
              path="/android-auto"
              component={AndroidAutoDisclosure}
            />
            <Route
              exact
              path="/ASN"
              component={this.allowedAccess(
                ASN,
                ["SuperUser", "Administrator", "Manager", "User"],
                false,
                null
              )}
            />
            <Route
              exact
              path="/home"
              component={this.allowedAccess(
                Home,
                ["SuperUser", "Administrator", "Manager", "User"],
                false,
                null
              )}
            />
            {/* <Route
              exact
              path="/eventdetails/:id"
              component={this.allowedAccess(
                EventOverviewView,
                ["SuperUser", "Administrator", "Manager", "User"],
                false,
                null
              )}
            /> */}
            <Route
              exact
              path="/eventdetails/:id"
              // component={EventOverviewView}
              render={(routeProps) => (
                <EventOverviewView
                  {...routeProps}
                  userInfo={this.state.userInfo}
                  userRoleAgency={userRoleAgency}
                  pagingGroupsOwned={this.state.pagingGroupsOwned}
                  pagingGroupsPartOf={this.state.pagingGroupsPartOf}
                  initialiseSASUserData={this.initialiseSASUserData}
                />
              )}
            />
            <Route
              exact
              path="/chat/chats"
              component={this.allowedAccess(
                SASChat,
                ["SuperUser", "Administrator", "Manager", "User"],
                false,
                null
              )}
            />
            <Route
              exact
              path="/chat/chats/:id"
              component={this.allowedAccess(
                SASChat,
                ["SuperUser", "Administrator", "Manager", "User"],
                false,
                null
              )}
            />
            <Route
              exact
              path="/settings/:tab"
              component={this.allowedAccess(
                Settings,
                ["SuperUser", "Administrator", "Manager", "User"],
                false,
                null
              )}
            />
            <Route
              exact
              path="/availability/:tab"
              component={this.allowedAccess(
                Availability,
                ["SuperUser", "Administrator", "Manager", "User"],
                true,
                ["AV"]
              )}
            />
            <Route
              exact
              path="/admin"
              component={this.allowedAccess(
                Admin,
                ["SuperUser", "Administrator", "Manager"],
                true,
                null
              )}
            />
            <Route
              exact
              path="/pagerhistory"
              component={this.allowedAccess(
                PagerHistoryView,
                ["SuperUser", "Administrator", "Manager", "User"],
                false,
                null
              )}
            />
            <Route
              exact
              path="/notifications"
              component={this.allowedAccess(
                Settings,
                ["SuperUser", "Administrator", "Manager", "User"],
                false,
                null
              )}
            />
            <Route
              exact
              path="/dashboard/report/download"
              component={this.allowedAccess(
                Download,
                ["SuperUser", "Administrator", "Manager", "User"],
                true,
                null
              )}
            />
            <Route
              exact
              path="/dashboard/report"
              component={this.allowedAccess(
                Report,
                ["SuperUser", "Administrator", "Manager", "User"],
                true,
                null
              )}
            />
            <Route
              exact
              path="/dashboard/events"
              component={this.allowedAccess(
                Dashboard,
                ["SuperUser", "Administrator", "Manager", "User"],
                true,
                null
              )}
            />
            <Route
              exact
              path="/turnout/events"
              component={this.allowedAccess(
                Turnout,
                ["SuperUser", "Administrator", "Manager", "User"],
                true,
                null
              )}
            />
            <Route
              exact
              path="/turnout/settings"
              component={this.allowedAccess(
                TurnoutSettingsView,
                ["SuperUser", "Administrator", "Manager", "User"],
                true,
                null
              )}
            />
            <Route path="*">
              <UnknownError />
            </Route>
          </Switch>
        </Wrapper>
      );
    }
  }
}
export default withRouter(Routes);
