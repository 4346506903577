import React from "react";

// eslint-disable-next-line no-unused-vars
import { scheduler } from "dhtmlx-scheduler";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import {
  format,
  addDays,
  differenceInDays,
  getDay,
  startOfWeek,
  endOfWeek,
  addMonths,
} from "date-fns";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import Popup from "../UI/Popup/Popup";
import copy from "../../assets/copy/copy.json";

import SchedulerModal from "./SchedulerModal";

import "@dmuy/toast/dist/mdtoast.css";
import mdtoast from "@dmuy/toast";
import { nanoid } from "nanoid";

export default class Scheduler extends React.Component {
  constructor(props) {
    super(props);

    this._days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    this.today = new Date();
    this.Requests = new Requests();

    this.state = {
      isMobile: (this.props.currentWidth || window.innerWidth) < 1024 ? true : false,
      isLoading: false,
      isEditEvent: false,
      dropdownOptions: [],
      allData: {},
      eventData: {},
      currentWeekDate: null,
      canAccessEmergencyLevels: false,
      deleteEvent: null,
      showDeleteControls: false,
      showSingleDeleteControls: false,
    };
  }
  setDaysOrWeeks = () => {
    const today = this.today;
      const weekStart = addDays(startOfWeek(today), 1);
      const weekEnd = addDays(endOfWeek(today), 1);

      let dayDatesOptions = [];
      let weekDatesOptions = [];
      let dateIn3Months = addMonths(today, 3);
      let daysIn3Months = differenceInDays(dateIn3Months, today);

      // * Get dates for the next 3 months
      for (let dayIndex = 0; dayIndex < daysIn3Months; dayIndex++) {
        let tomorrow = addDays(today, 1 * dayIndex);

        let day = this._days[getDay(tomorrow)];
        let date = format(tomorrow, "dd");
        let month = format(tomorrow, "MM");
        let year = format(tomorrow, "yyyy");

        dayDatesOptions.push({
          key: dayIndex,
          text: `${day}, ${date}/${month}/${year}`,
          value: `${date}/${month}/${year}`,
        });
      }

      // * Get weeks for the next 3 months
      for (let weekIndex = 0; weekIndex < 13; weekIndex++) {
        const oneWeekLater = weekIndex * 7;
        const dateOfWeekStart = format(
          addDays(weekStart, oneWeekLater),
          "dd/MM/yyyy"
        );

        weekDatesOptions.push({
          key: weekIndex,
          text: `${dateOfWeekStart} - ${format(
            addDays(weekEnd, oneWeekLater),
            "dd/MM/yyyy"
          )}`,
          value: dateOfWeekStart,
        });
      }

      this.setState({
        dropdownOptions: this.state.isMobile ? dayDatesOptions : weekDatesOptions,
        isLoading: true,
        currentWeekDate: this.state.isMobile
          ? moment().startOf("day").toDate()
          : addDays(startOfWeek(today), 1),
      });
      // * Get the availability for the next 7 days
    this.getAvailability(today);
  }
  componentDidMount = () => {
    this.checkEmergencyLevelsAccess();
    this._scheduler = window.scheduler;
    // moved the doce to this function, which can be shared with componentDidUpdate
    this.setDaysOrWeeks();    
    // Scroll calendar
    this.scrollCalendarToNow();
  };

  componentDidUpdate = (prevProps) => {
    this.scrollCalendarToNow();
    if (this.props.currentWidth !== prevProps.currentWidth) {
      // The width prop has changed, so you can perform some action here
      this.setState({ isMobile: this.props.currentWidth < 1024 }, () => {
        this.setDaysOrWeeks();
      });
    }
  };

  scrollCalendarToNow = () => {
    // This scrolls the calendar to the current time
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        const calendarContainer = document.querySelectorAll(".dhx_cal_data")[0];
        const calendarColumn = this.state.isMobile
          ? document.querySelectorAll(".dhx_scale_holder_now")[0]
          : document.querySelectorAll(".dhx_scale_holder")[0];
        if (calendarContainer && calendarColumn) {
          const hours = new Date().getHours();
          calendarContainer.scrollTop =
            (hours * calendarColumn.offsetHeight) / 24;
        }
      });
    });
  };

  checkEmergencyLevelsAccess() {
    if (this.props.userAgencies.indexOf("SES") !== -1) {
      this.setState({
        canAccessEmergencyLevels: true,
      });
    }
  }

  /**
   * Displays a toaster on the lower left of the screen
   * @param {String} message Message to be displayed in the toaster
   * @param {String} type Type of toaster (info, error, warning, success)
   */
  toastie = (message = "Hello world!", type = "info") => {
    mdtoast(message, {
      duration: 5000,
      type: type,
      interaction: false,
    });
  };

  getBrowserClient() {
    // * gets the browser name as the device name
    let Sys = {};
    let ua = navigator.userAgent.toLowerCase();
    var s = ua.match(/msie ([\d.]+)/)
      ? // eslint-disable-next-line no-use-before-define
        (Sys.ie = s[1])
      : // eslint-disable-next-line
      (s = ua.match(/firefox\/([\d.]+)/))
      ? // eslint-disable-next-line no-use-before-define
        (Sys.firefox = s[1])
      : // eslint-disable-next-line
      (s = ua.match(/chrome\/([\d.]+)/))
      ? // eslint-disable-next-line no-use-before-define
        (Sys.chrome = s[1])
      : // eslint-disable-next-line
      (s = ua.match(/opera.([\d.]+)/))
      ? // eslint-disable-next-line no-use-before-define
        (Sys.opera = s[1])
      : // eslint-disable-next-line
      (s = ua.match(/version\/([\d.]+).*safari/))
      ? // eslint-disable-next-line no-use-before-define
        (Sys.safari = s[1])
      : 0;
    let browserVersion = null;
    if (Sys.ie) {
      browserVersion = "IE";
    } else if (Sys.firefox) {
      browserVersion = "Firefox";
    } else if (Sys.chrome) {
      browserVersion = "Chrome";
    } else if (Sys.opera) {
      browserVersion = "Opera";
    } else if (Sys.safari) {
      browserVersion = "Safari";
    }
    return browserVersion;
  }

  gotoDateInCalendar(date) {
    // * Must convert the date into a Date Object so it can be parsed
    // * properly in getAvailability();
    const month = date.substring(0, 2);
    const day = date.substring(3, 5);
    const year = date.substring(6, 10);
    const _date = new Date(`${day}/${month}/${year}`);

    this.setState(
      {
        currentWeekDate: _date,
      },
      () => {
        this.getAvailability(_date);
      }
    );
  }

  getAvailability(today) {
    const day7 = addDays(today, 6);
    const dates = {
      dateFrom: format(today, "yyyy-MM-dd"),
      dateTo: format(day7, "yyyy-MM-dd"),
    };

    this.Requests.callAPI(this.Requests.getUsersAvailability, dates).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          this.setState({
            isLoading: false,
          });

          const schedulerTimer = setTimeout(() => {
            if (document.querySelectorAll("#scheduler").length) {
              this.initScheduler(data.data, today);
              clearTimeout(schedulerTimer);
            }
          }, 500);
        }
      }
    );
  }

  initScheduler(data, today) {
    const self = this;
    const calendarView = this.state.isMobile ? "day" : "week";
    const _responseData = data;

    self.setState({
      allData: data,
    });

    // * Scheduler Plugin Configurations
    const scheduler = this._scheduler;
    scheduler.xy.nav_height = 60;
    scheduler.config.time_step = 30;
    scheduler.config.hour_size_px = 84;
    scheduler.config.start_on_monday = true;
    scheduler.xy.menu_width = 0;
    scheduler.config.day_date = "%l %j/%n";
    scheduler.config.mark_now = true;
    scheduler.config.readonly = false;
    scheduler.config.multi_day = false;
    scheduler.config.details_on_dblclick = true;
    scheduler.config.details_on_create = true;

    scheduler.showLightbox = function (id) {
      const event = scheduler.getEvent(id);
      const modal = document.querySelectorAll(".schedulerModal")[0];

      if (event === undefined) {
        return false;
      }

      self.setState({
        eventData: { ...event },
      });

      scheduler.startLightbox(id, modal);
      // modal.style.top = "20px";
      // modal.style.left = "20px";
    };

    scheduler.attachEvent("onDragEnd", function (id, mode, event) {
      const _event = scheduler.getEvent(id);

      if (_event === undefined) {
        scheduler.deleteEvent(id);
        return false;
      }

      return true;
    });

    scheduler.attachEvent("onDblClick", function (id, event) {
      self.setState({
        isEditEvent: true,
      });

      return true;
    });

    scheduler.attachEvent("onBeforeDrag", function (id, mode, e) {
      if (mode === "move" || mode === "resize") {
        return false;
      } else {
        return true;
      }
    });

    scheduler.attachEvent("onBeforeEventCreated", function (e) {
      // disable creating new event via dragging if the first touchdown is on an existing event
      // since there's an issue where users cannot actually edit the new event, and it creates a
      // default 'new event' that cannot be deleted
      if (e.target.className === "dhx_body") {
        return false;
      }
      return true;
    });

    // * Clear off event header
    scheduler.templates.event_header = () => {
      return "";
    };

    // * Assign proper class styling on events
    scheduler.templates.event_class = function (start, end, event) {
      let _cssClass = "";

      // * Assign proper class for event type
      if (event.eventType !== undefined) {
        if (event.eventType.indexOf("Emergency") > -1) {
          _cssClass += "-emergency ";
        }

        if (event.eventType.indexOf("Non-emergency") > -1) {
          _cssClass += "-non-emergency ";
        }

        if (event.eventType.indexOf("Admin") > -1) {
          _cssClass += "-admin ";
        }

        if (event.eventType.indexOf("Unavailable") > -1 || !event.available) {
          _cssClass += "-unavailable ";
        }
      }

      return _cssClass.trim();
    };

    // * Customise look for event box
    scheduler.renderEvent = function (container, event) {
      const startDate = new Date(event.start_date);
      const endDate = new Date(event.end_date);
      const diffTime = endDate - startDate;
      const diffHours = diffTime / (1000 * 60 * 60);
      const boxHeight = 84;

      const eventHeight = diffHours * boxHeight;

      // * Creates the fancy ribbons on top of the event columns
      let _eventType = "";
      let _eventTypeCount = 0;
      if (event.eventType !== undefined && event.eventType !== null) {
        event.eventType.forEach((event) => {
          _eventType += `<span class="event__ribbon -${event.toLowerCase()}"></span>`;
          _eventTypeCount += 1;
        });
      }

      // * Creates the fancy triangles below the fancy ribbons
      let _locationType = "";
      // eslint-disable-next-line no-unused-vars
      let _locationTypeCount = 0;
      if (
        event.availabilityTypes !== undefined &&
        event.availabilityTypes !== null
      ) {
        event.availabilityTypes.forEach((availabilityType) => {
          _eventType += `<span class="event__triangle -${availabilityType.toLowerCase()}"></span>`;
          _locationTypeCount += 1;
        });
      }

      // ${_eventType + _locationType }
      let _html = `<div class="dhx_event_move dhx_header"></div>
                            <div class="dhx_event_move dhx_title">${
                              _eventType + _locationType
                            }</div>
                            <div class="dhx_body" style="height: ${eventHeight}px; padding-top: ${
        _eventTypeCount * 4 + (_eventTypeCount > 0 ? 7 : 8) + 8
      }px;">${event.text}</div>
                            <div class="dhx_event_resize dhx_footer"></div>`;

      // * Passes it into the DOM
      container.innerHTML = _html;

      // * required, true - to display a custom form, false - the default form
      return true;
    };

    // * Initialises (or goes to the week selected)
    scheduler.init("scheduler", today, calendarView);

    // * Grab the response and display the availability on the calendar
    let _data = [];
    let eventStartDates = [];

    // * Populate dates available
    _responseData.dateAvailable.forEach((availability) => {
      const _timeAvailable = availability.timeAvailable;

      // * Goes through all time availability.  Can have more than 1
      _timeAvailable.forEach((timeAvailable) => {
        // * Gets the first time stamp of an event
        let isEventRecorded = false;
        eventStartDates.forEach((eventStartDate) => {
          if (eventStartDate.eventID === timeAvailable.availableId) {
            isEventRecorded = true;
          }
        });

        if (!isEventRecorded) {
          eventStartDates.push({
            eventID: timeAvailable.availableId,
            eventStartDate: availability.date,
          });
        }

        const timeStart = timeAvailable.timeStart.substring(0, 5);
        const timeEnd = timeAvailable.timeEnd.substring(0, 5);

        const startDate = new Date(availability.date);
        const formattedStartDate = format(startDate, "yyyy-MM-dd");

        const endDate =
          timeAvailable.repeat === null
            ? startDate
            : new Date(timeAvailable.repeat.dateEffectiveTo);
        const formattedEndDate = format(endDate, "yyyy-MM-dd");

        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        // * Determine how long is the effective availability (for non-recurring events)
        if (startDate.getTime() >= currentDate.getTime()) {
          _data.push({
            id: nanoid(),
            eventID: timeAvailable.availableId,
            text:
              timeAvailable.pagingName === null
                ? "All paging groups"
                : timeAvailable.pagingName,
            pagingName:
              timeAvailable.pagingName === null
                ? "All paging groups"
                : timeAvailable.pagingName.replace(/_/g, " "),
            pagingNumber: timeAvailable.pagingNumber,
            start_date: `${formattedStartDate} ${timeStart}`,
            end_date: `${formattedStartDate} ${timeEnd}`,
            effective_end_date:
              timeAvailable.repeat === null
                ? null
                : `${formattedEndDate} ${timeStart}`,
            dayOfWeek:
              timeAvailable.repeat === null
                ? []
                : timeAvailable.repeat.dayOfWeek,
            available: true,
            availabilityTypes: timeAvailable.availabilityTypes,
            eventType: timeAvailable.emergencyLevels,
            type: timeAvailable.type,
          });
        }
      });
    });

    // * Populate dates unavailable
    if (_responseData.dateUnavailable) {
      _responseData.dateUnavailable.forEach((unavailability) => {
        const _timeUnvailable = unavailability.timeUnavailable;

        // * Goes through all time unavailability.  Can have more than 1
        _timeUnvailable.forEach((timeUnavailable) => {
          const timeStart = timeUnavailable.timeStart.substring(0, 5);
          const timeEnd = timeUnavailable.timeEnd.substring(0, 5);

          const startDate = new Date(unavailability.date);
          const formattedStartDate = format(startDate, "yyyy-MM-dd");

          const endDate =
            timeUnavailable.repeat === null
              ? startDate
              : new Date(timeUnavailable.repeat.dateEffectiveTo);
          const formattedEndDate = format(endDate, "yyyy-MM-dd");

          let currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);

          // * Determine how long is the effective unavailability (for non-recurring events)
          if (startDate.getTime() >= currentDate.getTime()) {
            _data.push({
              id: nanoid(),
              date: unavailability.date,
              eventID: timeUnavailable.unavailableId,
              text:
                timeUnavailable.pagingName === null
                  ? "Unavailable"
                  : timeUnavailable.pagingName.replace(/_/g, " "),
              pagingName:
                timeUnavailable.pagingName === null
                  ? "All paging groups"
                  : timeUnavailable.pagingName,
              pagingNumber: timeUnavailable.pagingNumber,
              start_date: `${formattedStartDate} ${timeStart}`,
              end_date: `${formattedStartDate} ${timeEnd}`,
              effective_end_date:
                timeUnavailable.repeat === null
                  ? null
                  : `${formattedEndDate} ${timeStart}`,
              dayOfWeek:
                timeUnavailable.repeat === null
                  ? []
                  : timeUnavailable.repeat.dayOfWeek,
              available: false,
              availabilityTypes: timeUnavailable.unavailabilityTypes,
              eventType: ["Unavailable"],
              type: timeUnavailable.type,
            });
          }
        });
      });
    }

    // * Assign Event Start Dates to all existing events
    _data.forEach((dataElement) => {
      eventStartDates.forEach((eventStartDate) => {
        if (dataElement.eventID === eventStartDate.eventID) {
          dataElement.event_start_date = format(
            new Date(eventStartDate.eventStartDate),
            this.getBrowserClient() === "Safari" ? "dd-MM-yyyy" : "yyyy-MM-dd"
          );
        }
      });
    });

    // * Pass in the data
    scheduler.clearAll();
    scheduler.parse(_data);
  }

  onSaveEvent(event, details) {
    const API_ENDPOINT = event.available
      ? this.Requests.addAvailability
      : this.Requests.addUnavailability;

    this.Requests.callAPI(API_ENDPOINT, details).then((data) => {
      if (data && data.status && data.status === 200) {
        this.toastie("Data has been updated!", "success");

        this._scheduler.endLightbox(
          true,
          document.getElementById("schedulerModal")
        );

        this.getAvailability(this.state.currentWeekDate);

        this.setState({
          isEditEvent: false,
        });
      } else {
        if (data.data !== undefined) {
          this.toastie(
            `Oops! Something went wrong! (${data.data.HTTPStatusCode} - ${data.data.SASMessageClient})`,
            "error"
          );
        } else {
          this.toastie(
            `Oops! Something went wrong! (${data.status} - ${data.statusText})`,
            "error"
          );
        }
      }
    });
  }

  onSaveClick(savedEvent) {
    const scheduler = this._scheduler;

    // * Check if scheduler has initialised
    if (scheduler !== undefined) {
      let event = scheduler.getEvent(scheduler.getState().lightbox_id);
      event.text = savedEvent.available ? savedEvent.pagingName : "Unavailable";
      event.date = savedEvent.date;
      event.pagingName = savedEvent.pagingName;
      event.pagingNumber = savedEvent.pagingNumber;
      event.dayOfWeek = savedEvent.dayOfWeek;

      let dateFromDate = savedEvent.dateFrom.substring(0, 2);
      let dateFromMonth = savedEvent.dateFrom.substring(3, 5);
      let dateFromYear = savedEvent.dateFrom.substring(6, 10);
      let dateFromHour = savedEvent.timeFrom.substring(0, 2);
      let dateFromMinutes = savedEvent.timeFrom.substring(3, 5);
      let dateFromSeconds = "00";

      event.start_date = new Date(
        dateFromYear,
        dateFromMonth - 1,
        dateFromDate,
        dateFromHour,
        dateFromMinutes,
        dateFromSeconds
      );

      let is24Hours = savedEvent.timeTo === "24:00";
      let dateToDate = savedEvent.dateTo.substring(0, 2);
      let dateToMonth = savedEvent.dateTo.substring(3, 5);
      let dateToYear = savedEvent.dateTo.substring(6, 10);
      let dateToHour = is24Hours ? "23" : savedEvent.timeTo.substring(0, 2);
      let dateToMinutes = is24Hours ? "59" : savedEvent.timeTo.substring(3, 5);
      let dateToSeconds = is24Hours ? "59" : "00";
      event.end_date = new Date(
        dateToYear,
        dateToMonth - 1,
        dateToDate,
        dateToHour,
        dateToMinutes,
        dateToSeconds
      );
      event.effective_end_date = savedEvent.effective_end_date;
      event.recurring = savedEvent.recurring;
      // event.endRecurringDate = savedEvent.endRecurringDate;
      event.available = savedEvent.available;
      event.prevAvailability = savedEvent.prevAvailability;
      event.availabilityTypes = savedEvent.availabilityTypes;
      event.eventType = savedEvent.eventType;

      let formattedDateFrom = `${dateFromYear}-${dateFromMonth}-${dateFromDate}`;
      let formattedDateTo = `${dateToYear}-${dateToMonth}-${dateToDate}`;

      let details = {
        dateFrom: formattedDateFrom,
        dateTo:
          event.recurring || formattedDateFrom !== formattedDateTo
            ? formattedDateTo
            : null,
        timeStart: `${dateFromHour}:${dateFromMinutes}:${dateFromSeconds}`,
        timeEnd: `${dateToHour}:${dateToMinutes}:${dateToSeconds}`,
        // endRecurringDate: event.recurring ? event.endRecurringDate : null,
        daysOfWeek: event.dayOfWeek.length === 0 ? null : event.dayOfWeek,
        availabilityTypes: event.availabilityTypes,
        emergencyLevels: event.eventType,
        pagingNumber: event.pagingNumber,
      };

      let API_ENDPOINT = event.prevAvailability
        ? this.Requests.removeSingleAvailability
        : this.Requests.removeSingleUnavailability;

      if (this.state.isEditEvent) {
        this.Requests.callAPI(API_ENDPOINT, event.eventID).then((data) => {
          if (data && data.status && data.status === 200) {
            this._scheduler.deleteEvent(event.id);
            this._scheduler.endLightbox(false);
            this.setState({
              isEditEvent: false,
            });

            this.onSaveEvent(event, details);
          } else {
            this.toastie(
              `Oops! Something went wrong! (${data.status} - ${data.statusText})`,
              "error"
            );
          }
        });
      } else {
        this.onSaveEvent(event, details);
      }
    }
  }

  onDeleteClick(event) {
    this.setState({
      showDeleteControls: true,
      deleteEvent: event,
    });
  }

  deleteAvailability = () => {
    this.setState({ showDeleteControls: false });
    const event = this.state.deleteEvent;
    const API_ENDPOINT = event.available
      ? this.Requests.removeSingleAvailability
      : this.Requests.removeSingleUnavailability;

    this.Requests.callAPI(API_ENDPOINT, event.eventID).then((data) => {
      if (data && data.status && data.status === 200) {
        this.toastie("Event has been removed!", "success");

        this._scheduler.deleteEvent(event.id);
        this._scheduler.endLightbox(false);
        this.getAvailability(this.state.currentWeekDate);
        this.setState({
          isEditEvent: false,
          deleteEvent: null,
        });
      } else {
        this.toastie(
          `Oops! Something went wrong! (${data.status} - ${data.statusText})`,
          "error"
        );
      }
    });
  };

  onDeleteSingleOccurrenceClick(event) {
    this.setState({
      showSingleDeleteControls: true,
      deleteEvent: event,
    });
  }

  deleteSingleAvailability = () => {
    this.setState({ showSingleDeleteControls: false });
    const event = this.state.deleteEvent;
    const API_ENDPOINT = event.available
      ? this.Requests.removeSingleRecurringAvailability
      : this.Requests.removeSingleRecurringUnavailability;

    const date = format(
      moment(event.currentTimeBlockDate, "DD-MM-YYYY").toDate(),
      "yyyy-MM-dd"
    );

    this.Requests.callAPI(API_ENDPOINT, event.eventID, date).then((data) => {
      if (data && data.status && data.status === 200) {
        this.toastie("Event has been removed!", "success");

        this._scheduler.deleteEvent(event.id);
        this._scheduler.endLightbox(false);
        this.getAvailability(this.state.currentWeekDate);
        this.setState({
          isEditEvent: false,
        });
      } else {
        this.toastie(
          `Oops! Something went wrong! (${data.status} - ${data.statusText})`,
          "error"
        );
      }
    });
  };

  /**
   * This function removes an event if it's not stored in the DB.
   * @param {*} eventID event's ID in the scheduler
   */
  checkValidityOfEvent(eventID) {
    const _event = this._scheduler.getEvent(eventID);
    // default events always have the name "New event"
    if (_event && _event.text && _event.text === "New event") {
      this._scheduler.deleteEvent(eventID);
      this.getAvailability(this.state.currentWeekDate);
    }
  }

  render() {
    const { userAgencies, pagingGroupsOwned } = this.props;
    const legend = document.querySelectorAll(".scheduler__legend")[0];
    const legendHeight = legend !== undefined ? legend.offsetHeight : 0;

    return (
      <div className="scheduler__container">
        <Popup
          isVisible={this.state.showDeleteControls}
          titleText={"Are you sure you want to delete this availability?"}
          button1Text={copy.global.btnDelete}
          button2Text={copy.global.btnCancel}
          button1Action={this.deleteAvailability}
          button2Action={() => {
            this.setState({ showDeleteControls: false });
          }}
        />
        <Popup
          isVisible={this.state.showSingleDeleteControls}
          titleText={"Are you sure you want to delete this availability?"}
          button1Text={copy.global.btnDelete}
          button2Text={copy.global.btnCancel}
          button1Action={this.deleteSingleAvailability}
          button2Action={() => {
            this.setState({ showSingleDeleteControls: false });
          }}
        />
        <div
          id="scheduler"
          className="dhx_cal_container"
          style={{ height: `calc(100vh - 146px - ${legendHeight}px)` }}>
          <div className="dhx_cal_navline">
            <Dropdown
              placeholder={
                this.state.dropdownOptions[0] === undefined
                  ? ""
                  : this.state.dropdownOptions[0].text
              }
              selection
              options={this.state.dropdownOptions}
              onChange={(e, { value }) => this.gotoDateInCalendar(value)}
            />

            <button
              className="button"
              style={
                this.state.isMobile
                  ? { boxShadow: "0px 10px 10px 1px rgba(0, 0, 0, 0.29)" }
                  : {}
              }
              aria-label="Add availability"
              onClick={() => {
                const minutes =
                  new Date().getHours() * 60 +
                  (new Date().getMinutes() / 30 < 1 ? 30 : 60);
                const startEndDate =
                  moment(this.state.currentWeekDate) < moment().startOf("day")
                    ? moment().startOf("day")
                    : this.state.currentWeekDate;
                this._scheduler.addEventNow({
                  start_date: moment(startEndDate).add(minutes, "m").toDate(),
                  end_date: moment(startEndDate)
                    .add(minutes + 30, "m")
                    .toDate(),
                });
              }}>
              <span>Add availability</span>
              <i></i>
            </button>
          </div>
          <div className="dhx_cal_header"></div>
          <div className="dhx_cal_data"></div>
        </div>

        <div className="scheduler__legend">
          <ul>
            <li className="scheduler__type -local">Local / Home Branch</li>
            <li className="scheduler__type -regionshorthaul">
              Region / Short Haul
            </li>
            <li className="scheduler__type -statelonghaul">
              State / Long Haul
            </li>
            <li className="scheduler__type -interstatedeployment">
              Interstate Deployment
            </li>
            <li className="scheduler__type -imt">IMT</li>
          </ul>

          {this.state.canAccessEmergencyLevels && (
            <ul>
              <li className="scheduler__level -emergency">Emergency</li>
              <li className="scheduler__level -non-emergency">Non-emergency</li>
              <li className="scheduler__level -admin">Admin</li>
            </ul>
          )}
        </div>

        {this.state.isLoading ? null : (
          <SchedulerModal
            allData={this.state.allData}
            isEventEdit={this.state.isEditEvent}
            eventData={this.state.eventData}
            userAgencies={userAgencies}
            pagingGroupsOwned={pagingGroupsOwned}
            canAccessEmergencyLevels={this.state.canAccessEmergencyLevels}
            onDeleteClick={(event) => {
              this.onDeleteClick(event);
            }}
            onDeleteSingleOccurrenceClick={(event) => {
              this.onDeleteSingleOccurrenceClick(event);
            }}
            onSaveClick={(event) => {
              this.onSaveClick(event);
            }}
            onCloseClick={(eventID) => {
              this.setState({
                isEditEvent: false,
              });
              this.checkValidityOfEvent(eventID);
              this._scheduler.endLightbox(false);
            }}
            isMobile={this.state.isMobile}
          />
        )}
      </div>
    );
  }
}
