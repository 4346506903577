import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { withRouter } from "react-router-dom";
import copy from "../../assets/copy/copy.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import Timer from "../../components/Timer/Timer";
import Loader from "../../components/UI/Loading/Loading";
import styles from "./TurnoutView.module.scss";

class ActiveEventTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: this.props.isExpandedView ? this.props.event : null,
      isLoading: true,
      isExpandedView: this.props.isExpandedView,
      errorMessage: null, // Error message for the banner
      timerBackgroundColour: '',
      timerTextColour: 'inherit',
    };
    this.Requests = new Requests();
  }

  componentDidUpdate(prevProps) {
    //only call API when timer is in the Fit to Screen View
    if (!this.state.isExpandedView) {
      if (
        prevProps.eventID !== this.props.eventID ||
        prevProps.updateEvent !== this.props.updateEvent
      ) {
        let activeEventID = this.props.eventID;
        if (activeEventID) {
          this.getActiveEventDetails(activeEventID, true);
        } else {
          this.setState({ event: null, isLoading: false });
        }
      }

      if (
        prevProps.loadTimer !== this.props.loadTimer &&
        this.props.loadTimer
      ) {
        this.setState({ event: null, isLoading: true });
      }
    }
  }

  getActiveEventDetails(id, showLoader) {
    this.setState(
      {
        isLoading: showLoader,
      },
      () => {
        this.Requests.callAPI(this.Requests.getEventDetails, id).then(
          (data) => {
            if (data && data.status && data.status === 200) {
              this.setState({ event: data.data, isLoading: false });
            } else {
              let ErrorMessage =
                copy.dashboard.getEventDetailsAPIErrorMessage +
                ` (Error #${copy.errorCodes.getEventDetailsAPIErrorMessage})`;
              this.setState({ errorMessage: ErrorMessage });
            }
          }
        );
      }
    );
  }

  // Checks whether active event is meeting SDS Timeframes
  checkTimeframe = (secondsPassedSinceEvent) => {
    const target = this.props.sdsTimeframeTarget
    const limit = this.props.sdsTimeframeLimit
    const exceeded = this.props.sdsTimeframeExceeded
    this.timeframe = {
      underTarget: secondsPassedSinceEvent < target,
      underLimit: secondsPassedSinceEvent < limit,
      underExceeded: secondsPassedSinceEvent < exceeded,
    }
    if (this.timeframe.underTarget) {
      if (this.state.timerBackgroundColour !== styles.underLimit) {
        this.setState({ 
          timerBackgroundColour: styles.underLimit, 
          timerTextColour: 'white'
        })
      }
    } else if (this.timeframe.underLimit) {
      if (this.state.timerBackgroundColour !== styles.underTarget) {
        this.setState({ 
          timerBackgroundColour: styles.underTarget,
          timerTextColour: 'black' 
        })
        this.props.sdsTimeframeTimerReadout()
      }
    } else if (this.timeframe.underExceeded || exceeded === 0) {
      if (this.state.timerBackgroundColour !== styles.underExceeded) {
        this.setState({ 
          timerBackgroundColour: styles.underExceeded,
          timerTextColour: 'white'

        })
        this.props.sdsTimeframeTimerReadout()
      }
    } else {
      if (this.state.timerBackgroundColour !== styles.overExceeded) {
        this.setState({ 
          timerBackgroundColour: styles.overExceeded,
          timerTextColour: 'reset'
         })
      }
    }
  }

  render() {
    return (
      <Wrapper>
        <div
          className={
            (this.state.isExpandedView
              ? styles.expandedTimerHeader
              : styles.timerHeader) + " turnoutTimerBorder " + this.state.timerBackgroundColour
          }
        >
          {this.state.event && this.state.event.eventStatus === "Open" ? (
            <Timer
              initialTimestamp={this.state.event.messages}
              eventStatus={this.state.event.eventStatus}
              turnoutHeader={true}
              readableTimerText={this.props.readableTimerText}
              secondsSinceEvent={(this.props.colourTimer) ? this.checkTimeframe : null}
              isExpandedView={this.state.isExpandedView}
              textStyle={this.state.timerTextColour}
            />
          ) : (
            <div className={styles.timerLoading}>
              {this.state.isLoading ? (
                <Loader />
              ) : (
                <h4>NO ACTIVE TIMER TO DISPLAY</h4>
              )}
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}
export default withRouter(ActiveEventTimer);
