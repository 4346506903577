export default function onResume() {
  if(window.cordova && window.MobileAccessibility && window.device && window.device.platform === "iOS"){
    window.MobileAccessibility.usePreferredTextZoom(true);
    window.MobileAccessibility.getTextZoom(function(currentZoom){
      window.MobileAccessibility.usePreferredTextZoom(false);
            var updatedCurrent = currentZoom;
            if(updatedCurrent > 140){
              updatedCurrent = 135;
            }
            window.MobileAccessibility.setTextZoom(updatedCurrent, function(success){
            });
      });
  }
}