// import _ from 'lodash'
import React, { Component } from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import Loader from "../../components/UI/Loading/Loading";
import copy from "../../assets/copy/copy.json";
import InfiniteScroll from "react-infinite-scroll-component";

// Page Component
// import Tabs from "../../components/UI/Tabs/Tabs";
import UserDetailsView from "./UserDetailsView";
// import QualificationTile from "../../components/Tiles/QualificationTile";
import GroupDetailsView from "./GroupDetailsView";
import AdvanceSettings from "../../components/Admin/AdvanceSettings";
import NewSASUserTile from "../../components/Admin/NewSASUserTile";

// Page UIs & styles

import Button from "../../components/UI/Button/Button";
// import Icon from "../../components/UI/Icon/Icon";
// import Toggle from "../../components/UI/Button/Toggle";
import { Dropdown } from "semantic-ui-react";
import Checkbox from "../../components/UI/Button/Checkbox";

import styles from "./AdminView.module.scss";

// Requests for API calls
// import copy from "../../assets/copy/copy.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      width: window.innerWidth,
      isMobile: window.innerWidth <= 1023,

      allPropsReceived: false, // Props required include userRoleAgency, pagingGroupsOwned
      adminAgencies: null, // Stores which agencies the user has admin rights to
      isPagingGroupOwner: null, // Stores whether the user is a paging group owner or not
      pagingGroupsOwnedNumberOnly: [], // Stores an array of paging groups the user owns, but paging number only

      // The following options store data that is in a format that is suitable for the filter
      // They get passed in as data to be searched
      userAgenciesOption: null,
      userPagingGroupsOption: null,
      userQualificationsOption: null,

      // searching by paging group and/or person
      searchPagingGroups: true,
      searchMembers: true,
      noPagingGroupAndMemberSelectedError: false,

      // These are the parameters being passed into the filter
      searchData: {
        agency: [],
        pagingNumber: [],
        qualification: [],
      },

      // Results to be displayed
      pagingGroupResults: [],
      membersResults: [],
      noPagingGroupResults: false,
      noMemberGroupResults: false,
      pagingGroupResultsLoading: false,
      memberResultsLoading: false,
      noSearchDataAgencySelected: false,

      hasMorePagingGroupsToLoad: true, // Indicates that more pagination can occur for paging group results
      hasMoreUsersToLoad: true, // Indicates that more pagination can occur for member results
      pagingGroupSkip: 0, // Required to skip paging groups when paginating
      userListSkip: 0, // Required to skip users when paginating

      // Stores which paging group/member was clicked on
      activePagingGroup: null,
      activeMember: null,

      isInitialSearch: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.sessionStorage.setItem("redirectHomeFromEvent", "");
    if (
      this.props.userRoleAgency &&
      this.props.userRoles &&
      this.props.userAgencies &&
      this.props.pagingGroupsPartOf !== null &&
      this.props.pagingGroupsOwned !== null
    ) {
      this.setState(
        {
          allPropsReceived: true,
        },
        () => {
          this.preparePermissions();
        }
      );
    } else {
      let ErrorMessage = copy.error_page.noRolesAgenciesPagingGroupsProps;

      this.setState({ errorMessage: ErrorMessage }, () => {
        setTimeout(
          function () {
            this.setState({ errorMessage: null });
          }.bind(this),
          5000
        );
      });
    }
  }

  // Make sure to remove the listener when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  /**
   * This function updates state variables when the window changes and
   */
  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth,
      isMobile: window.innerWidth <= 1023,
    });
  };

  /**
   * This function is used to determine:
   * - Which agencies the user has admin rights to
   * - Whether the user is a paging group owner
   */
  preparePermissions = () => {
    // See which agencies the user has admin rights too
    let adminAgencies = [];
    // If they are superuser, they will have admin rights to all agencies
    if (
      this.props.userRoleAgency !== undefined &&
      this.props.userRoleAgency.indexOf("SuperUser") !== -1
    ) {
      adminAgencies = ["AV", "CFA", "SES"];
      // If they are not a superuser, go through their list of roles to see
      // agency they do have admin rights to
    } else {
      let agencies = ["AV", "CFA", "SES"];
      agencies.forEach((agency) => {
        if (
          this.props.userRoleAgency.indexOf("Administrator_" + agency) !== -1 ||
          this.props.userRoleAgency.indexOf("Manager_" + agency) !== -1
        ) {
          adminAgencies.push(agency);
        }
      });
    }

    // See if the user is a paging group owner and also create an array of paging groups they own (numbers only)
    let pagingGroupsOwnedNumberOnly = [];
    let isPagingGroupOwner = false;
    Object.values(this.props.pagingGroupsOwned).forEach(
      (agencyPagingGroups) => {
        // If they have paging groups...
        if (agencyPagingGroups.length > 0) {
          isPagingGroupOwner = true;
          // Add the paging group number to the array
          pagingGroupsOwnedNumberOnly = pagingGroupsOwnedNumberOnly.concat(
            agencyPagingGroups.map((pagingGroup) => pagingGroup.key)
          );
        }
      }
    );

    this.setState(
      {
        isPagingGroupOwner: isPagingGroupOwner,
        adminAgencies: adminAgencies,
        pagingGroupsOwnedNumberOnly: pagingGroupsOwnedNumberOnly,
      },
      () => {
        this.prepareFilterData();
      }
    );
  };

  /**
   * This function prepares the filter data (agencies, paging groups, qualifications)
   * to ensure they are in a suitable format for the filter
   */
  prepareFilterData = () => {
    // Preparing agency data and paging groups data for the filter
    let userAgenciesOption = [];
    let pagingGroups = {};

    // Go through all the agencies which the user is admin of
    this.state.adminAgencies.forEach((agency, index) => {
      // Add the agency to the filter
      userAgenciesOption.push({
        key: index,
        text: agency,
        value: agency,
      });

      // If the user has permission to view paging groups of that agency, add the paging groups to the filter
      if (
        this.props.authorisedPagingGroups &&
        this.props.authorisedPagingGroups[agency] !== undefined &&
        this.props.authorisedPagingGroups[agency].length > 0
      ) {
        pagingGroups[agency] = [...this.props.authorisedPagingGroups[agency]];
      }
    });

    Object.entries(this.props.pagingGroupsOwned).forEach((entry) => {
      // If user is not admin of the agency but owns paging groups of that agency, add the paging groups they own to the filter
      if (
        this.state.adminAgencies.indexOf(entry[0]) === -1 &&
        entry[1].length > 0
      ) {
        // Adding agency to filter
        userAgenciesOption.push({
          key: userAgenciesOption.length,
          text: entry[0],
          value: entry[0],
        });

        // Adding paging groups to filter
        pagingGroups[entry[0]] = [...entry[1]];
      }
    });

    this.setState({
      userAgenciesOption: userAgenciesOption,
      userPagingGroupsOption: pagingGroups,
    });

    // Preparing qualifications data
    this.Requests.callAPI(this.Requests.getUserQualifications).then((data) => {
      if (data && data.status && data.status === 200) {
        let qualificationsOption = {};

        // Putting agencies into the qualifications object
        // (either agencies which user is admin of or own paging groups in)
        this.state.adminAgencies.forEach((agency) => {
          if (agency !== "AV") {
            qualificationsOption[agency] = [];
          }
        });
        Object.keys(this.props.pagingGroupsOwned).forEach((key) => {
          if (
            this.state.adminAgencies.indexOf(key) === -1 &&
            this.props.pagingGroupsOwned[key].length > 0
          ) {
            qualificationsOption[key] = [];
          }
        });

        // Looping through all qualifications and categorising them to the right agency
        data.data.forEach((qualification) => {
          if (qualificationsOption[qualification.agency] !== undefined) {
            qualificationsOption[qualification.agency].push({
              key: qualification.id,
              text: qualification.name,
              value: qualification.id,
            });
          }
        });
        this.setState({ userQualificationsOption: qualificationsOption });
      } else {
        let ErrorMessage =
          copy.admin.filter.filterQualsInfoAPIErrorMessage +
          ` (Error #${copy.errorCodes.filterQualsInfoAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          { errorMessage: ErrorMessage, userQualificationsOption: {} },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * Function that's called when a user clicks on a paging group result
   */
  pagingGroupResultClick = (item) => {
    this.setState({
      activePagingGroup: item,
      activeMember: null,
    });
  };

  /**
   * Function that's called when the user clicks on a member result
   */
  membersResultClick = (item) => {
    this.setState({
      activePagingGroup: null,
      activeMember: item,
    });
  };

  /**
   * This filters the data and produces the paging group and members results
   * Runs when the 'Search' button is clicked
   * 'bodyData' is only passed in if it is for pagination
   */
  filterData = (bodyData) => {
    // if paging groups and members checkboxes are both deselected, show
    // and error message
    if (!this.state.searchPagingGroups && !this.state.searchMembers) {
      this.setState(
        {
          noPagingGroupAndMemberSelectedError: true,
        },
        () => {
          setTimeout(
            function () {
              this.setState({
                noPagingGroupAndMemberSelectedError: false,
              });
            }.bind(this),
            8500
          );
        }
      );
    }
    // If user is trying to search with no data, then show nothing
    else if (
      this.state.searchData.agency.length === 0 &&
      this.state.searchData.pagingNumber.length === 0 &&
      this.state.searchData.qualification.length === 0 &&
      document.getElementById("userAndPagingGroupSearch").value === ""
    ) {
      this.setState({
        pagingGroupResults: [],
        membersResults: [],
        hasMorePagingGroupsToLoad: true,
        hasMoreUsersToLoad: true,
      });
    } else {
      // Setting up body of the API call
      let userSeachBody;
      let groupSearchBody;
      if (bodyData) {
        userSeachBody = bodyData;
        groupSearchBody = bodyData;
      } else {
        userSeachBody = {
          take: 30,
          keywords:
            document.getElementById("userAndPagingGroupSearch").value === ""
              ? []
              : [document.getElementById("userAndPagingGroupSearch").value],
          agency:
            this.state.searchData.agency.length === 0
              ? null
              : this.state.searchData.agency,
          pagingNumber:
            this.state.searchData.pagingNumber.length === 0
              ? null
              : this.state.searchData.pagingNumber,
          qualificationId:
            this.state.searchData.qualification.length === 0
              ? null
              : this.state.searchData.qualification,
          ownedAndRoleGroupsOnly: true,
          includePagingNumberDecendants: true,
          showDeactivated: true,
        };

        groupSearchBody = {
          take: 30,
          keywords:
            document.getElementById("userAndPagingGroupSearch").value === ""
              ? []
              : [document.getElementById("userAndPagingGroupSearch").value],
          agency:
            this.state.searchData.agency.length === 0
              ? null
              : this.state.searchData.agency,
          pagingNumber:
            this.state.searchData.pagingNumber.length === 0
              ? null
              : this.state.searchData.pagingNumber,
          qualificationId:
            this.state.searchData.qualification.length === 0
              ? null
              : this.state.searchData.qualification,
          onlyGroupsRequestingUserCanUpdate: true,
        };
      }

      this.setState(
        {
          hasMorePagingGroupsToLoad: !bodyData
            ? true
            : this.state.hasMorePagingGroupsToLoad,
          hasMoreUsersToLoad: !bodyData ? true : this.state.hasMoreUsersToLoad,
        },
        () => {
          // An agency must be selected before the search can work
          if (this.state.searchData.agency.length > 0) {
            // Scenario 1: Pagination for user results
            if (this.state.isLoadingUserData) {
              this.Requests.callAPI(
                this.Requests.filterUsers,
                userSeachBody
              ).then((data) => {
                if (data && data.status && data.status === 200 && data.data) {
                  let membersResults;

                  membersResults = this.state.membersResults.concat(data.data);

                  // No more users to to load so pagination should stop
                  if (data.data.length < 30) {
                    this.setState({
                      hasMoreUsersToLoad: false,
                    });
                  }
                  // Fallback in case pagination triggers this to false before all data is finished loading
                  if (data.data.length === 30) {
                    this.setState({
                      hasMoreUsersToLoad: true,
                    });
                  }

                  this.setState({
                    membersResults: membersResults,
                    isLoadingUserData: false,
                    memberResultsLoading: false,
                    isInitialSearch: false,
                  });
                } else {
                  let ErrorMessage =
                    copy.admin.filter.filterInfoAPIErrorMessage +
                    ` (Error #${copy.errorCodes.filterChangeAPIErrorMessage})`;
                  // if (data && data.data && data.data.SASMessageClient) {
                  //   ErrorMessage = data.data.SASMessageClient;
                  // }
                  this.setState(
                    {
                      errorMessage: ErrorMessage,
                      membersResults: false,
                      pagingGroupResults: false,
                      isLoadingUserData: false,
                      memberResultsLoading: false,
                      isInitialSearch: true,
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({ errorMessage: null });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                }
              });
            } // Scenario 2: Pagination for paging group results
            else if (this.state.isLoadingPagingData) {
              this.Requests.callAPI(
                this.Requests.filterPagingGroups,
                groupSearchBody
              ).then((data) => {
                if (data && data.status && data.status === 200 && data.data) {
                  let pagingGroupResults;

                  pagingGroupResults = this.state.pagingGroupResults.concat(
                    data.data
                  );

                  if (data.data.length < 30) {
                    this.setState({
                      hasMorePagingGroupsToLoad: false,
                    });
                  }

                  // Fallback in case pagination triggers this to false before all data is finished loading
                  if (data.data.length === 30) {
                    this.setState({
                      hasMorePagingGroupsToLoad: true,
                    });
                  }

                  this.setState({
                    pagingGroupResults: pagingGroupResults,
                    isLoadingPagingData: false,
                    pagingGroupResultsLoading: false,
                    isInitialSearch: false,
                  });
                } else {
                  let ErrorMessage =
                    copy.admin.filter.filterPagingGroupInfoAPIErrorMessage +
                    ` (Error #${copy.errorCodes.filterPagingGroupInfoAPIErrorMessage})`;
                  // if (data && data.data && data.data.SASMessageClient) {
                  //   ErrorMessage = data.data.SASMessageClient;
                  // }
                  this.setState(
                    {
                      errorMessage: ErrorMessage,
                      pagingGroupResults: false,
                      isLoadingPagingData: false,
                      pagingGroupResultsLoading: false,
                      isInitialSearch: true,
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({ errorMessage: null });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                }
              });
            } // Scenario 3: New search
            else {
              if (this.state.searchPagingGroups) {
                this.setState(
                  {
                    pagingGroupResultsLoading: true,
                  },
                  () => {
                    this.Requests.callAPI(
                      this.Requests.filterPagingGroups,
                      groupSearchBody
                    ).then((data) => {
                      if (
                        data &&
                        data.status &&
                        data.status === 200 &&
                        data.data
                      ) {
                        let pagingGroupResults;
                        pagingGroupResults = data.data;

                        if (data.data.length < 30) {
                          this.setState({
                            hasMorePagingGroupsToLoad: false,
                          });
                        }
                        // Fallback in case pagination triggers this to false before all data is finished loading
                        if (data.data.length === 30) {
                          this.setState({
                            hasMorePagingGroupsToLoad: true,
                          });
                        }

                        if (data.data.length === 0) {
                          this.setState({
                            noPagingGroupResults: true,
                          });
                        } else {
                          this.setState({
                            noPagingGroupResults: false,
                          });
                        }
                        this.setState({
                          pagingGroupResults: pagingGroupResults,
                          pagingGroupResultsLoading: false,
                          isInitialSearch: false,
                        });
                      } else {
                        let ErrorMessage =
                          copy.admin.filter
                            .filterPagingGroupInfoAPIErrorMessage +
                          ` (Error #${copy.errorCodes.filterPagingGroupInfoAPIErrorMessage})`;
                        // if (data && data.data && data.data.SASMessageClient) {
                        //   ErrorMessage = data.data.SASMessageClient;
                        // }
                        this.setState(
                          {
                            errorMessage: ErrorMessage,
                            pagingGroupResultsLoading: false,
                            isInitialSearch: true,
                          },
                          () => {
                            setTimeout(
                              function () {
                                this.setState({ errorMessage: null });
                              }.bind(this),
                              5000
                            );
                          }
                        );
                      }
                    });
                  }
                );
              }
              if (this.state.searchMembers) {
                this.setState(
                  {
                    memberResultsLoading: true,
                  },
                  () => {
                    this.Requests.callAPI(
                      this.Requests.filterUsers,
                      userSeachBody
                    ).then((data) => {
                      if (
                        data &&
                        data.status &&
                        data.status === 200 &&
                        data.data
                      ) {
                        let membersResults;

                        membersResults = data.data;

                        if (data.data.length < 30) {
                          this.setState({
                            hasMoreUsersToLoad: false,
                          });
                        }
                        // Fallback in case pagination triggers this to false before all data is finished loading
                        if (data.data.length === 30) {
                          this.setState({
                            hasMoreUsersToLoad: true,
                          });
                        }

                        if (data.data.length === 0) {
                          this.setState({
                            noMemberGroupResults: true,
                          });
                        } else {
                          this.setState({
                            noMemberGroupResults: false,
                          });
                        }
                        this.setState({
                          membersResults: membersResults,
                          memberResultsLoading: false,
                          isInitialSearch: false,
                        });
                      } else {
                        let ErrorMessage =
                          copy.admin.filter.filterInfoAPIErrorMessage +
                          ` (Error #${copy.errorCodes.filterChangeAPIErrorMessage})`;
                        // if (data && data.data && data.data.SASMessageClient) {
                        //   ErrorMessage = data.data.SASMessageClient;
                        // }
                        this.setState(
                          {
                            errorMessage: ErrorMessage,
                            memberResultsLoading: false,
                            isInitialSearch: true,
                          },
                          () => {
                            setTimeout(
                              function () {
                                this.setState({
                                  errorMessage: null,
                                });
                              }.bind(this),
                              5000
                            );
                          }
                        );
                      }
                    });
                  }
                );
              }
            }
          } else {
            this.setState({
              noSearchDataAgencySelected: true,
            });
          }
        }
      );
    }
  };

  /**
   * Called when pagination is required for paging groups results
   */
  triggerGroupPagination = () => {
    if (
      this.state.pagingGroupResults.length >= 30 &&
      this.state.hasMorePagingGroupsToLoad
    ) {
      this.setState(
        {
          isLoadingPagingData: true,
          isLoadingUserData: false,
        },
        () => {
          // Setting up body of the API call
          this.setState(
            {
              pagingGroupSkip: this.state.pagingGroupSkip + 30,
            },
            () => {
              let body = {
                take: 30,
                skip: this.state.pagingGroupSkip,
                keywords: document.getElementById("userAndPagingGroupSearch")
                  ? document.getElementById("userAndPagingGroupSearch")
                      .value === ""
                    ? []
                    : [
                        document.getElementById("userAndPagingGroupSearch")
                          .value,
                      ]
                  : null,
                agency:
                  this.state.searchData.agency.length === 0
                    ? null
                    : this.state.searchData.agency,
                pagingNumber:
                  this.state.searchData.pagingNumber.length === 0
                    ? null
                    : this.state.searchData.pagingNumber,
                qualificationId:
                  this.state.searchData.qualification.length === 0
                    ? null
                    : this.state.searchData.qualification,
                onlyGroupsRequestingUserCanUpdate: true,
              };

              // Call the filter data function to get more data
              this.filterData(body);
            }
          );
        }
      );
    } else {
      if (this.state.hasMorePagingGroupsToLoad) {
        this.setState({
          hasMorePagingGroupsToLoad: false,
        });
      }
    }
  };

  /**
   * Called when pagination is required for user results
   */
  triggerUserPagination = () => {
    if (
      this.state.membersResults.length >= 30 &&
      this.state.hasMoreUsersToLoad
    ) {
      this.setState(
        {
          isLoadingUserData: true,
          isLoadingPagingData: false,
        },
        () => {
          // Setting up body of the API call
          this.setState(
            {
              userListSkip: this.state.userListSkip + 30,
            },
            () => {
              let body = {
                take: 30,
                skip: this.state.userListSkip,
                keywords: document.getElementById("userAndPagingGroupSearch")
                  ? document.getElementById("userAndPagingGroupSearch")
                      .value === ""
                    ? []
                    : [
                        document.getElementById("userAndPagingGroupSearch")
                          .value,
                      ]
                  : null,
                agency:
                  this.state.searchData.agency.length === 0
                    ? null
                    : this.state.searchData.agency,
                pagingNumber:
                  this.state.searchData.pagingNumber.length === 0
                    ? null
                    : this.state.searchData.pagingNumber,
                qualificationId:
                  this.state.searchData.qualification.length === 0
                    ? null
                    : this.state.searchData.qualification,
                ownedAndRoleGroupsOnly: true,
                includePagingNumberDecendants: true,
                showDeactivated: true,
              };

              // Call the filter data function to get more data
              this.filterData(body);
            }
          );
        }
      );
    } else {
      if (this.state.hasMoreUsersToLoad) {
        this.setState({
          hasMoreUsersToLoad: false,
        });
      }
    }
  };

  /**
   * This function renders the agency filter
   */
  renderAgencies() {
    // If there is only one option for the agency, then that is the only value the user can search on
    if (
      this.state.userAgenciesOption.length === 1 &&
      this.state.searchData.agency[0] !== this.state.userAgenciesOption[0].value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          agency: [this.state.userAgenciesOption[0].value],
        },
      }));
    }

    return (
      <div className={styles.dropdownHolder}>
        <span className={styles.dropdownLabel}>
          {copy.admin.filter.agencyFilter}
        </span>
        {this.state.userAgenciesOption.length > 1 ? (
          // Scenario 1: User is part of multiple agencies
          <div className={styles.dropdownWrapper}>
            <Dropdown
              placeholder=""
              fluid
              multiple
              search
              selection
              {...(this.state.noSearchDataAgencySelected && {
                placeholder: copy.admin.filter.agencyFilterPlaceholder,
                className: styles.error,
              })}
              noResultsMessage={""}
              value={this.state.searchData.agency}
              options={this.state.userAgenciesOption}
              onChange={(e, { value }) => this.handleAgencyFilterChange(value)}
            />
          </div>
        ) : // Scenario 2: User is part of only one agency (no filtering for agencis can be done)
        this.state.userAgenciesOption.length === 1 ? (
          <p>{this.state.userAgenciesOption[0].text}</p>
        ) : (
          // Scenario 3: User is part of no agencies
          <span>{copy.admin.filter.noAgencies}</span>
        )}
      </div>
    );
  }

  /**
   * This function is used to handle when the agency filter values change
   * It is needed because changes in agency will affect the paging numbers being displayed
   * NOTE:
   * this.state.agency = the previous filter values
   * value = the new filter values
   */
  handleAgencyFilterChange = (value) => {
    // If paging groups or qualifications are currently being filtered
    if (
      this.state.searchData.pagingNumber.length !== 0 ||
      this.state.searchData.qualification.length !== 0
    ) {
      let tempPagingNumbers = this.state.searchData.pagingNumber;
      let tempQualifications = this.state.searchData.qualification;

      // If an agency was removed and there is at least one agency being filtered
      if (
        value.length !== 0 &&
        this.state.searchData.agency.length !== 0 &&
        this.state.searchData.agency.length === value.length + 1
      ) {
        // Get the removed agency
        let removedAgency = null;
        this.state.searchData.agency.forEach((agency) => {
          // Get the agency that was removed from the filter
          if (value.indexOf(agency) === -1) {
            removedAgency = agency;
          }
        });

        // Changing paging group filter if there are currently selections
        if (this.state.searchData.pagingNumber.length !== 0) {
          // Remove any paging groups which belong to that agency from the results
          this.state.userPagingGroupsOption[removedAgency].forEach(
            (pagingGroup) => {
              let index = tempPagingNumbers.indexOf(pagingGroup.value);
              if (index !== -1) {
                tempPagingNumbers.splice(index, 1);
              }
            }
          );
        }

        // Change qualifications filter if there are currently selections
        if (this.state.searchData.qualification.length !== 0) {
          // Remove any paging groups which belong to that agency from the results
          this.state.userQualificationsOption[removedAgency].forEach(
            (qualification) => {
              let index = tempQualifications.indexOf(qualification.value);
              if (index !== -1) {
                tempQualifications.splice(index, 1);
              }
            }
          );
        }
        if (!this.state.isInitialSearch) {
          this.filterData();
        }
        // If an agency was added when previously none were being filtered
      } else if (
        this.state.searchData.agency.length === 0 &&
        value.length === 1
      ) {
        let addedAgency = value[0];

        // Changing paging group filter if there are currently selections
        if (this.state.searchData.pagingNumber.length !== 0) {
          tempPagingNumbers = [];
          // Any paging groups which are not from that agency should be removed
          this.state.userPagingGroupsOption[addedAgency].forEach(
            (pagingGroup) => {
              let index = this.state.searchData.pagingNumber.indexOf(
                pagingGroup.value
              );
              if (index !== -1) {
                tempPagingNumbers.push(pagingGroup.value);
              }
            }
          );
        }

        // Change qualifications filter if there are currently selections
        if (this.state.searchData.qualification.length !== 0) {
          tempQualifications = [];
          // Any qualifications which are not from that agency should be removed
          this.state.userQualificationsOption[addedAgency].forEach(
            (qualification) => {
              let index = this.state.searchData.qualification.indexOf(
                qualification.value
              );
              if (index !== -1) {
                tempQualifications.push(qualification.value);
              }
            }
          );
        }
        if (!this.state.isInitialSearch) {
          this.filterData();
        }
      }
      this.setState(
        (prevState) => ({
          ...prevState,
          searchData: {
            ...prevState.searchData,
            pagingNumber:
              tempPagingNumbers.length === 0 ? [] : tempPagingNumbers,
            qualification:
              tempQualifications.length === 0 ? [] : tempQualifications,
          },
        }),
        () => {
          if (!this.state.isInitialSearch) {
            this.filterData();
          }
        }
      );
    }

    // Update the events with the new agency filter applied
    this.setState(
      (prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          agency: value.length === 0 ? [] : value,
        },
        noSearchDataAgencySelected: false,
      }),
      () => {
        if (!this.state.isInitialSearch) {
          this.filterData();
        }
      }
    );
  };

  /**
   * This function renders the paging groups filter
   */
  renderPagingGroups() {
    let pagingGroupOption = [];

    // If no agencies has been selected, then all paging groups should be selectable
    if (this.state.searchData.agency.length === 0) {
      Object.values(this.state.userPagingGroupsOption).forEach(
        (pagingGroup) => {
          pagingGroupOption = pagingGroupOption.concat(pagingGroup);
        }
      );
    } else {
      // If agencies have been selected, only the paging groups from the agencies should be selectable
      // Go through all agencies the user is part of and add the paging groups to the search data
      this.state.searchData.agency.forEach((agency) => {
        if (this.state.userPagingGroupsOption[agency] !== undefined) {
          pagingGroupOption = pagingGroupOption.concat(
            this.state.userPagingGroupsOption[agency]
          );
        }
      });
    }

    // add personal paging groups
    if (this.props.pagingGroupsPartOf && this.props.pagingGroupsPartOf.all) {
      this.props.pagingGroupsPartOf.all.forEach(
        (personalPagingGroup, index) => {
          pagingGroupOption.push({
            key: personalPagingGroup.key,
            text: personalPagingGroup.text,
            value: personalPagingGroup.value,
          });
        }
      );
    }

    // sort the paging groups in ascending order by name
    pagingGroupOption.sort((a, b) => {
      return a["text"].localeCompare(b["text"]);
    });

    // If there is only one paging group option, then the user can only search on it
    if (
      pagingGroupOption.length === 1 &&
      this.state.searchData.pagingNumber[0] !== pagingGroupOption[0].value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          pagingNumber: [pagingGroupOption[0].value],
        },
      }));
    }

    return (
      <div className={styles.dropdownHolder}>
        <span className={styles.dropdownLabel}>
          {copy.admin.filter.pagingGroupsFilter}
        </span>
        {pagingGroupOption.length > 1 ? (
          // Scenario 1: User is part of multiple paging groups
          <div className={styles.dropdownWrapper}>
            <Dropdown
              placeholder=""
              fluid
              multiple
              search
              selection
              noResultsMessage={""}
              value={this.state.searchData.pagingNumber}
              options={pagingGroupOption}
              onChange={(e, { value }) => {
                this.setState(
                  (prevState) => ({
                    ...prevState,
                    searchData: {
                      ...prevState.searchData,
                      pagingNumber: value,
                    },
                  }),
                  () => {
                    if (!this.state.isInitialSearch) {
                      this.filterData();
                    }
                  }
                );
              }}
            />
          </div>
        ) : // Scenario 2: User part of only one paging group
        pagingGroupOption.length === 1 ? (
          <p>{pagingGroupOption[0].text}</p>
        ) : (
          // Scenario 3: User is not part of any paging group
          <span>
            <p>{copy.admin.filter.noPagingGroups}</p>
          </span>
        )}
      </div>
    );
  }

  /**
   * This function prepares the qualification filter
   */
  renderQualification() {
    let qualificationsOption = [];
    // If no agencies has been selected, then all qualifications should be selectable
    if (this.state.searchData.agency.length === 0) {
      Object.values(this.state.userQualificationsOption).forEach(
        (qualification) => {
          qualificationsOption = qualificationsOption.concat(qualification);
        }
      );
    } else {
      // If agencies have been selected, only the qualifications from the agencies should be selectable
      // Go through all agencies the user is part of and add the qualifications to the search data
      for (let i = 0; i < this.state.searchData.agency.length; i++) {
        if (
          this.state.userQualificationsOption[
            this.state.searchData.agency[i]
          ] !== undefined
        ) {
          qualificationsOption = qualificationsOption.concat(
            this.state.userQualificationsOption[this.state.searchData.agency[i]]
          );
        }
      }
    }

    // If there is only one qualification option, the user can onsly search based on it
    if (
      qualificationsOption.length === 1 &&
      this.state.searchData.qualification[0] !== qualificationsOption[0].value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          qualificationId: [qualificationsOption[0].value],
        },
      }));
    }

    return (
      <div className={styles.dropdownHolder}>
        <span className={styles.dropdownLabel}>
          {copy.admin.filter.qualificationsFilter}
        </span>

        {qualificationsOption.length > 0 ? (
          // Scenario 1: User has multiple qualifications to choose from
          <div className={styles.dropdownWrapper}>
            <Dropdown
              placeholder=""
              fluid
              multiple
              search
              selection
              noResultsMessage={""}
              value={this.state.searchData.qualification}
              options={qualificationsOption}
              onChange={(e, { value }) => {
                this.setState(
                  (prevState) => ({
                    ...prevState,
                    searchData: {
                      ...prevState.searchData,
                      qualification: value,
                    },
                  }),
                  () => {
                    if (!this.state.isInitialSearch) {
                      this.filterData();
                    }
                  }
                );
              }}
            />
          </div>
        ) : (
          // Scenario 2: User has no qualifications to choose from
          <span>
            <p>{copy.admin.filter.noQualifications}</p>
          </span>
        )}
      </div>
    );
  }

  /**
   * This function renders the search/filter for the page
   */
  renderSearchFiltersSection() {
    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
        />
        <div className="box_default">
          <div className="Sub-Section-titles">
            <h3>{copy.admin.filter.searchDescription}</h3>
            <div className={styles.searchCheckboxes}>
              <Checkbox
                name={"Paging Groups"}
                label={"Paging Groups"}
                checked={this.state.searchPagingGroups}
                onChange={() => {
                  this.setState((prevState) => ({
                    searchPagingGroups: !prevState.searchPagingGroups,
                  }));
                }}
              />
            </div>
            <div className={styles.searchCheckboxes}>
              <Checkbox
                name={"Members"}
                label={"Members"}
                checked={this.state.searchMembers}
                onChange={() => {
                  this.setState((prevState) => ({
                    searchMembers: !prevState.searchMembers,
                  }));
                }}
              />
            </div>
          </div>
          {this.state.noPagingGroupAndMemberSelectedError && (
            <Wrapper>
              <p className="fadeaway error">
                {
                  copy.admin.filter
                    .noPagingGroupAndMemberSelectedForSearchErrorMessage
                }
              </p>
            </Wrapper>
          )}
          <input
            id="userAndPagingGroupSearch"
            placeholder={copy.admin.filter.searchPlaceholder}
            className={styles.searchbox}
          ></input>
          <p className={styles.label}>{copy.admin.filter.filterDescription}</p>
          {this.renderAgencies()}
          {this.renderPagingGroups()}
          {
            // Don't render qualifications filter if selected agency is AV and only AV
            this.state.searchData &&
            this.state.searchData.agency &&
            this.state.searchData.agency.length === 1 &&
            this.state.searchData.agency[0] === "AV"
              ? null
              : this.renderQualification()
          }
          {/* {this.renderAvailability()} */}
          <Button
            label={copy.global.btnSearch}
            content={copy.global.btnSearch}
            variant="btn_outline"
            styles="btn_primary"
            icon="icon_search"
            buttonClick={() => {
              // Reset the page skip for the pagination
              this.setState(
                {
                  pagingGroupSkip: 0,
                  userListSkip: 0,
                },
                () => {
                  this.filterData();
                }
              );
            }}
          />
        </div>
      </Wrapper>
    );
  }

  /**
   * This function renders the results of the search
   */
  renderSearchResultsSection() {
    //Scenario 1: API fails and can't load results
    if (
      this.state.pagingGroupResults.length === 0 &&
      this.state.membersResults.length === 0 &&
      !this.state.memberResultsLoading &&
      !this.state.pagingGroupResultsLoading &&
      !this.state.noPagingGroupResults &&
      !this.state.noMemberGroupResults
    ) {
      return (
        <div className="box_default">
          <br></br>
          <p>{copy.searchAndFilter.searchToSeeResults}</p>
        </div>
      );
    }

    // Scenario 2: User input provided
    else {
      let heightStyle = { height: "calc(100% - 54px)" };
      if (this.state.searchPagingGroups && this.state.searchMembers) {
        heightStyle = { height: "calc(50% - 54px)" };
      }
      return (
        <div className={[`${"box_default "}`, styles.resultsWrapper].join(" ")}>
          {/* paging group search results */}
          {this.state.searchPagingGroups && (
            <Wrapper>
              <p className={styles.label}>
                {copy.admin.filter.pagingGroupResults}
              </p>
              <ul
                id="searchGroupResultsWrapper"
                className={
                  styles.searchResults +
                  " " +
                  (this.state.isMobile ? styles.mobile : "")
                }
                style={heightStyle}
              >
                {this.state.pagingGroupResultsLoading ? (
                  <div className="inLineloadingContainer">
                    <Loader />
                  </div>
                ) : (
                  <Wrapper>
                    {this.state.pagingGroupResults &&
                    this.state.pagingGroupResults.length > 0 ? (
                      <Wrapper>
                        <InfiniteScroll
                          dataLength={this.state.pagingGroupResults.length}
                          next={this.triggerGroupPagination}
                          hasMore={this.state.hasMorePagingGroupsToLoad}
                          loader={
                            <div className={styles.paginationLoader}>
                              <Loader />
                            </div>
                          }
                          scrollableTarget="searchGroupResultsWrapper"
                          // endMessage={
                          //   <div className={styles.paginationLoader}>
                          //     <span>{copy.global.noMessages}</span>
                          //   </div>
                          // }
                          scrollThreshold="100%"
                        >
                          {this.state.pagingGroupResults.map((item, index) => {
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  this.pagingGroupResultClick(item);
                                }}
                              >
                                {item.name}
                              </li>
                            );
                          })}
                        </InfiniteScroll>
                      </Wrapper>
                    ) : (
                      <p>{copy.searchAndFilter.noResults}</p>
                    )}
                  </Wrapper>
                )}
              </ul>
            </Wrapper>
          )}
          {/* member search results */}
          {this.state.searchMembers && (
            <Wrapper>
              <p className={styles.label}>{copy.admin.filter.memberResults}</p>
              <ul
                id="searchUserResultsWrapper"
                className={
                  styles.searchResults +
                  " " +
                  (this.state.isMobile ? styles.mobile : "")
                }
                style={heightStyle}
              >
                {this.state.memberResultsLoading ? (
                  <div className="inLineloadingContainer">
                    <Loader />
                  </div>
                ) : (
                  // </div>
                  <Wrapper>
                    {this.state.membersResults &&
                    this.state.membersResults.length > 0 ? (
                      <Wrapper>
                        <InfiniteScroll
                          dataLength={this.state.membersResults.length}
                          next={this.triggerUserPagination}
                          hasMore={this.state.hasMoreUsersToLoad}
                          loader={
                            <div className={styles.paginationLoader}>
                              <Loader />
                            </div>
                          }
                          scrollableTarget="searchUserResultsWrapper"
                          // endMessage={
                          //   <div className={styles.paginationLoader}>
                          //     <span>{copy.global.noMessages}</span>
                          //   </div>
                          // }
                          scrollThreshold="100%"
                        >
                          {this.state.membersResults.map((item, index) => {
                            let individualLocation = [];
                            let individualEmail = [];
                            // This gets all locations for each agency
                            item.userAgencyInfo.forEach((agency) => {
                              if (
                                agency.locationAgencyHome &&
                                !individualLocation.includes(
                                  agency.locationAgencyHome
                                )
                              ) {
                                // Array only includes each location once
                                individualLocation.push(
                                  agency.locationAgencyHome
                                );
                              }
                              if (
                                agency.email &&
                                !individualEmail.includes(agency.email)
                              ) {
                                // Array only includes each email once
                                individualEmail.push(agency.email);
                              }
                            });
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  this.membersResultClick(item);
                                }}
                              >
                                {item.name}
                                <p className={styles.description}>
                                  {individualEmail.join(", ")}
                                </p>
                                <p className={styles.description}>
                                  {individualLocation.join(", ")}
                                </p>
                              </li>
                            );
                          })}
                        </InfiniteScroll>
                      </Wrapper>
                    ) : (
                      <p>{copy.searchAndFilter.noResults}</p>
                    )}
                  </Wrapper>
                )}
              </ul>
            </Wrapper>
          )}
        </div>
      );
    }
  }

  /**
   * This function is used only for mobile
   * It resets all user input for the search, clears the results and brings
   * the user back to the search
   */
  resetSearch = () => {
    this.setState({
      pagingGroupResults: [],
      membersResults: [],
      activePagingGroup: null,
      activeMember: null,
      searchData: {
        agency: [],
        pagingNumber: [],
        qualification: [],
        availability: null,
      },
      memberResultsLoading: false,
      pagingGroupResultsLoading: false,
      noPagingGroupResults: false,
      noMemberGroupResults: false,
      isInitialSearch: true,
      hasMorePagingGroupsToLoad: true,
      hasMoreUsersToLoad: true,
    });
  };

  /**
   * - Search/Filter can only be seen if you are a superuser, admin or paging group owner
   * - Advanced settings can only be seen if you are superuser or admin
   * - You can only create a new SAS user if you are a superuser, admin or manager
   */
  render() {
    // Only load the page when the filter has been set up
    if (
      this.state.isPagingGroupOwner === null ||
      this.state.adminAgencies === null ||
      this.state.userAgenciesOption === null ||
      this.state.userPagingGroupsOption === null ||
      this.state.userQualificationsOption === null
    ) {
      return (
        <div className="loadingContainer">
          <Loader />
        </div>
      );
    }
    if (this.state.isMobile) {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <div
            className={
              "main-content-holder " + styles.adminViewMainContentWrapper
            }
          >
            <div className={"col-one-third " + styles.adminColumn}>
              <div className="Section-titles">
                <h2>{copy.admin.administrationTitle}</h2>
                {/* Only show the reset button if the user has the permission to search/filter */}
                {(this.props.userRoles.indexOf("SuperUser") !== -1 ||
                  this.props.userRoles.indexOf("Administrator") !== -1 ||
                  this.props.userRoles.indexOf("Manager") !== -1 ||
                  this.state.isPagingGroupOwner) &&
                !(
                  this.state.pagingGroupResults.length === 0 &&
                  this.state.membersResults.length === 0 &&
                  !this.state.pagingGroupResultsLoading &&
                  !this.state.memberResultsLoading &&
                  !this.state.noPagingGroupResults &&
                  !this.state.noMemberGroupResults
                ) ? (
                  <div className={styles.btnHolder}>
                    <Button
                      id="resetSearch"
                      label={copy.global.btnResetSearch}
                      content={copy.global.btnResetSearch}
                      variant="btn_secondary"
                      styles="btn_outline"
                      icon=""
                      buttonClick={() => {
                        this.resetSearch();
                      }}
                    />
                  </div>
                ) : null}
              </div>
              {this.props.userRoles.indexOf("SuperUser") !== -1 ||
              this.props.userRoles.indexOf("Administrator") !== -1 ||
              this.props.userRoles.indexOf("Manager") !== -1 ||
              this.state.isPagingGroupOwner ? (
                this.state.activePagingGroup !== null ? (
                  <GroupDetailsView
                    userId={this.props.userInfo.userId}
                    userRoles={this.props.userRoles}
                    pagingGroup={this.state.activePagingGroup}
                    userRoleAgency={this.props.userRoleAgency}
                    pagingGroupsOwnedNumberOnly={
                      this.state.pagingGroupsOwnedNumberOnly
                    }
                  />
                ) : this.state.activeMember !== null ? (
                  <UserDetailsView
                    userId={this.props.userInfo.userId}
                    userRoleAgency={this.props.userRoleAgency}
                    userAgencies={this.props.userAgencies}
                    user={this.state.activeMember}
                    isPagingGroupOwner={this.state.isPagingGroupOwner}
                    roles={this.props.userRoles}
                    searchData={this.state.searchData}
                  />
                ) : this.state.pagingGroupResults.length === 0 &&
                  this.state.membersResults.length === 0 &&
                  !this.state.pagingGroupResultsLoading &&
                  !this.state.memberResultsLoading &&
                  !this.state.noPagingGroupResults &&
                  !this.state.noMemberGroupResults ? (
                  this.renderSearchFiltersSection()
                ) : (
                  this.renderSearchResultsSection()
                )
              ) : null}

              <Wrapper>
                {/* Only superusers or administrators can see advance settings */}
                {this.props.userRoles.indexOf("SuperUser") !== -1 ||
                this.props.userRoles.indexOf("Administrator") !== -1 ? (
                  <AdvanceSettings userAgencies={this.state.adminAgencies} />
                ) : null}
                {/* Only supereusers, administrators or managers can see advance settings */}
                {this.props.userRoles.indexOf("SuperUser") !== -1 ||
                this.props.userRoles.indexOf("Administrator") !== -1 ||
                this.props.userRoles.indexOf("Manager") !== -1 ? (
                  <NewSASUserTile userRoles={this.props.userRoleAgency} />
                ) : null}
              </Wrapper>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <div
            className={
              "main-content-holder " + styles.adminViewMainContentWrapper
            }
          >
            <div className={"col-one-third " + styles.adminColumn}>
              <div className="Section-titles">
                <h2>{copy.admin.administrationTitle}</h2>
              </div>
              <div className={styles.scrollableContent}>
                {this.props.userRoles.indexOf("SuperUser") !== -1 ||
                this.props.userRoles.indexOf("Administrator") !== -1 ||
                this.props.userRoles.indexOf("Manager") !== -1 ||
                this.state.isPagingGroupOwner
                  ? this.renderSearchFiltersSection()
                  : null}
                {this.props.userRoles.indexOf("SuperUser") !== -1 ||
                this.props.userRoles.indexOf("Administrator") !== -1 ? (
                  <AdvanceSettings userAgencies={this.state.adminAgencies} />
                ) : null}
                {this.props.userRoles.indexOf("SuperUser") !== -1 ||
                this.props.userRoles.indexOf("Administrator") !== -1 ||
                this.props.userRoles.indexOf("Manager") !== -1 ? (
                  <NewSASUserTile userRoles={this.props.userRoleAgency} />
                ) : null}
              </div>
            </div>

            {this.props.userRoles.indexOf("SuperUser") !== -1 ||
            this.props.userRoles.indexOf("Administrator") !== -1 ||
            this.props.userRoles.indexOf("Manager") !== -1 ||
            this.state.isPagingGroupOwner ? (
              <div className={"col-one-third " + styles.adminColumn}>
                <div className="Section-titles">
                  <h2>{copy.admin.resultsTitle}</h2>
                </div>
                {this.renderSearchResultsSection()}
              </div>
            ) : null}

            {/* Show the user details when a user has been selected */}
            {/* <UserDetailsView /> */}
            {this.state.activeMember !== null ? (
              <div className={"col-one-third " + styles.adminColumn}>
                <UserDetailsView
                  userId={this.props.userInfo.userId}
                  userRoleAgency={this.props.userRoleAgency}
                  userRoles={this.props.userRoles}
                  user={this.state.activeMember}
                  isPagingGroupOwner={this.state.isPagingGroupOwner}
                  roles={this.props.userRoles}
                  searchData={this.state.searchData}
                />
              </div>
            ) : null}
            {/* Show the group details when a groups have been selected */}
            {/* <GroupDetailsView /> */}
            {this.state.activePagingGroup !== null ? (
              <div className={"col-one-third " + styles.adminColumn}>
                <GroupDetailsView
                  userId={this.props.userInfo.userId}
                  userRoles={this.props.userRoles}
                  pagingGroup={this.state.activePagingGroup}
                  userRoleAgency={this.props.userRoleAgency}
                  pagingGroupsOwnedNumberOnly={
                    this.state.pagingGroupsOwnedNumberOnly
                  }
                />
              </div>
            ) : null}
          </div>
        </Wrapper>
      );
    }
  }
}
