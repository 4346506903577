import React from "react";
import styles from "./GroupSettings.module.scss";
import Toggle from "../../components/UI/Button/Toggle";
import Accordion from "../../components/UI/Accordion/Accordion";
import Radio from "../../components/UI/Button/Radio";
import Button from "../../components/UI/Button/Button";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import copy from "../../assets/copy/copy.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import Loader from "../../components/UI/Loading/Loading";

/**
 * Sections that appear below the "Set notifications" pane.
 * Contains emergency, non-emergency, and admin settings for either Global or individual Group notifications.
 */
export default class GroupSettings extends React.Component {
  myPersonalRef = null;

  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      // List of available sounds. New sounds must be added here.
      sounds: [
        { sound: "SAS Alert Tone 1", label: "SAS Alert Tone 1 - Long (15sec)" },
        { sound: "SAS Alert Tone 2", label: "SAS Alert Tone 2 - Long (15sec)" },
        { sound: "SAS Alert Tone 3", label: "SAS Alert Tone 3 - Long (15sec)" },
        { sound: "SAS Alert Tone 4", label: "SAS Alert Tone 4 - Long (15sec)" },
        { sound: "SAS Alert Tone 5", label: "SAS Alert Tone 5 - Long (15sec)" },
        { sound: "SAS Alert Tone 6", label: "SAS Alert Tone 6 - Long (15sec)" },
        { sound: "SAS Alert Tone 7", label: "SAS Alert Tone 7 - Long (15sec)" },
        { sound: "SAS Alert Tone 8", label: "SAS Alert Tone 8 - Long (15sec)" },
        { sound: "SAS Alert Tone 9", label: "SAS Alert Tone 9 - Long (15sec)" },
        {
          sound: "SAS Alert Tone 10",
          label: "SAS Alert Tone 10 - Long (15sec)",
        },
        {
          sound: "SAS Alert Tone 11",
          label: "SAS Alert Tone 11 - Long (15sec)",
        },
        {
          sound: "SAS Alert Tone 12",
          label: "SAS Alert Tone 12 - Long (15sec)",
        },
        {
          sound: "SAS Alert Tone 13",
          label: "SAS Alert Tone 13 - Long (15sec)",
        },
        {
          sound: "SAS Alert Tone 14",
          label: "SAS Alert Tone 14 - Long (15sec)",
        },
        {
          sound: "SAS Alert Tone 15",
          label: "SAS Alert Tone 15 - Long (15sec)",
        },
        {
          sound: "SAS Alert Tone 16",
          label: "SAS Alert Tone 16 - Long (15sec)",
        },
        {
          sound: "SAS Alert Tone 17",
          label: "SAS Alert Tone 17 - Short (3sec)",
        },
        {
          sound: "SAS Alert Tone 18",
          label: "SAS Alert Tone 18 - Short (3sec)",
        },
        {
          sound: "SAS Alert Tone 19",
          label: "SAS Alert Tone 19 - Short (3sec)",
        },
        {
          sound: "SAS Alert Tone 20",
          label: "SAS Alert Tone 20 - Short (3sec)",
        },
        {
          sound: "SAS Alert Tone 21",
          label: "SAS Alert Tone 21 - Long (15sec)",
        },
        {
          sound: "SAS Alert Tone 22",
          label: "SAS Alert Tone 22 - Long (15sec)",
        },
        {
          sound: "SAS Alert Tone 23",
          label: "SAS Alert Tone 23 - Long (15sec)",
        },
        {
          sound: "SAS Alert Tone 24",
          label: "SAS Alert Tone 24 - Long (15sec)",
        },
      ],
      // List of available light colours. New colours must be added here.
      lights: ["Red", "Purple", "Blue"],
      // Initial state for reminder.
      reminder: null,
      // Contains current settings and updated as they change.
      selected: {
        emergency: {
          sound: {
            name: "SAS Alert Tone 1",
          },
          light: { name: "Red", hex: "FF0000" },
          vibration: true,
          channelVersion: 0,
          isCritical: false,
        },
        nonemergency: {
          sound: {
            name: "SAS Alert Tone 1",
          },
          light: { name: "Purple", hex: "800080" },
          vibration: true,
          channelVersion: 0,
          isCritical: false,
        },
        admin: {
          sound: {
            name: "SAS Alert Tone 1",
          },
          light: { name: "Blue", hex: "0000FF" },
          vibration: true,
          channelVersion: 0,
          isCritical: false,
        },
      },
      // Current alert type (emergency, nonemergency, admin)
      alertType: "",
      // If true, shows the initial screen where users can select which alert type they want to edit.
      showAlertTypes: true,
      // If true, shows loader.
      loading: true,
      // Only show on iOS when the user tries to turn off vibrations with sound
      vibrationsLimitationMessage: false,
    };
    // iOS and Android handle audio differently
    if (window.device) {
      if (window.device.platform === "iOS") {
        this.currentAudio = new window.Media(
          require("../../assets/tones/SAS Alert Tone 1.wav").default
        );
      } else {
        this.currentAudio = new Audio(
          require("../../assets/tones_mp3/SAS Alert Tone 1.mp3").default
        );
      }
    }
    // Keeps track of playing sound.
    this.timeout = null;
  }

  /**
   * componentDidMount for GroupSettings.
   * Sets current settings if there are settings passed in (returned from API).
   */
  componentDidMount() {
    if (this.props.currentSettings) {
      this.setCurrentSettings();
    }
  }

  /**
   * componentDidUpdate for GroupSettings.
   * Sets current settings if there were no settings on mount and they had just been passed in (due to API).
   * Also ensures that if ChatSettings is in Edit mode, GroupSettings is not
   * @param {JSON} prevProps The previous props.
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.currentSettings && this.props.currentSettings) {
      this.setCurrentSettings();
    }

    // Close the group notifications settings if chat notification settings was toggled to edit state
    if (
      prevProps.chatNotificationsEditState !==
        this.props.chatNotificationsEditState &&
      this.props.chatNotificationsEditState &&
      !this.state.showAlertTypes
    ) {
      this.closeNotificationSettings();
    }
  }

  /**
   * componentWillUnmount for GroupSettings.
   * Stops audio from playing.
   */
  componentWillUnmount() {
    this.unmountAudio();
  }

  /**
   * Sets user's current settings in the state so that the component reflects them.
   * Current settings are based on this.props.currentSettings
   */
  setCurrentSettings = () => {
    const { currentSettings } = this.props;

    if (currentSettings) {
      // Current selected object
      let selected = this.state.selected;

      // messageTypeNotificationSettings will be null if the user has no current settings for this group
      if (currentSettings.messageTypeNotificationSettings) {
        // Looping through emergency, nonemergency, and admin settings
        currentSettings.messageTypeNotificationSettings.forEach((value) => {
          // Emergency, nonemergency, or admin
          const alertType = value.messageType.toLowerCase();
          // Current sound with underscores
          const soundNameRaw = value.sound;
          // Whether vibration is toggled
          const vibration = value.vibration;
          // Android 8+ notification channel ID
          const channelId = value.channelId;
          // Hex value of current light colour
          let lightHex = value.lightColor;
          // Critical Alert value
          let isCritical = value.critical;

          let lightName = null;
          let soundName = null;

          // Sets light name displayed to the user depending on hex code
          switch (lightHex) {
            case "FF0000":
              lightName = "Red";
              break;
            case "800080":
              lightName = "Purple";
              break;
            case "0000FF":
              lightName = "Blue";
              break;
            case "FFFFFF":
              lightName = "Off";
              break;
            default:
              lightName = "Red";
              lightHex = "FF0000";
              break;
          }

          // Sets sound name displayed to user
          if (!soundNameRaw) {
            soundName = "SAS Alert Tone 1";
          } else if (soundNameRaw === "off") {
            soundName = "Off";
          } else {
            soundName = "SAS Alert Tone " + soundNameRaw.split("_")[3];
          }

          // Gets the version of the current channel ID (integer after channel ID)
          const channelIdArray = channelId.split("_");
          let channelVersion = parseInt(
            channelIdArray[channelIdArray.length - 1],
            10
          );

          // Defaults to 0 if there is no version
          if (isNaN(channelVersion)) {
            channelVersion = 0;
          }

          // New object to contain current settings
          selected = {
            ...selected,
            [alertType]: {
              sound: {
                name: soundName,
              },
              light: {
                name: lightName,
                hex: lightHex,
              },
              vibration: vibration,
              channelVersion: channelVersion,
              isCritical: isCritical,
            },
          };
        });

        // Sets state for current settings
        this.setState({
          reminder: currentSettings.messageUnreadReminder,
          selected: selected,
        });
      }

      // Clears loader
      this.setState({
        loading: false,
      });
    }
  };

  /**
   * This handles toggling sounds and lights on and off via the toggle
   * @param {string} label Either "Lights" or "Sounds": the setting that is being toggled
   */
  handleNotificationToggle = (label) => {
    let alertType = this.state.alertType.replace(/-/g, "").toLowerCase();
    // Changes settings just for sounds
    if (label === "Sounds") {
      // Resets sounds to tone 1 if sounds are turned off
      if (this.state.selected[alertType].sound.name === "Off") {
        this.handleNotificationSoundRadio("SAS Alert Tone 1", true);
      } else {
        this.handleNotificationSoundRadio("Off", true);
      }
      // Changes settings just for lights
    } else if (label === "Lights") {
      // Resets lights to red if lights are turned off
      if (this.state.selected[alertType].light.name === "Off") {
        this.handleNotificationLightRadio("Red");
      } else {
        this.handleNotificationLightRadio("Off");
      }
    }
  };

  /**
   * Handles the sounds radio buttons
   * Plays the sound and then sets the sound as the current sound in the state
   * @param {string} item The name of the sound in lowercase with underscores
   * @param {bool} fromToggle Whether the function was called because the sound toggle was pressed
   */
  handleNotificationSoundRadio = (item, fromToggle) => {
    // Removes hyphens and makes lowercase from alert type
    let alertType = this.state.alertType.replace(/-/g, "").toLowerCase();
    if (window.device) {
      // Stops audio if another sound is playing
      // iOS and Android handle sounds differently
      if (window.device.platform === "iOS") {
        this.currentAudio.stop();
      } else {
        this.currentAudio.pause();
      }
      if (item !== "Off") {
        // Handles playing sound differently for iOS and Android
        if (window.device.platform === "iOS") {
          this.currentAudio = new window.Media(
            // Path to where the tone is stored
            require("../../assets/tones/" + item + ".wav").default
          );
        } else {
          this.currentAudio.setAttribute(
            "src",
            // Path to where the tone is stored
            require("../../assets/tones_mp3/" + item + ".mp3").default
          );
        }
      }
    }

    // Will play the newly selected sound, only when radio buttons are pressed, not when toggle is pressed
    // Timeout handles how long the sound should play for
    if (!fromToggle) {
      clearTimeout(this.timeout);
      if (window.device) {
        this.currentAudio.play();
        // Timeout is set to 2 seconds
        this.timeout = setTimeout(this.unmountAudio, 2000);
      }
    }

    // Check if iOS and if the vibrations are off. If the vibrations are off, and sound is being turned on, turn vibrations on too.
    let vibrationToggle = this.state.selected[alertType].vibration;
    if (
      window.device &&
      window.device.platform === "iOS" &&
      this.state.selected[alertType].sound.name === "Off" &&
      vibrationToggle === false
    ) {
      vibrationToggle = true;
    }

    this.setState(
      {
        vibrationsLimitationMessage: false,
        selected: {
          ...this.state.selected,
          [alertType]: {
            ...this.state.selected[alertType],
            sound: {
              name: item,
            },
            vibration: vibrationToggle,
            // Channel version should increment
            channelVersion: this.state.selected[alertType].channelVersion + 1,
          },
        },
      },
      // This calls the API and sets the notification channel
      () => this.saveNotificationPreferences()
    );
  };

  /**
   * Handles the lights radio buttons
   * Sets the selected colour as the current light in the state and looks up its hex code (ANDROID ONLY)
   * @param {string} item The name of the light colour
   */
  handleNotificationLightRadio = (item) => {
    // Removes hyphens and makes lowercase from alert type
    let alertType = this.state.alertType.replace(/-/g, "").toLowerCase();

    // Assigns hex colours. Hex colours are the EMV-supplied colours for emergency, nonemergency, and admin
    let hex;
    switch (item) {
      case "Red":
        hex = "FF0000";
        break;
      case "Purple":
        hex = "800080";
        break;
      case "Blue":
        hex = "0000FF";
        break;
      case "Off":
        hex = "FFFFFF";
        break;
      default:
        hex = "FFFFFF";
        break;
    }

    this.setState(
      {
        selected: {
          ...this.state.selected,
          [alertType]: {
            ...this.state.selected[alertType],
            light: { name: [item], hex: hex },
            // Channel version should increment
            channelVersion: this.state.selected[alertType].channelVersion + 1,
          },
        },
      },
      // This calls the API and sets the notification channel
      () => this.saveNotificationPreferences()
    );
  };

  /**
   * Handles the vibration toggle
   * Toggles the selected state of vibrations in the state
   */
  handleNotificationVibrationToggle = () => {
    // Removes hyphens and makes lowercase from alert type
    let alertType = this.state.alertType.replace(/-/g, "").toLowerCase();

    // Check if the device is an iOS device, if it is, the sounds needs to be ON, inorder to turn the toggle off.
    if (
      window.device &&
      window.device.platform === "iOS" &&
      this.state.selected[alertType].sound.name !== "Off" &&
      this.state.selected[alertType].vibration === true
    ) {
      this.setState({
        vibrationsLimitationMessage: true,
      });
    } else {
      this.setState(
        {
          vibrationsLimitationMessage: false,
          selected: {
            ...this.state.selected,
            [alertType]: {
              ...this.state.selected[alertType],
              vibration: !this.state.selected[alertType].vibration,
              // Channel version should increment
              channelVersion: this.state.selected[alertType].channelVersion + 1,
            },
          },
        },
        // This calls the API and sets the notification channel
        () => {
          this.saveNotificationPreferences();
        }
      );
    }
  };

  /**
   * Handles the critical alerts toggle
   * Toggles the selected state of critical alerts in the state
   */
  handleNotificationCriticalToggle = () => {
    // Removes hyphens and makes lowercase from alert type
    let alertType = this.state.alertType.replace(/-/g, "").toLowerCase();

    // Check if the device is an iOS device as critical alerts are only available on iOS
    if (window.device && window.device.platform === "iOS") {
      this.setState(
        {
          selected: {
            ...this.state.selected,
            [alertType]: {
              ...this.state.selected[alertType],
              isCritical: !this.state.selected[alertType].isCritical,
              // Channel version should increment
              channelVersion: this.state.selected[alertType].channelVersion + 1,
            },
          },
        },
        // This calls the API and sets the notification channel
        () => {
          this.saveNotificationPreferences();
        }
      );
    }
  };

  /**
   * Handles the toggle to be reminded of unread pages
   * Toggles the selected state of being reminded of unread pages in the state
   */
  handleReminderToggle = () => {
    this.setState(
      {
        reminder: !this.state.reminder,
      },
      () => {
        // Calls setPagingNotificationReminder for paging groups
        let deviceId = window.device.uuid;
        if (this.props.pagerGroup) {
          this.Requests.callAPI(
            this.Requests.setPagingNotificationReminder,
            deviceId,
            this.props.pagerGroup.number,
            this.state.reminder
          ).then((data) => {
            if (data.status !== 200) {
              let ErrorMessage =
                copy.settings.notifications
                  .setPagingGroupReminderAPIErrorMessage +
                ` (Error #${copy.errorCodes.setPagingGroupReminderAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  reminder: !this.state.reminder,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          });
          // Calls setGlobalNotificationReminder for global settings
        } else {
          let deviceId = window.device.uuid;
          this.Requests.callAPI(
            this.Requests.setGlobalNotificationReminder,
            deviceId,
            this.state.reminder
          ).then((data) => {
            if (data.status !== 200) {
              let ErrorMessage =
                copy.settings.notifications.setGlobalReminderAPIErrorMessage +
                ` (Error #${copy.errorCodes.setGlobalReminderAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  reminder: !this.state.reminder,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          });
        }
      }
    );
  };

  /**
   * This function stops any current playing sounds from playing
   */
  unmountAudio = () => {
    if (window.device) {
      // iOS and Android handle sounds differently
      if (window.device.platform === "iOS") {
        this.currentAudio.stop();
      } else {
        this.currentAudio.pause();
      }
    }
  };

  /**
   * Opens setting pane for either emergency, nonemergency, or admin sound/light/vibration settings
   * @param {string} alertType The alert type, either Emergency, Non-Emergency, or Admin
   */
  openNotificationSettings = (alertType) => {
    this.setState(
      {
        showAlertTypes: false,
        alertType: alertType,
      },
      () => {
        this.props.toggleGroupNotificationsEditState(
          !this.state.showAlertTypes
        );
      }
    );
  };

  /**
   * Closes settings pane for sound/light/vibration settings and returns to overview pane
   */
  closeNotificationSettings = () => {
    this.setState(
      {
        showAlertTypes: true,
        alertType: "",
        vibrationsLimitationMessage: false,
      },
      () => {
        this.props.toggleGroupNotificationsEditState(
          !this.state.showAlertTypes
        );
      }
    );
  };

  /**
   * Is called from all "handleNotification" functions above.
   * Creates channels and sets the new settings, then calls the API to send the preferences to the backend
   */
  saveNotificationPreferences = () => {
    let alertTypeRaw,
      alertType = null;
    if (this.state.alertType) {
      // alertTypeRaw is the alert type with upper case letters (RAW being the way it was originally)
      alertTypeRaw = this.state.alertType.replace(/-/g, "");
      // alertType is the alert type in lower case
      alertType = this.state.alertType.replace(/-/g, "").toLowerCase();
    }
    // Current channel version
    let version = this.state.selected[alertType].channelVersion;
    // This creates the current channel ID (needed to delete it from the device)
    let oldChannel =
      "channel_" +
      (this.props.pagerGroup
        ? (this.props.pagerGroup.agency + "_" + this.props.pagerGroup.name)
            .toLowerCase()
            .replace(/ /g, "_")
        : "global") +
      "_" +
      alertType +
      "_" +
      (version - 1);

    let vibration,
      soundName,
      lightName,
      lightHex,
      isCritical = null;
    // Null catches for getting above variables from state
    if (this.state.selected && this.state.selected[alertType]) {
      vibration = this.state.selected[alertType].vibration;
      if (this.state.selected[alertType].sound) {
        soundName = this.state.selected[alertType].sound.name;
      }
      if (this.state.selected[alertType].light) {
        lightName = this.state.selected[alertType].light.name;
        lightHex = this.state.selected[alertType].light.hex;
      }
      isCritical = this.state.selected[alertType].isCritical;
    }

    // Whether lights are turned on or off
    const lightBool = lightName !== "Off";

    // This creates the channel
    let channel = {
      // Channel ID is in format: channel_GLOBAL/GROUPNAME_ALERTTYPE_VERSION
      id:
        "channel_" +
        (this.props.pagerGroup
          ? (this.props.pagerGroup.agency + "_" + this.props.pagerGroup.name)
              .toLowerCase()
              .replace(/ /g, "_")
          : "global") +
        "_" +
        alertType +
        "_" +
        version,
      description:
        "This is the highest priority channel with " +
        lightName +
        " lights" +
        (vibration ? ", vibration" : "") +
        " and " +
        soundName +
        " as the sound.",
      name:
        // Uses either agency and pager group name or global
        (this.props.pagerGroup
          ? this.props.pagerGroup.agency +
            " " +
            this.props.pagerGroup.name.replace(/_/g, " ")
          : "Global") +
        " " +
        alertTypeRaw +
        " Notification Preferences",
      // Sound name needs to have underscores
      sound: soundName.toLowerCase().replace(/ /g, "_"),
      vibration: vibration
        ? [0, 750, 250, 750, 250, 750, 1000, 750, 250, 750, 250, 750]
        : false,
      light: lightBool,
      // This converts the hex value for the light colour into ARGB format
      lightColor: lightHex,
      importance: 4,
      badge: true,
      visibility: 1,
    };

    let deviceId = window.device.uuid;

    // Send API request - setPagingNotificationChannel for paging groups
    if (this.props.pagerGroup) {
      this.Requests.callAPI(
        this.Requests.setPagingNotificationChannel,
        deviceId,
        this.props.pagerGroup.number,
        alertTypeRaw,
        {
          channelId: channel.id,
          sound: channel.sound,
          vibration: vibration, // Dont use the channel.vibration, since thats stored as an array in the phone rather than a boolean true / false
          light: channel.light,
          lightColor: channel.lightColor,
          importance: channel.importance,
          badge: channel.badge,
          visibility: channel.visibility,
          critical: isCritical,
        }
      ).then((data) => {
        let deviceId = window.device.uuid;
        if (data && data.status && data.status === 200) {
          // Sets preferred notification details to be Per Group
          this.Requests.callAPI(
            this.Requests.setNotificationBy,
            deviceId,
            "PagingNumber"
          ).then((data) => {
            if (data.status !== 200) {
              let ErrorMessage =
                copy.settings.notifications
                  .setNotificationPreferenceAPIErrorMessage +
                ` (Error #${copy.errorCodes.setNotificationPreferenceAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState({ errorMessage: ErrorMessage }, () => {
                setTimeout(
                  function () {
                    this.setState({ errorMessage: null });
                  }.bind(this),
                  5000
                );
              });
            }
          });
          if (window.device) {
            // Deletes old channel and replaces it with the new one
            window.FirebasePlugin.deleteChannel(
              oldChannel,
              function () {
                // Create the new channel
                window.FirebasePlugin.createChannel(
                  channel,
                  function () {
                    console.log("Channel created: " + channel.id);
                  },
                  function (error) {
                    console.log("Create channel error: " + error);
                  }
                );
              },
              function (error) {
                console.log("Delete channel error: " + error);
              }
            );
          }
        } else {
          let ErrorMessage =
            copy.settings.notifications
              .setPagingGroupNotificationsAPIErrorMessage +
            ` (Error #${copy.errorCodes.setPagingGroupNotificationsAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      });
    } else {
      // Sends API for global notification settings
      let deviceId = window.device.uuid;
      this.Requests.callAPI(
        this.Requests.setGlobalNotificationChannel,
        deviceId,
        alertTypeRaw,
        {
          channelId: channel.id,
          sound: channel.sound,
          vibration: vibration, // Dont use the channel.vibration, since thats stored as an array in the phone rather than a boolean true / false
          light: channel.light,
          lightColor: lightHex,
          importance: channel.importance,
          badge: channel.badge,
          visibility: channel.visibility,
          critical: isCritical,
        }
      ).then((data) => {
        let deviceId = window.device.uuid;
        if (data && data.status && data.status === 200) {
          // Sets preferred notification details to be global
          this.Requests.callAPI(
            this.Requests.setNotificationBy,
            deviceId,
            "Global"
          ).then((data) => {
            if (data.status !== 200) {
              let ErrorMessage =
                copy.settings.notifications
                  .setNotificationPreferenceAPIErrorMessage +
                ` (Error #${copy.errorCodes.setNotificationPreferenceAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState({ errorMessage: ErrorMessage }, () => {
                setTimeout(
                  function () {
                    this.setState({
                      errorMessage: null,
                    });
                  }.bind(this),
                  5000
                );
              });
            }
          });
          if (window.device) {
            // Deletes old notification channel
            window.FirebasePlugin.deleteChannel(
              oldChannel,
              function () {
                console.log("Channel deleted: " + oldChannel);
                // Creates the new channel
                window.FirebasePlugin.createChannel(
                  channel,
                  function () {
                    console.log("Channel created: " + channel.id);
                  },
                  function (error) {
                    console.log("Create channel error: " + error);
                  }
                );
              },
              function (error) {
                console.log("Delete channel error: " + error);
              }
            );
          }
        } else {
          let ErrorMessage =
            copy.settings.notifications.setGlobalNotificationsAPIErrorMessage +
            ` (Error #${copy.errorCodes.setGlobalNotificationsAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      });
    }
  };

  /**
   * This opens an OS settings window within the app
   * @param {string} setting The settings ID to be opened based on the cordova-open-native-settings plugin
   */
  openDeviceSettings = (setting) => {
    if (
      window.cordova &&
      window.cordova.plugins &&
      window.cordova.plugins.settings
    ) {
      // Given the app is opening an external program, saves the current preferences
      this.saveNotificationPreferences();
      window.cordova.plugins.settings.open(setting);
    }
  };

  render() {
    const { pagerGroup } = this.props;
    // Removes hyphen from alert type
    let alertType = null;
    if (this.state.alertType) {
      alertType = this.state.alertType.replace(/-/g, "").toLowerCase();
    }
    // Creates the group name to display in the text explaining how to open override do not disturb
    const groupName =
      (this.props.pagerGroup && this.props.pagerGroup.name
        ? this.props.pagerGroup.agency +
          " " +
          this.props.pagerGroup.name.replace(/_/g, " ")
        : "Global") +
      " " +
      this.state.alertType +
      " Notification Preferences";
    // Only if the sounds/lights/vibration settings pane is open
    if (alertType) {
      // Creates list of available sounds based on array in the state
      this.soundList = this.state.sounds.map((item, index) => (
        <li key={index}>
          <Radio
            name={"sound"}
            onClick={() => {
              this.handleNotificationSoundRadio(item.sound);
            }}
            label={item.label}
            checked={
              // Defaults to selecting the first sound if there are currently no sounds selected
              (item.sound === this.state.selected[alertType].sound.name &&
                "true") ||
              (this.state.selected[alertType].sound.name === "Off" &&
                index === 0)
            }
          />
        </li>
      ));
      // Creates list of available light colours based on array in the state
      this.lightList = this.state.lights.map((item, index) => (
        <li key={index}>
          <Radio
            name={"light"}
            onClick={() => {
              this.handleNotificationLightRadio(item);
            }}
            label={item}
            checked={
              // Defaults to selecting first light if there are currently no lights selected
              (item === this.state.selected[alertType].light.name && "true") ||
              (this.state.selected[alertType].light.name === "Off" &&
                index === 0)
            }
          />
        </li>
      ));
    }

    return (
      <div className="box_default">
        <div className={"Sub-Section-titles " + styles.Title}>
          {/* Headings */}
          {this.state.showAlertTypes ? (
            <h3>
              {this.props.pagerGroup && pagerGroup.name
                ? pagerGroup.name.replace(/_/g, " ")
                : "Global"}
            </h3>
          ) : (
            <h3>
              {this.state.alertType} {copy.settings.settingsTitle.toLowerCase()}
            </h3>
          )}
          {/* Back buttons - only shown on inner pages */}
          {!this.state.showAlertTypes ? (
            <Button
              icon=""
              label="Back"
              content={copy.global.btnBack}
              variant="btn_outline"
              styles="btn_secondary"
              buttonClick={this.closeNotificationSettings}
            />
          ) : (
            <span>
              {this.props.buttonClick && (
                <Button
                  icon=""
                  label="Back"
                  content={copy.global.btnBack}
                  variant="btn_outline"
                  styles="btn_secondary"
                  buttonClick={this.props.buttonClick}
                />
              )}
            </span>
          )}
        </div>
        {this.state.loading ? (
          this.props.currentSettings === false ? (
            <div className={styles.API_error_text}>
              {this.props.pagerGroup && pagerGroup.name
                ? copy.settings.notifications
                    .getPagingGroupNotificationAPIErrorMessage +
                  ` (Error #${copy.errorCodes.getPagingGroupNotificationAPIErrorMessage})`
                : copy.settings.notifications.getNotificationsAPIErrorMessage +
                  ` (Error #${copy.errorCodes.getGlobalNotificationAPIErrorMessage})`}
            </div>
          ) : (
            <div className={styles.loadingContainer}>
              <Loader />
            </div>
          )
        ) : (
          <div className="toggle_group">
            {/* This contains the outer settings (overview page for emergency/nonemergency/admin) */}
            {this.state.showAlertTypes ? (
              <Wrapper>
                {/* Be reminded of unread pages toggle */}
                <div className="toggle_heading">
                  <span>{copy.settings.notifications.reminder}</span>
                  <Toggle
                    key="1"
                    checked={this.state.reminder}
                    variant="toggle"
                    label="vibration toggle"
                    buttonClick={this.handleReminderToggle}
                  />
                </div>
                <div
                  className={"toggle_heading " + styles.notificationsHeading}
                >
                  <span>
                    {copy.settings.notifications.notificationTitle.toUpperCase()}
                  </span>
                </div>
                <div className={styles.alertType}>
                  <div className={styles.heading}>
                    {/* Emergency settings */}
                    <span className={styles.emergency}>
                      {copy.global.emerg}
                    </span>
                    <Button
                      label="Edit"
                      content={copy.global.btnEdit}
                      variant="btn_outline"
                      styles="greenTxt"
                      buttonClick={() => {
                        this.openNotificationSettings("Emergency");
                      }}
                      className="openEmergencySettings"
                    />
                  </div>
                  <div className={styles.content}>
                    {window.device && (
                      <div className={styles.section}>
                        <span className={styles.title}>
                          {copy.settings.notifications.vibrations}
                        </span>
                        <p>
                          {this.state.selected.emergency.vibration
                            ? "Enabled"
                            : "Disabled"}
                        </p>
                      </div>
                    )}
                    <div className={styles.section}>
                      <span className={styles.title}>
                        {copy.settings.notifications.sounds}
                      </span>
                      <p>{this.state.selected.emergency.sound.name}</p>
                    </div>
                    {/* Only show lights on android */}
                    {window.device && window.device.platform === "Android" && (
                      <div className={styles.section}>
                        <span className={styles.title}>
                          {copy.settings.notifications.lights}
                        </span>
                        <p>{this.state.selected.emergency.light.name}</p>
                      </div>
                    )}
                    {/* Only show critical alerts on iOS */}
                    {window.device && window.device.platform === "iOS" && (
                      <div className={styles.section}>
                        <span className={styles.title}>
                          {copy.settings.notifications.critical}
                        </span>
                        <p>
                          {this.state.selected.emergency.isCritical
                            ? "Enabled"
                            : "Disabled"}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.alertType}>
                  <div className={styles.heading}>
                    {/* Non-emergency settings */}
                    <span className={styles.nonEmergency}>
                      {copy.global.nonEmerg}
                    </span>
                    <Button
                      label="Edit"
                      content={copy.global.btnEdit}
                      variant="btn_outline"
                      styles="greenTxt"
                      buttonClick={() => {
                        this.openNotificationSettings("Non-Emergency");
                      }}
                      className="openNonEmergencySettings"
                    />
                  </div>
                  <div className={styles.content}>
                    {window.device && (
                      <div className={styles.section}>
                        <span className={styles.title}>
                          {copy.settings.notifications.vibrations}
                        </span>
                        <p>
                          {this.state.selected.nonemergency.vibration
                            ? "Enabled"
                            : "Disabled"}
                        </p>
                      </div>
                    )}
                    <div className={styles.section}>
                      <span className={styles.title}>
                        {copy.settings.notifications.sounds}
                      </span>
                      <p>{this.state.selected.nonemergency.sound.name}</p>
                    </div>
                    {/* Only show lights on android */}
                    {window.device && window.device.platform === "Android" && (
                      <div className={styles.section}>
                        <span className={styles.title}>
                          {copy.settings.notifications.lights}
                        </span>
                        <p>{this.state.selected.nonemergency.light.name}</p>
                      </div>
                    )}
                    {/* Only show critical alerts on iOS */}
                    {window.device && window.device.platform === "iOS" && (
                      <div className={styles.section}>
                        <span className={styles.title}>
                          {copy.settings.notifications.critical}
                        </span>
                        <p>
                          {this.state.selected.nonemergency.isCritical
                            ? "Enabled"
                            : "Disabled"}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.alertType}>
                  <div className={styles.heading}>
                    {/* Admin settings */}
                    <span className={styles.admin}>{copy.global.admin}</span>
                    <Button
                      label="Edit"
                      content={copy.global.btnEdit}
                      variant="btn_outline"
                      styles="greenTxt"
                      buttonClick={() => {
                        this.openNotificationSettings("Admin");
                      }}
                      className="openAdminSettings"
                    />
                  </div>
                  <div className={styles.content}>
                    {window.device && (
                      <div className={styles.section}>
                        <span className={styles.title}>
                          {copy.settings.notifications.vibrations}
                        </span>
                        <p>
                          {this.state.selected.admin.vibration
                            ? "Enabled"
                            : "Disabled"}
                        </p>
                      </div>
                    )}
                    <div className={styles.section}>
                      <span className={styles.title}>
                        {copy.settings.notifications.sounds}
                      </span>
                      <p>{this.state.selected.admin.sound.name}</p>
                    </div>
                    {/* Only show lights on android */}
                    {window.device && window.device.platform === "Android" && (
                      <div className={styles.section}>
                        <span className={styles.title}>
                          {copy.settings.notifications.lights}
                        </span>
                        <p>{this.state.selected.admin.light.name}</p>
                      </div>
                    )}
                    {/* Only show critical alerts on iOS */}
                    {window.device && window.device.platform === "iOS" && (
                      <div className={styles.section}>
                        <span className={styles.title}>
                          {copy.settings.notifications.critical}
                        </span>
                        <p>
                          {this.state.selected.admin.isCritical
                            ? "Enabled"
                            : "Disabled"}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </Wrapper>
            ) : (
              // This contains the inner settings - for changing settings for sounds/lights/vibrations
              <Wrapper>
                {window.device && (
                  <div className={styles.borderBottom}>
                    <div className={"toggle_heading"}>
                      <span>{copy.settings.notifications.vibrations}</span>
                      <Toggle
                        key="2"
                        checked={this.state.selected[alertType].vibration}
                        fromAdmin
                        variant="toggle"
                        label="turn on vibrate"
                        buttonClick={this.handleNotificationVibrationToggle}
                      />
                    </div>
                    {window.device &&
                      window.device.platform === "iOS" &&
                      this.state.vibrationsLimitationMessage && (
                        <div>
                          <p>
                            {
                              copy.settings.notifications
                                .vibrationsLimitationMessage
                            }
                          </p>
                        </div>
                      )}
                  </div>
                )}
                <Accordion
                  toggle={true}
                  allowMultipleOpen={true}
                  buttonClick={this.handleNotificationToggle}
                >
                  <div
                    key="112"
                    label={"Sounds"}
                    subtxt={this.state.selected[alertType].sound.name}
                    isOpen={
                      this.state.selected[alertType].sound.name &&
                      this.state.selected[alertType].sound.name !== "Off"
                    }
                  >
                    <ul>{this.soundList}</ul>
                  </div>
                  {/* Only show lights on android */}
                  {window.device && window.device.platform === "Android" && (
                    <div
                      key="113"
                      label={"Lights"}
                      subtxt={this.state.selected[alertType].light.name}
                      isOpen={
                        this.state.selected[alertType].light.name &&
                        this.state.selected[alertType].light.name !== "Off"
                      }
                    >
                      <ul>{this.lightList}</ul>
                    </div>
                  )}
                </Accordion>
                {/* This gets the device version - only show notification channels do not disturb on Android 8+ */}
                {window.device &&
                  window.device.platform === "Android" &&
                  window.device.version.split(".")[0] >= 8 && (
                    <div>
                      <div className={styles.dnd}>
                        <div className={styles.content}>
                          <span className={styles.title + " txt2"}>
                            {copy.settings.notifications.overrideDNDTitle}
                          </span>
                        </div>
                      </div>
                      <p>
                        {copy.settings.notifications.overrideDNDDesc8part1}
                        {groupName}
                        {copy.settings.notifications.overrideDNDDesc8part2}
                      </p>
                      <Button
                        label="Edit"
                        content={copy.global.btnEdit}
                        variant="btn_outline"
                        styles="greenTxt"
                        buttonClick={() => {
                          this.openDeviceSettings("notification_id");
                        }}
                      />
                    </div>
                  )}
                {/* This opens the iOS Accessbility Settings */}
                {window.cordova && window.device.platform === "iOS" && (
                  <div>
                    <div className={styles.borderBottom}>
                      <div className={"toggle_heading"}>
                        <span className={styles.title + " txt2"}>
                          {copy.settings.notifications.criticalAlertsTitle}
                        </span>
                        <Toggle
                          key="2"
                          checked={this.state.selected[alertType].isCritical}
                          fromAdmin
                          variant="toggle"
                          label="turn on critical alerts"
                          buttonClick={this.handleNotificationCriticalToggle}
                        />
                      </div>
                      <p>{copy.settings.notifications.criticalAlertsDesc}</p>
                    </div>
                    <div>
                      <div className={styles.dnd}>
                        <div className={styles.content}>
                          <span className={styles.title + " txt2"}>
                            {
                              copy.settings.notifications
                                .lightAccessibilityTitle
                            }
                          </span>
                        </div>
                      </div>
                      <p>
                        {copy.settings.notifications.lightAccessibilityDesc1}
                      </p>
                      <Button
                        label="Edit"
                        content={copy.global.btnEdit}
                        variant="btn_outline"
                        styles="greenTxt"
                        buttonClick={() => {
                          this.openDeviceSettings("accessibility");
                        }}
                      />
                    </div>
                  </div>
                )}
              </Wrapper>
            )}
          </div>
        )}
      </div>
    );
  }
}
